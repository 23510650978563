import { FC } from 'react'
import { Table } from 'semantic-ui-react'
import { DetailsTabProps } from '../../../../../core/interfaces'
import { TableLoaderWrap } from '../../../../../core/components/Loader'
import { Environment } from '../../../../../services/Customers'
import { EnvironmentRow } from '../../EnvironmentRow'

export const SimpleEnvironmentsTable: FC<DetailsTabProps> = props => (
  <div className="scrollable">
    <Table striped color="blue" size="small" stackable className="sticky-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Website URL</Table.HeaderCell>
          <Table.HeaderCell>API URL</Table.HeaderCell>
          <Table.HeaderCell>SF Pkg version</Table.HeaderCell>
          <Table.HeaderCell>App Version</Table.HeaderCell>
          <Table.HeaderCell>App Release</Table.HeaderCell>
          <Table.HeaderCell>Details</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <TableLoaderWrap
        loading={props.loading}
        array={Object.keys(props.customer?.environments || {})}
        emptyMessage="No environments found."
      >
        <Table.Body>
          {Object.keys(props.customer?.environments || {}).map((env: string) => (
            <EnvironmentRow
              environmentName={env}
              environment={props.customer?.environments[env] as Environment}
              key={env}
              customerName={props.customerKey}
            />
          ))}
        </Table.Body>
      </TableLoaderWrap>
    </Table>
  </div>
)
