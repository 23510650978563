import { vaxAdminApi } from './Endpoints'

export interface Booking {
  [x: string]: BookingValueType
}

export type BookingValueType = string | number | Booking | boolean | BookingValueType[] | undefined

export const getBooking = (customerKey: string, customerEnv: string, bookingId: string) =>
  vaxAdminApi
    .get<Booking>(`/proxy/${customerKey}/${customerEnv}/secure/bookings/${bookingId}?showCancelled=true`)
    .then(response => response.data)
