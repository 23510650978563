import { FC } from 'react'
import { Checkbox, Form, Table } from 'semantic-ui-react'
import { OnChangeHandler } from '../GenerateTokenModal'
import { ScopeErrorPopupProps, ScopeErrorPopup } from './ScopeErrorPopup'

export interface ScopesProps extends ScopeErrorPopupProps {
  scopes?: string[]
  selectedScopes: string[]
  scopesString: string
  onChangeScopes: OnChangeHandler
  validateScopesString: () => boolean | RegExpMatchArray | null
  toggleScope: (scope: string) => () => void
}

export const Scopes: FC<ScopesProps> = ({
  scopes,
  selectedScopes,
  scopesString,
  error,
  onChangeScopes,
  validateScopesString,
  toggleScope
}) => {
  if (scopes && scopes.length > 0) {
    return (
      <Table basic="very" compact="very" style={{ width: '80%', margin: 'auto' }} textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Scope</Table.HeaderCell>
            <Table.HeaderCell>Added</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {scopes.map(scope => (
            <Table.Row key={scope}>
              <Table.Cell>{scope}</Table.Cell>
              <Table.Cell>
                <Checkbox checked={selectedScopes.includes(scope)} onClick={toggleScope(scope)} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    )
  }
  return (
    <Form.Input
      label={<ScopeErrorPopup error={error} />}
      placeholder="Enter scopes separated by space."
      value={scopesString}
      onChange={onChangeScopes}
      required
      error={!validateScopesString()}
    />
  )
}
