import { FC, useState } from 'react'
import { Button, Checkbox, Table, Segment } from 'semantic-ui-react'
import Inspector, { chromeLight } from 'react-inspector'
import { DetailsTabProps } from '../../../../../core/interfaces'
import { TableLoaderWrap } from '../../../../../core/components/Loader'
import { getAllVaccines, VaccineDetails, Vaccines } from '../../../../../services/Vaccines'
import { useConfigTable } from '../../../hooks/useConfigTable'
import { RenderIf } from '../../../../../core/components/RenderIf'

type Props = DetailsTabProps

export const VaccineDataTable: FC<Props> = ({
  customerEnv,
  customerKey,
  configType,
  customer,
  loading: loadingProp
}) => {
  const { config, loading } = useConfigTable({ customerKey, customerEnv, getConfigMethod: getAllVaccines, configType })
  const [showRawData, setShowRawData] = useState(false)

  return (
    <>
      <div style={{ padding: '5px', display: 'flex', justifyContent: 'flex-end' }}>
        <RenderIf condition={customer ? Object.keys(customer.environments).length > 1 : false}>
          <Button
            color="blue"
            content="Diff"
            href={`/config/diff/${configType}?env1=${customerEnv}&customer1=${customerKey}`}
            style={{ marginRight: '20px' }}
          />
        </RenderIf>
        <Checkbox toggle label="Raw Data" onChange={(_, data) => setShowRawData(!!data.checked)} />
      </div>

      {showRawData ? (
        <Segment color="blue">
          <Inspector theme={{ ...chromeLight, ...{ BASE_BACKGROUND_COLOR: 'none' } }} data={config} expandLevel={3} />
        </Segment>
      ) : (
        <div className="scrollable">
          <Table striped color="blue" size="small" stackable className="sticky-table">
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>Brand</Table.HeaderCell>
                <Table.HeaderCell>Doses</Table.HeaderCell>
                <Table.HeaderCell>Min Interval</Table.HeaderCell>
                <Table.HeaderCell>Max Interval</Table.HeaderCell>
                <Table.HeaderCell>External ID</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <TableLoaderWrap
              loading={loading}
              array={(config && Object.keys(config)) || []}
              emptyMessage="No Vaccines found."
            >
              <Table.Body>
                {config?.vaccines instanceof Array &&
                  // TODO: [ENG-16335] Fix cast here by making useConfigTable hook return correct type.
                  ((config as Vaccines) || undefined)?.vaccines.map((vax: VaccineDetails, index) => {
                    const { id, externalId, detail } = vax
                    const { brand, isDefault, maxInterval, minInterval, numberOfDose } = detail
                    return (
                      <Table.Row key={`${id}-${index}`} textAlign="center">
                        <Table.Cell>{brand}</Table.Cell>
                        <Table.Cell>{numberOfDose}</Table.Cell>
                        <Table.Cell>{minInterval}</Table.Cell>
                        <Table.Cell>{maxInterval}</Table.Cell>
                        <Table.Cell>{externalId}</Table.Cell>
                      </Table.Row>
                    )
                  })}
              </Table.Body>
            </TableLoaderWrap>
          </Table>
        </div>
      )}
    </>
  )
}
