import { vaxAdminApi } from './Endpoints'

export interface MessageKeys {
  type: 'text' | 'markdown' | 'html'
  required: boolean
  defaultValue?: string
  description?: string
}

export const getMessageKeys = (customerKey: string, customerEnv: string) =>
  vaxAdminApi
    .get<Record<string, MessageKeys>>(`/proxy/${customerKey}/${customerEnv}/secure/messageKeys`)
    .then(response => response.data)
