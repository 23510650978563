import { FC, useEffect, useState } from 'react'
import { Link, Redirect, RouteComponentProps } from 'react-router-dom'
import { Breadcrumb, Grid, Icon, Menu, Segment, Header } from 'semantic-ui-react'
import { DeploymentLog } from '../../services/Deployments'
import { createErrorToast } from '../../core/components/Alert'
import { displaySuccess } from '../../core/components/DisplaySuccess'
import { SidebarInfo } from '../../core/components/SidebarInfo'
import { DeploymentLogTable } from './components/DeploymentLogTable'
import { useDeployments } from './hooks/useDeployments'

export const DeploymentDetails: FC<RouteComponentProps<{ id: string }>> = ({ match, history }) => {
  const [deployment, setDeployment] = useState<DeploymentLog | undefined>(undefined)
  const [redirect, setRedirect] = useState(false)

  const { data: deployments, isLoading: loading } = useDeployments({
    options: { onError: error => createErrorToast(error), refetchInterval: 15000 }
  })

  const getDeployment = () => {
    if (!loading) {
      const selectedDeployment = deployments?.find(testDeployment => testDeployment.id === match.params.id)
      if (selectedDeployment) {
        setDeployment(selectedDeployment)
      } else {
        setRedirect(true)
      }
    }
  }

  useEffect(() => {
    getDeployment()
  }, [match.params.id, loading])

  return (
    <div className="route-component">
      {redirect && <Redirect to="/deployments" />}
      <Menu secondary fluid stackable>
        <Menu.Menu position="left">
          <Menu.Item>
            <Breadcrumb>
              <Breadcrumb.Section className="back-button" as={Link} to="/deployments">
                <Icon name="chevron left" size="big" />
                Back
              </Breadcrumb.Section>
            </Breadcrumb>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Grid style={{ height: 'calc(100% - 50px)' }}>
        <Grid.Column width={5} style={{ height: '100%' }} className="scrollable">
          <SidebarInfo
            title="Environment Details"
            loading={loading}
            table
            data={[
              { name: 'Customer Key', value: deployment?.customerKey },
              { name: 'Environment', value: deployment?.customerEnv },
              { name: 'Version', value: deployment?.version },
              { name: 'Started At', value: deployment?.createdAt?.substring(0, 10) },
              {
                name: 'Completed At',
                value: deployment?.finished ? deployment.updatedAt.substring(0, 10) : 'In Progress'
              },
              { name: 'Deployed By', value: deployment?.userId },
              { name: 'Reason', value: deployment?.reason },
              { name: 'Promoted', value: displaySuccess(deployment?.promote || false) },
              { name: 'Finished', value: displaySuccess(deployment?.finished || false) },
              { name: 'Success', value: displaySuccess(deployment?.success || false) }
            ]}
          />
        </Grid.Column>
        <Grid.Column width={11} stackable style={{ height: 'calc(100% - 50px)' }}>
          <Header>Log</Header>
          <Segment color="blue">
            <p style={{ whiteSpace: 'pre-wrap' }}>{deployment?.message}</p>
          </Segment>
          <Header>{deployment?.customerKey} Deploys</Header>
          <DeploymentLogTable
            loading={loading}
            deploymentLogs={
              deployments?.filter(
                deploymentLog =>
                  deploymentLog.customerKey === deployment?.customerKey &&
                  deploymentLog.customerEnv === deployment.customerEnv
              )!
            }
          />
        </Grid.Column>
      </Grid>
    </div>
  )
}
