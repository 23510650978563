import { Dispatch, useReducer } from 'react'
import { useCombinedReducers } from '../../../core/hooks/useCombinedReducers'
import { ReleaseAction } from '../actions/Release'
import { DeployReleaseModalState, deployReleaseModalReducer, defaultReleaseState } from './DeployReleaseModal'

export interface ReleaseState {
  deployReleaseModal: DeployReleaseModalState
  dispatch: ReleaseDispatch
}

export type ReleaseDispatch = Dispatch<ReleaseAction>

export const useReleaseReducer = () =>
  useCombinedReducers<ReleaseState, ReleaseAction>({
    deployReleaseModal: useReducer(deployReleaseModalReducer, defaultReleaseState)
  })
