import React, { useState } from 'react'
import { Button, Form, Loader, Modal, Dimmer } from 'semantic-ui-react'
import { useMutation } from 'react-query'
import { Customers } from '../../../services/Customers'
import { deployRelease } from '../../../services/Release'
import { RenderIf } from '../../../core/components/RenderIf'
import { createErrorToast, createSuccessToast } from '../../../core/components/Alert'
import { Actions } from '../actions/Release'
import { useRelease } from './../hooks/useRelease'
import { useReleaseContext } from './../context/Release'
import { DeployReleaseConfirmationModal } from './DeployReleaseConfirmationModal'
import { ReleaseInfo } from './ReleaseDataTable'

interface Props {
  customers: Customers
  loading: boolean
}

export const DeployReleaseModal = (props: Props) => {
  const [customerKey, setCustomerKey] = useState('')
  const [customerEnv, setCustomerEnv] = useState('')
  const [failure, setFailure] = useState(false)
  const [confirmBox, setConfirmBox] = useState(false)
  const [deployingRelease, setDeployingRelease] = useState(false)
  const { dispatch, deployReleaseModal } = useReleaseContext()
  const { customers, loading } = props

  const { data: latestRelease, refetch, isFetching } = useRelease({
    options: {
      onError: error => {
        createErrorToast(error)
      },
      enabled: false
    }
  })

  const addRelease = useMutation(() => deployRelease(customerKey, customerEnv, latestRelease?.releaseVersion || ''), {
    onSuccess: () => {
      createSuccessToast(`Successfully deployed release ${latestRelease?.releaseVersion}`)
      setFailure(false)
      setDeployingRelease(false)
    },
    onError: error => {
      createErrorToast(`Failed to deploy release. ${error}`)
      setFailure(true)
      setDeployingRelease(false)
    }
  })

  const validateForm = () => !!(customers[customerKey]?.environments?.[customerEnv] && latestRelease)

  const handleClose = () => setFailure(false)

  const handleOpen = () => {
    if (deployReleaseModal.unfetchedLatestRelease) {
      refetch()
      dispatch({
        type: Actions.SET_DEPLOY_RELEASE_MODAL_UNFETCHED_NEW_RELEASE,
        payload: false
      })
    }
    setFailure(true)
  }

  const submitForm = async () => {
    if (validateForm()) {
      setDeployingRelease(true)
      await addRelease.mutateAsync()
    }
  }

  const getReleaseData = () => [
    {
      name: 'Release Version',
      value: latestRelease?.releaseVersion
    },
    {
      name: 'Git Hash',
      value: latestRelease?.gitHash
    },
    {
      name: 'Minimum SF Pkg Version',
      value: latestRelease?.minimumSalesForce
    }
  ]

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      onClose={handleClose}
      open={failure}
      trigger={
        <Button onClick={handleOpen} content="Deploy Release" color="blue" disabled={loading} loading={loading} />
      }
    >
      <Modal.Header>Deploy Release</Modal.Header>
      <RenderIf condition={isFetching}>
        <Modal.Content>
          <Dimmer active inverted>
            <Loader active inline="centered" />
          </Dimmer>
        </Modal.Content>
      </RenderIf>
      <RenderIf condition={!isFetching && latestRelease !== undefined}>
        <Modal.Content>
          <ReleaseInfo data={getReleaseData()} />
          <Form>
            <Form.Dropdown
              label="Customer Key"
              placeholder="e.g. California = CAL"
              selection
              search
              options={Object.keys(customers).map(customerKeyString => ({
                text: customerKeyString,
                value: customerKeyString
              }))}
              value={customerKey}
              onChange={(event, data) => setCustomerKey(data.value as string)}
            />
            <Form.Dropdown
              label="Customer Env"
              placeholder="e.g. dev, QA"
              selection
              search
              disabled={!customerKey}
              options={Object.keys(customers[customerKey]?.environments || {}).map(customerKeyString => ({
                text: customerKeyString,
                value: customerKeyString
              }))}
              value={customerEnv}
              onChange={(event, data) => setCustomerEnv(data.value as string)}
            />
            <Modal.Actions>
              <Form.Button
                onClick={() => setConfirmBox(true)}
                content="Deploy"
                fluid
                color="blue"
                loading={deployingRelease}
                disabled={deployingRelease || !validateForm()}
              />
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </RenderIf>
      <RenderIf condition={!isFetching && latestRelease === undefined}>
        <Modal.Content>No release found</Modal.Content>
      </RenderIf>
      <DeployReleaseConfirmationModal
        open={confirmBox}
        setOpen={setConfirmBox}
        submitForm={submitForm}
        releaseVersion={latestRelease?.releaseVersion || ''}
        customerKey={customerKey}
        customerEnv={customerEnv}
      />
    </Modal>
  )
}
