import { Dispatch, useReducer } from 'react'
import { useCombinedReducers } from '../../../core/hooks/useCombinedReducers'
import { DebugLocationWithVaccineAction } from '../../../core/actions/DebugLocationWithVaccine'
import { DebugLocationTableAction } from '../actions/DebugLocationTable'
import {
  DebugLocationWithVaccineState,
  debugLocationWithVaccineReducer,
  defaultDebugLocationWithVaccineState
} from '../../../core/reducers/DebugLocationWithVaccine'
import {
  DebugLocationTableState,
  debugLocationTableReducer,
  defaultDebugLocationTableState
} from './DebugLocationTable'

export interface LocationDetailsState {
  debugLocationWithVaccine: DebugLocationWithVaccineState
  debugLocationTable: DebugLocationTableState
  dispatch: LocationDetailsDispatch
}

export type LocationDetailsAction = DebugLocationWithVaccineAction | DebugLocationTableAction

export type LocationDetailsDispatch = Dispatch<LocationDetailsAction>

export const useLocationDetailsReducer = () =>
  useCombinedReducers<LocationDetailsState, LocationDetailsAction>({
    debugLocationWithVaccine: useReducer(debugLocationWithVaccineReducer, defaultDebugLocationWithVaccineState),
    debugLocationTable: useReducer(debugLocationTableReducer, defaultDebugLocationTableState)
  })
