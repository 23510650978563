import { FC } from 'react'
import { Icon, Loader, Popup } from 'semantic-ui-react'
import { RenderIf } from '../../../../../core/components/RenderIf'
import { formatNumber } from '../../../../../core/utils'

interface Props {
  errorCode?: number
  value: number
  isLoading: boolean
}

export const RenderInterval: FC<Props> = ({ errorCode, value, isLoading }) => (
  <>
    <RenderIf condition={isLoading}>
      <Loader active inline size="mini" />
    </RenderIf>
    <RenderIf condition={!isLoading}>
      <RenderIf condition={!!errorCode}>
        <RenderIf condition={errorCode! >= 500}>
          <Popup
            content={'Please screenshot and report to Skedulo Vax Tooling'}
            trigger={<Icon name="attention" color="red" />}
          />
        </RenderIf>
        <RenderIf condition={errorCode! >= 400 && errorCode! < 500}>
          <Popup content={'Environment version upgrade required'} trigger={<Icon name="attention" color="yellow" />} />
        </RenderIf>
      </RenderIf>
      <RenderIf condition={!errorCode}>
        <>{formatNumber(value)}</>
      </RenderIf>
    </RenderIf>
  </>
)
