import { auth, vaxAdminApi } from './Endpoints'

export type ConfigStoreConfig = Record<string, string | boolean | number | object>
export interface ConfigStore extends ConfigStoreRevision {
  config: ConfigStoreConfig
}

export interface ConfigStoreRevision {
  changedAt: string
  revisionId: number
  changedByUser: string
  changedBySub: string
}

export interface ConfigStoreSchema {
  type: 'object'
  properties: Record<
    string,
    {
      type: 'boolean' | 'string' | 'number' | 'object' | 'array'
      default: 'boolean' | 'string' | 'number'
      description: string
    }
  >
}

export const getLatestConfigStore = (customerKey: string, customerEnv: string) =>
  vaxAdminApi
    .get<ConfigStore>(`/proxy/${customerKey}/${customerEnv}/secure/environment/config`)
    .then(response => response.data)

export const getAllConfigStore = (customerKey: string, customerEnv: string) =>
  vaxAdminApi
    .get<ConfigStore[]>(`/proxy/${customerKey}/${customerEnv}/secure/environment/configs`)
    .then(response => response.data)

export const getConfigStoreSchema = (customerKey: string, customerEnv: string) =>
  vaxAdminApi
    .get<ConfigStoreSchema>(`/proxy/${customerKey}/${customerEnv}/secure/environment/schema`)
    .then(response => response.data)

export const createNewConfigStoreRevision = (customerKey: string, customerEnv: string) => (payload: {
  user: string
  config: ConfigStoreConfig
}) =>
  auth.getProfile().then(profile =>
    vaxAdminApi.post<ConfigStore>(`/proxy/${customerKey}/${customerEnv}/secure/environment/config`, {
      config: payload.config,
      user: profile.name
    })
  )

export const getConfigStoreHistory = (customerKey: string, customerEnv: string) =>
  vaxAdminApi
    .get<ConfigStoreRevision[]>(`/proxy/${customerKey}/${customerEnv}/secure/environment/history`)
    .then(response => response.data)

export const getConfigStoreByRevisionId = (customerKey: string, customerEnv: string, revisionId: number) =>
  vaxAdminApi
    .get<ConfigStore>(`/proxy/${customerKey}/${customerEnv}/secure/environment/config/${revisionId}`)
    .then(response => response.data)
