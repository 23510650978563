import { FC } from 'react'
import { Table, Container, Grid, Pagination, Icon, Form } from 'semantic-ui-react'

import { TableLoaderWrap } from '../../../../../core/components/Loader'
import { DetailsTabProps } from '../../../../../core/interfaces'
import { useLocationListing } from '../../../hooks/useLocationListing'
import { CapacitySnapshotButton } from '../../../../Locations/components/CapacitySnapshotButton'
import { S3CapacitySnapshotButton } from '../../../../Locations/components/S3CapacitySnapshotButton'
import { LocationType } from '../../../../../services/Locations'
import { RenderIf } from '../../../../../core/components/RenderIf'
import { SortedHeaderCell } from '../../../../../core/components/Header/SortedHeaderCell'
import { LocationItem } from './../../LocationItem'
interface Props extends DetailsTabProps {
  locationId?: string
}

export const EnvironmentDetailsDebugLocationListing: FC<Props> = ({ customerKey, customerEnv }) => {
  const {
    loading,
    onSearchLocations,
    locations,
    onSortLocations,
    sortColumn,
    sortDirection,
    onPaginationChange,
    page,
    totalLocations,
    totalPages,
    input,
    isNewVersion,
    onInputChange
  } = useLocationListing({
    customerEnv,
    customerKey
  })

  const SortedLocationHeaderCell = SortedHeaderCell(sortColumn, sortDirection, onSortLocations)

  return (
    <Container fluid>
      <Grid>
        <Grid.Column width={3} textAlign="left" verticalAlign="middle" style={{ color: '#797979' }}>
          Total Locations: {totalLocations || 0}
        </Grid.Column>
        <Grid.Column width={6} textAlign="right" verticalAlign="middle">
          <Form onSubmit={onSearchLocations}>
            <Form.Input
              value={input}
              onChange={onInputChange}
              placeholder="Search"
              fluid
              icon={<Icon name="search" inverted circular link onClick={onSearchLocations} />}
            />
          </Form>
        </Grid.Column>
        <Grid.Column width={7} textAlign="right">
          <RenderIf condition={!(customerKey === 'CAL' && customerEnv === 'prod')}>
            <CapacitySnapshotButton customerKey={customerKey} customerEnv={customerEnv} />
          </RenderIf>
          <RenderIf condition={customerKey === 'STG'}>
            <S3CapacitySnapshotButton customerKey={customerKey} customerEnv={customerEnv} />
          </RenderIf>
        </Grid.Column>
      </Grid>
      <Table celled stackable size="small" color="blue">
        <Table.Header>
          <Table.Row>
            <SortedLocationHeaderCell title="Name" name="name" />
            <SortedLocationHeaderCell title="Available Date" name="availableDate" />
            <SortedLocationHeaderCell title="Start Date" name="startDate" />
            <SortedLocationHeaderCell title="End Date" name="endDate" />
            <SortedLocationHeaderCell title="Exclude From Search" name="excludeFromSearch" />
            <SortedLocationHeaderCell title="Exhausted" name="exhausted" />
            <SortedLocationHeaderCell title="Location Pool" name="locationPool" />
            <Table.HeaderCell textAlign="center">Details</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <TableLoaderWrap loading={loading} array={locations} emptyMessage="No Locations Found">
          <Table.Body>
            {locations.map((location: LocationType) => (
              <LocationItem
                key={location.id}
                location={location}
                id={location.id}
                customerName={customerKey}
                customerEnv={customerEnv}
              />
            ))}
          </Table.Body>
        </TableLoaderWrap>
      </Table>
      <Pagination
        simple
        totalPages={totalPages ? totalPages : Math.ceil((locations?.length || 0) / 10)}
        onPageChange={onPaginationChange}
        activePage={page}
      />
    </Container>
  )
}
