import { AxiosError } from 'axios'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Button, Table } from 'semantic-ui-react'
import { Environment } from '../../../services/Customers'
import { VersionDisplay } from '../../../core/components/VersionDisplay'
import { Version } from '../../../services/Environment'
import { useAllVersions } from '../hooks/useAllVersions'

interface EnvironmentRowProps {
  environment: Environment
  environmentName: string
  customerName: string
}

const defaultVersion = {
  git: '',
  sf: '',
  release: ''
}

export const EnvironmentRow: FC<EnvironmentRowProps> = ({ environment, environmentName, customerName }) => {
  const { data, error: err, isLoading } = useAllVersions({})
  const customerData =
    (data &&
      data instanceof Array &&
      data.find(ver => ver.customerEnv === environmentName && ver.customerKey === customerName)) ||
    ''
  const { gitBranch: release, gitVersion: git, packageVersion: sf } =
    (customerData && customerData.data) || defaultVersion
  const error = err as AxiosError<Version> | undefined
  const { static: www, api } = environment

  return (
    <Table.Row>
      <Table.Cell>{environmentName}</Table.Cell>
      <Table.Cell className="table-cell-overflow">
        <a href={www}>{www}</a>
      </Table.Cell>
      <Table.Cell className="table-cell-overflow">
        <a href={api}>{api}</a>
      </Table.Cell>
      <Table.Cell>
        <VersionDisplay version={sf} error={error} loading={isLoading} />
      </Table.Cell>
      <Table.Cell className="table-cell-overflow">
        <VersionDisplay version={git} error={error} loading={isLoading} />
      </Table.Cell>
      <Table.Cell>
        <VersionDisplay version={release} error={error} loading={isLoading} />
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Button icon="file alternate outline" as={Link} to={`/environments/${customerName}/${environmentName}`} />
      </Table.Cell>
    </Table.Row>
  )
}
