import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { createErrorToast } from '../../../core/components/Alert'
import { Config, ConfigValueType } from '../../../services/Config'
import { IConfigPanel, panels } from '../components/Tabs/ConfigPanel/ConfigPanel'
import { useFetchConfig } from './useFetchConfig'

interface Props {
  customerKey: string
  customerEnv: string
  name: string
}

interface Result {
  error: AxiosError<Config> | null
  isLoading: boolean
  config?: Config
  handlerExpandClick: () => void
  expandPaths?: string | string[]
  isExpand: boolean
}

const objectDepth = (o?: Config | ConfigValueType): number =>
  Object(o) === o ? 1 + Math.max(-1, ...Object.values(o || {}).map(objectDepth)) : 0

const createExpandPath = (config?: Config) => {
  const num = objectDepth(config)
  const array = ['$']
  for (let index = 1; index < num; index++) {
    const last = array[array.length - 1]
    array.push(`${last}.*`)
  }
  return array
}

export const useConfig: CustomHook<Props, Result> = ({ customerKey, customerEnv, name }) => {
  const panel = <IConfigPanel>panels.find(p => p.name === name)
  const [isExpand, setIsExpand] = useState(false)

  const { data: config, error, isLoading } = useFetchConfig({
    customerEnv,
    customerKey,
    getConfigMethod: panel.getConfig,
    name
  })

  useEffect(() => {
    if (error) {
      createErrorToast(`${name}: ${error.message}`)
    }
  }, [error])

  const handlerExpandClick = () => {
    setIsExpand(!isExpand)
  }

  return { isExpand, error, isLoading, config, handlerExpandClick, expandPaths: createExpandPath(config) }
}
