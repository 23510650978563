import { vaxAdminApi } from './Endpoints'

export interface Config {
  [x: string]: ConfigValueType
}

export interface LocationExtendedDebug {
  location: {
    displayAddress: string
    distanceInMeters: number
    description?: string
    extId: string
    regionalExternalId: string
    startDate: string
    endDate: string
    location: {
      lat: number
      long: number
    }
    timezone: string
    openHours: {
      days: string[]
      localStart: string
      localEnd: string
    }[]
    type: string
    externalUrl: string
    name: string
    externalId: string
    address: string
    availableDate: string
    excludeFromSearch: boolean
    exhausted: boolean
    locationPool: string
  }
  searchRadius: number
  consumption: LocationVaccineConsumption[]
  holidays: Config[] // TODO: [ENG-16335] Needs specific type
  searchMaxResults: number
}

export interface LocationVaccineConsumption {
  supply: {
    count: number
    asAt: string
  }
  vaccine: {
    id: string
    brand: string
    doseNumber: number
  }
  appointments: number
  reservations: number
  supplyRemaining: number
}

export interface LocationCapacityForVaccine {
  vaccineData: string
  locationExtId: string
  availability: LocationAvailability[]
}

export interface LocationAvailabilityQuery {
  vaccineData: string
  startDate: string
  endDate: string
  doseNumber: number
  groupSize: number
}

export interface LocationAvailability {
  capacity: number
  capacityLeft: number
  capacityTaken: number
  date: string
  holiday: boolean
  isBookable: boolean
  nextDoseAvailable: boolean
  reservations: number
  slotsWithAvailability?: SlotAvailability[]
}

export interface SlotAvailability {
  capacity: number
  capacityTaken: number
  capacityTotal: number
  durationSeconds: number
  localStartTime: string
  reservationsTaken: number
  appointmentsTaken: number
}

export type ConfigValueType = string | number | Config | boolean | ConfigValueType[] | undefined

export const getEnvConfig = (customerKey: string, customerEnv: string) =>
  vaxAdminApi.get<Config>(`/proxy/${customerKey}/${customerEnv}/secure/config/env`).then(response => response.data)

export const getSecureConfig = (customerKey: string, customerEnv: string) =>
  vaxAdminApi.get<Config>(`/proxy/${customerKey}/${customerEnv}/secure/config`).then(response => response.data)

export const getClientConfig = (customerKey: string, customerEnv: string) =>
  vaxAdminApi.get<Config>(`/proxy/${customerKey}/${customerEnv}/public/config`).then(response => response.data)

export const getConfigDb = (customerKey: string, customerEnv: string) =>
  vaxAdminApi.get<Config>(`/proxy/${customerKey}/${customerEnv}/secure/config/db`).then(response => response.data)

export const getLocationDebugExtended = (customerKey: string, customerEnv: string, locationId: string) =>
  vaxAdminApi
    .get<LocationExtendedDebug>(`/proxy/${customerKey}/${customerEnv}/secure/locations/${locationId}/debug_extended`)
    .then(response => response.data)

export const getLocationDebug = (
  customerKey: string,
  customerEnv: string,
  locationId: string,
  locationAvailabilityQuery: LocationAvailabilityQuery
) =>
  vaxAdminApi
    .post<LocationCapacityForVaccine>(
      `/proxy/${customerKey}/${customerEnv}/secure/locations/${locationId}/debug`,
      locationAvailabilityQuery
    )
    .then(response => response.data)
