import { useEffect, FC, ComponentClass } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Breadcrumb, Grid, Icon, Menu, StrictTabProps, Tab } from 'semantic-ui-react'
import { getTimezone } from 'countries-and-timezones'
import { auth } from '../../services/Endpoints'

import { SidebarInfo } from '../../core/components/SidebarInfo'
import { createErrorToast } from '../../core/components/Alert'
import { getClientConfig } from '../../services/Config'
import { DetailsTabProps } from '../../core/interfaces'

import { useFetchConfig } from '../Environments/hooks/useFetchConfig'
import { usePerInteractionWorflow } from '../Environments/hooks/usePerInteractionWorflow'
import { useCustomer } from '../Environments/hooks/useCustomer'
import { useLocationExtendedDebug } from '../../core/hooks/useLocationExtendedDebug'
import { DebugLocationWithVaccine } from './components/DebugLocationWithVaccine/DebugLocationWithVaccine'
import { LocationDetailsProvider } from './context/LocationDetails'
import { DebugLocationTable } from './components/DebugLocationTable/DebugLocationTable'

export interface RouteParams {
  customerLocation: string
  customerCode: string
  customerEnv: string
}

export interface LocationDetailsTabProps extends DetailsTabProps {
  locationId?: string
}

export interface TabData {
  permissions: string[]
  name: string
  Component: ComponentClass<LocationDetailsTabProps, object> | FC<LocationDetailsTabProps>
}

const tabs: TabData[] = [
  {
    permissions: ['vax:action:debug:location'],
    Component: DebugLocationWithVaccine,
    name: 'Availability'
  },
  {
    permissions: ['vax:action:debug:location'],
    Component: DebugLocationTable,
    name: 'Supply'
  }
]

export const LocationDetails: FC<RouteComponentProps<RouteParams>> = ({ match, history }) => {
  const { customerCode, customerEnv, customerLocation } = match.params

  const { data: config, isLoading, error } = useLocationExtendedDebug({
    customerKey: customerCode,
    customerEnv,
    locationId: customerLocation
  })

  const { data: envConfig } = useFetchConfig({
    customerEnv,
    customerKey: customerCode,
    getConfigMethod: getClientConfig,
    name: 'Client'
  })
  const { data: customer } = useCustomer({ customerCode })

  const { data: perInteractionWorkflowLastDate } = usePerInteractionWorflow({
    customerName: customerCode,
    environmentName: customerEnv
  })

  useEffect(() => {
    if (error) {
      createErrorToast(error)
    }
  }, [error])

  const validatePanePermissions = (tab: TabData) =>
    tab.permissions
      .map(permission => auth.getPermissions().includes(permission))
      .reduce((sum, next) => sum && next, true)
  const getPanes = (): StrictTabProps['panes'] =>
    tabs.filter(validatePanePermissions).map((tab, index) => ({
      menuItem: tab.name,
      render: () => (
        <Tab.Pane className="scrollable" style={{ maxHeight: 'calc(100% - 30px)' }}>
          <tab.Component
            customerKey={customerCode}
            customerEnv={customerEnv}
            loading={isLoading}
            configType={tab.name}
            locationId={customerLocation}
          />
        </Tab.Pane>
      )
    }))

  const getTimeDiffInText = (firstTime: number, secondTime: number) => {
    const minDiff = firstTime - secondTime
    const timeDiff = minDiff % 60 === 0 ? minDiff / 60 : (minDiff / 60).toFixed(1)
    return timeDiff === 0
      ? 'Same as your timezone'
      : timeDiff === 1
      ? '+1 hr ahead of you '
      : timeDiff > 0
      ? `+${timeDiff} hrs ahead of you`
      : timeDiff === -1
      ? '-1 hr behind you'
      : `${timeDiff} hrs behind you`
  }

  const getTimeDiff = () => {
    const locationOffset = getTimezone(config?.location?.timezone || '')
    const localOffset = getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    return locationOffset && localOffset ? (
      <span style={{ display: 'block', color: 'grey' }}>
        ({getTimeDiffInText(locationOffset.dstOffset, localOffset.dstOffset)})
      </span>
    ) : (
      ''
    )
  }

  return (
    <LocationDetailsProvider>
      <div className="route-component">
        <Menu secondary fluid stackable>
          <Menu.Menu position="left">
            <Menu.Item>
              <Breadcrumb>
                <Breadcrumb.Section className="back-button" onClick={history.goBack}>
                  <Icon name="chevron left" size="big" />
                  Back
                </Breadcrumb.Section>
              </Breadcrumb>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Grid stackable style={{ height: 'calc(100% - 50px)' }}>
          <Grid.Column width={5} style={{ height: '100%' }} className="scrollable">
            <SidebarInfo
              title="Relevant Environment Details"
              loading={isLoading}
              table
              data={[
                { name: 'Customer Name', value: customer?.friendlyName },
                { name: 'Environment Name', value: customerEnv },
                { name: 'Location Search Distance', value: config?.searchRadius },
                { name: 'Location Search Max Results', value: config?.searchMaxResults },
                {
                  name: 'Enable Dose 1 Upfront, Dose 2 Later',
                  value: envConfig?.forceSingleBooking ? (
                    <Icon color="red" name="close" />
                  ) : (
                    <Icon color="green" name="check" />
                  )
                },
                {
                  name: 'Group third party locations in search',
                  value: envConfig?.groupThirdPartyLocationResults ? (
                    <Icon color="red" name="close" />
                  ) : (
                    <Icon color="green" name="check" />
                  )
                },
                {
                  name: 'Last Per Interaction Workflow',
                  value: !!!perInteractionWorkflowLastDate?.configOverride?.createdAt
                    ? 'Never'
                    : new Date(perInteractionWorkflowLastDate?.configOverride.createdAt!).toLocaleDateString()
                }
              ]}
            />
            <SidebarInfo
              title="Location Details"
              loading={isLoading}
              style={{ marginTop: '25px' }}
              table
              data={[
                { name: 'Name', value: config?.location?.name },
                { name: 'ID', value: config?.location?.externalId },
                { name: 'Description', value: config?.location?.description },
                { name: 'Address', value: config?.location?.address },
                {
                  name: 'Timezone',
                  value: (
                    <p>
                      {config?.location?.timezone}
                      {getTimeDiff()}
                    </p>
                  )
                },
                { name: 'Available Date', value: config?.location?.availableDate },
                { name: 'Start Date', value: config?.location?.startDate },
                { name: 'End Date', value: config?.location?.endDate },
                { name: 'Type', value: config?.location?.type },
                {
                  name: 'Exclude From Search',
                  value: config?.location?.excludeFromSearch ? (
                    <Icon color="red" name="check" />
                  ) : (
                    <Icon color="green" name="close" />
                  )
                },
                {
                  name: 'Exhausted',
                  value: config?.location?.exhausted ? (
                    <Icon color="red" name="check" />
                  ) : (
                    <Icon color="green" name="close" />
                  )
                },
                { name: 'Location Pool', value: config?.location?.locationPool }
              ]}
            />
          </Grid.Column>
          <Grid.Column width={11} stretched style={{ height: '100%' }}>
            <Tab panes={getPanes()} style={{ height: '100%' }} />
          </Grid.Column>
        </Grid>
      </div>
    </LocationDetailsProvider>
  )
}
