import { FC } from 'react'
import { Icon } from 'semantic-ui-react'

interface Props {
  loading?: boolean
  success?: boolean
}

export const DisplaySuccessWithLoader: FC<Props> = ({ loading, success }) => {
  const name = loading ? 'sync' : success ? 'check' : 'close'
  const color = loading ? 'black' : success ? 'green' : 'red'

  return <Icon name={name} color={color} loading={name === 'sync'} />
}
