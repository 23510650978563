import { LocationType } from '../../../services/Locations'

// eslint-disable-next-line no-shadow
export enum Actions {
  SET_INPUT = 'SET_INPUT',
  SET_LOCATION_LIST_ITEMS = 'SET_LOCATION_LIST_ITEMS',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_SORT_COLUMN = 'SET_SORT_COLUMN',
  SET_SORT_DIRECTION = 'SET_SORT_DIRECTION'
}

interface SetInput {
  type: Actions.SET_INPUT
  payload: string
}

// TODO: [ENG-16335] Replace with correct type
interface SetFilter {
  type: Actions.SET_LOCATION_LIST_ITEMS
  payload: LocationType[]
}

interface SetPagination {
  type: Actions.SET_PAGINATION
  payload: number
}

interface SetSortColumn {
  type: Actions.SET_SORT_COLUMN
  payload: string
}

interface SetSortDirection {
  type: Actions.SET_SORT_DIRECTION
  payload: 'ascending' | 'descending'
}

export type LocationAction = SetFilter | SetInput | SetPagination | SetSortColumn | SetSortDirection
