import { Button, Modal } from 'semantic-ui-react'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { ConfigValueType } from '../../../services/Config'

const metadataTypes = ['string', 'number', 'undefined', 'boolean', 'json'] as const
type MetadataTypes = typeof metadataTypes[number]

interface Props {
  leftValue: ConfigValueType
  leftTitle: string
  rightValue: ConfigValueType
  rightTitle: string
  name: string
}

export const getType = (value: ConfigValueType) => {
  const rawType = typeof value
  if (rawType === 'object') {
    return 'json'
  }
  if (metadataTypes.includes(rawType as MetadataTypes)) {
    return rawType as MetadataTypes
  }
  throw Error('Invalid type in metadata.')
}

export const getString = (value: ConfigValueType) => {
  const type = getType(value)
  return type === 'json' ? JSON.stringify(value, null, 4) : value?.toString()
}

export const ConfigCompareModal = (props: Props) => {
  const { leftValue, leftTitle, rightValue, rightTitle } = props
  return (
    <Modal
      closeIcon
      trigger={<Button circular icon="arrows alternate horizontal" className="circle-icon-button" />}
      size="fullscreen"
    >
      <Modal.Header>Field: {props.name}</Modal.Header>
      <Modal.Content scrolling>
        <ReactDiffViewer
          oldValue={getString(leftValue)}
          leftTitle={leftTitle}
          newValue={getString(rightValue)}
          rightTitle={rightTitle}
          splitView={true}
          compareMethod={DiffMethod.LINES}
        />
      </Modal.Content>
    </Modal>
  )
}
