import { vaxAdminApiLongRunning, vaxAdminApi } from './Endpoints'

export interface DeploymentLog {
  id: string
  customerKey: string
  customerEnv: string
  version: string
  promote: boolean
  finished: boolean
  success: boolean
  message: string
  reason: string
  userId: string
  updatedAt: string
  createdAt: string
}

export interface NewDeployment {
  customerKey: string
  customerEnv: string
  tag: string
}

export const getDeployments = () => vaxAdminApi.get<DeploymentLog[]>('/deploy').then(response => response.data)

export const createDeployment = (deployment: NewDeployment) =>
  vaxAdminApiLongRunning.post(`/deploy/${deployment.customerKey}/${deployment.customerEnv}/${deployment.tag}`)

export const getAvailableVersions = () =>
  vaxAdminApi.get<string[]>('/deploy/available_versions').then(response => response.data)
