import { FC } from 'react'
import { Link } from 'react-router-dom'
import Inspector from 'react-inspector'
import { Card, Container, Icon, Loader, Button, Menu, Header } from 'semantic-ui-react'
import { DisplayWithPermission } from '../../../../../core/components/DisplayWithPermission'
import { Config, getClientConfig, getConfigDb, getEnvConfig, getSecureConfig } from '../../../../../services/Config'
import { DetailsTabProps } from '../../../../../core/interfaces'
import { RenderIf } from '../../../../../core/components/RenderIf'
import { useConfig } from '../../../hooks/useConfig'
import { CustomerInfo } from '../../../../../services/Customers'

export interface IConfigPanel {
  name: string
  expandLevel: number
  getConfig: (customerKey: string, customerEnv: string) => Promise<Config>
  permission: string
}

export type ConfigPanels = IConfigPanel[]

export const panels: ConfigPanels = [
  {
    name: 'Client',
    expandLevel: 1,
    getConfig: getClientConfig,
    permission: 'vax:action:read:config'
  },
  {
    name: 'Server',
    expandLevel: 2,
    getConfig: getSecureConfig,
    permission: 'vax:action:read:config'
  },
  {
    name: 'Env',
    expandLevel: 1,
    getConfig: getEnvConfig,
    permission: 'vax:action:read:config'
  },
  {
    name: 'RDS',
    expandLevel: 2,
    getConfig: getConfigDb,
    permission: 'vax:action:read:internal-config'
  }
]

export const ConfigPanel: FC<DetailsTabProps> = props => (
  <>
    {panels.map(({ name, expandLevel, permission }, index) => (
      <DisplayWithPermission permission={permission} key={`cdp-${index}`}>
        <ConfigDetailPanel key={`cdp-${index}`} {...{ ...props, name, expandLevel }} />
      </DisplayWithPermission>
    ))}
  </>
)

interface ConfigDetailPanelProps {
  name: string
  expandLevel: number
  customerKey: string
  customerEnv: string
  customer?: CustomerInfo
}

const ConfigDetailPanel: React.FC<ConfigDetailPanelProps> = ({
  customerKey,
  customerEnv,
  name,
  customer,
  expandLevel = 2
}) => {
  const { isExpand, config, error, isLoading, handlerExpandClick, expandPaths } = useConfig({
    name,
    customerKey,
    customerEnv
  })

  return (
    <Card fluid>
      <Card.Content header={name}>
        <Menu secondary fluid stackable>
          <Menu.Menu position="left">
            <Header as="h2">{name}</Header>
          </Menu.Menu>
          <Menu.Menu position="right">
            <Button color="blue" content={isExpand ? 'Collapse' : 'Expand'} onClick={handlerExpandClick} />
            <RenderIf condition={customer ? Object.keys(customer.environments).length > 1 : false}>
              <Button
                color="blue"
                content="Diff"
                as={Link}
                to={`/config/diff/${name}?env1=${customerEnv}&customer1=${customerKey}`}
              />
            </RenderIf>
          </Menu.Menu>
        </Menu>
      </Card.Content>
      <Card.Content>
        {error && (
          <p>
            <Icon color="red" name="dont" />
            {error.message}
          </p>
        )}
        {isLoading && (
          <Container textAlign="center">
            <Loader active inline />
          </Container>
        )}
        <RenderIf condition={!isLoading && !error}>
          <Inspector
            data={isExpand ? config : { config }}
            expandLevel={expandLevel}
            expandPaths={isExpand ? expandPaths : ['$', '$.*']}
          />
        </RenderIf>
      </Card.Content>
    </Card>
  )
}
