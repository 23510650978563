import { AxiosError } from 'axios'
import { useQuery, UseQueryResult } from 'react-query'
import { getGeneratedTokens, TokenInfo } from '../../../services/Tokens'

interface Props {
  customerKey: string
  customerEnv: string
  name: string
}

type Result = UseQueryResult<TokenInfo[], AxiosError<TokenInfo[]>>

export const useTokens: CustomHook<Props, Result> = ({ customerKey, customerEnv, name }) =>
  useQuery(`tokens-${name}-${customerEnv}-${customerKey}`, () => getGeneratedTokens(customerKey, customerEnv))
