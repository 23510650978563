import { FC, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Grid, Icon, Input } from 'semantic-ui-react'
import { Actions } from '../../core/actions/EnvironmentDetails'
import { useAppContext } from '../../core/context/App'
import { EnvironmentsTable } from './components/EnvironmentTable'
import { useEnvironmentsList } from './hooks/useEnvironmentsList'

export const Environments: FC<RouteComponentProps> = () => {
  const { changeSearchValue, isLoading, isFetching, fetchCustomers, searchEnvironmentsList } = useEnvironmentsList()
  const { dispatch } = useAppContext()

  useEffect(() => {
    dispatch({
      type: Actions.SET_ENVIRONMENT_DETAILS_ACTIVE_INDEX_TAB,
      payload: 0
    })
  }, [])

  return (
    <div className="route-component">
      <Grid columns={2} stackable verticalAlign="middle">
        <Grid.Column width={1}>
          <Grid verticalAlign="middle">
            <Grid.Column>
              <Icon size="large" name="refresh" loading={isFetching} className="clickable" onClick={fetchCustomers} />
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column>
          <Input icon="search" placeholder="Search..." type="text" onChange={changeSearchValue} />
        </Grid.Column>
      </Grid>
      <EnvironmentsTable loading={isLoading} customers={searchEnvironmentsList()} />
    </div>
  )
}
