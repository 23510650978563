export const mapStringsToOptions = (options: string[]) => options.map(option => ({ text: option, value: option }))

export const formatNumber = (raw: number) => raw.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

export const READ_CONFIG_STORE_PERMISSION = 'vax:action:read:internal-config'
export const DATE_FORMAT = 'h:mma dd/MM/yyyy'

// eslint-disable-next-line no-shadow
export enum ConfigStoreComparisonSelectionType {
  Env = 'env',
  RevisionLeft = '1',
  RevisionRight = '2'
}
