import { createContext, useContext, ReactElement } from 'react'
import { useReleaseReducer, ReleaseState } from '../reducers/Release'

export const ReleaseContext = createContext<ReleaseState | undefined>(undefined)

export const ReleaseProvider = ({
  children,
  ...rest
}: {
  [x: string]: unknown
  children: ReactElement | ReactElement[]
}) => {
  const [state, dispatch] = useReleaseReducer()

  return (
    <ReleaseContext.Provider
      value={{
        ...state,
        dispatch
      }}
      {...rest}
    >
      {children}
    </ReleaseContext.Provider>
  )
}

// custom hook to create App context
export const useReleaseContext = () => {
  const context = useContext(ReleaseContext)
  if (!context) {
    throw new Error('App components cannot be rendered outside the ReleaseContext components')
  }
  return context
}
