import type { FC } from 'react'
import { Button, Modal, Dropdown } from 'semantic-ui-react'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { IConfigComparison } from '../../ConfigCompare'
import { useConfigFieldCompareModal } from './hooks/useConfigFieldCompareModal'
import { getString } from './../ConfigCompareModal'

interface Props {
  config: IConfigComparison
  leftTitle: string
  rightTitle: string
}

export const ConfigFieldCompareModal: FC<Props> = ({ config, leftTitle, rightTitle }) => {
  const {
    flattenedConfig1,
    flattenedConfig2,
    configField,
    searchField,
    handleFieldChange,
    searchOptions,
    updateSearchField,
    resetSearchFeild
  } = useConfigFieldCompareModal({ config })

  return (
    <Modal
      closeIcon
      trigger={<Button circular icon="arrows alternate horizontal" className="circle-icon-button" />}
      size="fullscreen"
    >
      <Modal.Header>Field: {config.name}</Modal.Header>
      <Modal.Content style={{ overflow: 'visible' }} scrolling>
        <Dropdown
          fluid
          lazyLoad
          selection
          search
          placeholder={configField || 'Fields..'}
          selectOnBlur={false}
          options={searchOptions(searchField)}
          onChange={handleFieldChange}
          onSearchChange={updateSearchField}
          onClick={resetSearchFeild}
          style={{ marginBottom: '20px' }}
        />
        <ReactDiffViewer
          oldValue={getString(flattenedConfig1[configField])}
          leftTitle={leftTitle}
          newValue={getString(flattenedConfig2[configField])}
          rightTitle={rightTitle}
          splitView={true}
          compareMethod={DiffMethod.LINES}
        />
      </Modal.Content>
    </Modal>
  )
}
