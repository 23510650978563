import { format } from 'date-fns'

import type { Reducer } from 'react'
import { LocationAvailability } from '../../services/Config'
import { DebugLocationWithVaccineAction, Actions } from '../actions/DebugLocationWithVaccine'

export interface DebugLocationWithVaccineState {
  locationId: string
  vaccineData: string
  vaccineBase64?: string
  startDate: string
  endDate: string
  datePickerValue: Date | Date[]
  doseNumber: number
  inputType: string
  showRawData: boolean
  showSlotsModal: boolean
  slotsWithAvail?: LocationAvailability[]
  totalCapacity: string
  totalRemaining: string
  doseOptions?: { key: number; text: number; value: number }[]
  groupSize: number
}

export const defaultDebugLocationWithVaccineState: DebugLocationWithVaccineState = {
  vaccineData: '',
  startDate: format(new Date(), 'yyyy-MM-dd'),
  locationId: '',
  endDate: format(new Date(), 'yyyy-MM-dd'),
  datePickerValue: new Date(),
  doseNumber: 1,
  inputType: 'raw',
  showRawData: false,
  showSlotsModal: false,
  slotsWithAvail: undefined,
  totalCapacity: '',
  totalRemaining: '',
  groupSize: 1
}

export const debugLocationWithVaccineReducer: Reducer<DebugLocationWithVaccineState, DebugLocationWithVaccineAction> = (
  state,
  action
) => {
  switch (action.type) {
    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_DATE_PICKER: {
      const { payload: datePickerValue } = action

      return {
        ...state,
        datePickerValue
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_LOADING: {
      const { payload: loading } = action

      return {
        ...state,
        loading
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_LOADING_VAX: {
      const { payload: loadingVax } = action

      return {
        ...state,
        loadingVax
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_CONFIG: {
      const { payload: config } = action

      return {
        ...state,
        config
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_LOCATION_ID: {
      const { payload: locationId } = action

      return {
        ...state,
        locationId
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_DATA: {
      const { payload: vaccineData } = action

      return {
        ...state,
        vaccineData
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_BASE_64: {
      const { payload: vaccineBase64 } = action

      return {
        ...state,
        vaccineBase64
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_START_DATE: {
      const { payload: startDate } = action

      return {
        ...state,
        startDate
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_END_DATE: {
      const { payload: endDate } = action

      return {
        ...state,
        endDate
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_DOSE_NUMBER: {
      const { payload: doseNumber } = action

      return {
        ...state,
        doseNumber
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_INPUT_TYPE: {
      const { payload: inputType } = action

      return {
        ...state,
        inputType
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_LIST: {
      const { payload: vaccineList } = action

      return {
        ...state,
        vaccineList
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_SHOW_RAW_DATA: {
      const { payload: showRawData } = action

      return {
        ...state,
        showRawData
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_SHOW_SLOTS_MODAL: {
      const { payload: showSlotsModal } = action

      return {
        ...state,
        showSlotsModal
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_SLOTS_WITH_AVAIL: {
      const { payload: slotsWithAvail } = action

      return {
        ...state,
        slotsWithAvail
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_TOTAL_CAPACITY: {
      const { payload: totalCapacity } = action

      return {
        ...state,
        totalCapacity
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_TOTAL_REMAINING: {
      const { payload: totalRemaining } = action

      return {
        ...state,
        totalRemaining
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_DOSE_OPTIONS: {
      const { payload: doseOptions } = action

      return {
        ...state,
        doseOptions
      }
    }

    case Actions.SET_DEBUG_LOCATION_WITH_VACCINE_VAX_INFO: {
      const { payload: vaxInfo } = action

      return {
        ...state,
        vaxInfo
      }
    }

    default:
      return state
  }
}
