import { PureComponent, ChangeEvent } from 'react'
import { Button, Input, Grid, Header, Segment, Loader } from 'semantic-ui-react'
import Inspector, { chromeLight } from 'react-inspector'
import { Booking, getBooking } from '../../../../../services/Bookings'
import { createErrorToast } from '../../../../../core/components/Alert'
import { DetailsTabProps } from '../../../../../core/interfaces'

interface State {
  loading: boolean
  booking?: Booking
  bookingId: string
}

type Props = DetailsTabProps

export class BookingTable extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      bookingId: ''
    }
  }

  getBookingDetails = async () => {
    const { customerKey, customerEnv } = this.props
    this.setState({ loading: true, booking: {} })
    try {
      const booking = await getBooking(customerKey, customerEnv, this.state.bookingId)
      this.setState({ booking })
    } catch (error) {
      createErrorToast(`Booking with Confirmation Code '${this.state.bookingId}' not found.`, { time: 3000 })
    }
    this.setState({ loading: false })
  }

  onChangeBookingId = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ bookingId: e.target.value })
  }

  renderBookingDetails = () => {
    const { booking, loading } = this.state
    delete booking?.info
    if (loading) {
      return <Loader size="tiny" inline active />
    } else {
      return (
        <Segment>
          <Inspector theme={{ ...chromeLight, ...{ BASE_BACKGROUND_COLOR: 'none' } }} data={booking} expandLevel={1} />
        </Segment>
      )
    }
  }

  render() {
    return (
      <div>
        <div style={{ marginBottom: '10px' }}>
          <Grid>
            <Grid.Column width={10} textAlign="left" verticalAlign="middle">
              <Header as="h3">Booking confirmation code</Header>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right" verticalAlign="middle" style={{ paddingRight: '0px' }}>
              <Input value={this.state.bookingId} onChange={this.onChangeBookingId} fluid />
            </Grid.Column>
            <Grid.Column width={2} textAlign="right">
              <Button color="blue" content="Get" onClick={this.getBookingDetails} />
            </Grid.Column>
          </Grid>
        </div>
        {this.state.booking && this.renderBookingDetails()}
      </div>
    )
  }
}
