import { AxiosError } from 'axios'
import { Icon, Loader, Popup } from 'semantic-ui-react'

interface VersionProps {
  version: string
  error?: AxiosError
  loading: boolean
}

export const VersionDisplay = ({ version, error, loading }: VersionProps) =>
  loading ? (
    <Loader size="tiny" inline active />
  ) : error?.response?.status !== 200 && (error?.response?.status || 501) < 500 ? (
    <Popup trigger={<Icon name="exclamation circle" color="red" />}>
      This environment is not configured correctly.
    </Popup>
  ) : error ? (
    <Popup trigger={<Icon name="minus circle" color="red" />}>This environment does not exist or is not set up.</Popup>
  ) : version ? (
    <span>{version}</span>
  ) : (
    <Popup trigger={<Icon name="question circle" color="orange" />}>
      This version is out of date and not supported.
    </Popup>
  )
