import { AxiosError } from 'axios'
import { memoize } from 'lodash'
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query'

import {
  ConfigStore,
  getLatestConfigStore,
  getAllConfigStore,
  getConfigStoreSchema,
  ConfigStoreSchema,
  createNewConfigStoreRevision
} from '../../../services/ConfigStore'

interface Props<T> {
  customerKey: string
  customerEnv: string
  options?: UseQueryOptions<T, AxiosError<T>, T, string>
}

interface MutationProps<T> {
  customerKey: string
  customerEnv: string
  options?: UseMutationOptions<T, AxiosError<T>, T, string>
}

const CACHE_TIME = 1000 * 60 * 5

const configKey = memoize(
  (type: 'all' | 'latest' | 'schema', { customerEnv, customerKey }: { customerKey: string; customerEnv: string }) => {
    switch (type) {
      case 'all':
        return `config-store-all-${customerKey}-${customerEnv}`
      case 'latest':
        return `config-store-latest-${customerKey}-${customerEnv}`
      case 'schema':
        return `config-store-schema-${customerKey}-${customerEnv}`
    }
  },
  (key, { customerEnv, customerKey }) => `${key}-${customerEnv}-${customerKey}`
)

export const useLatestConfigStore = ({ customerEnv, customerKey, options }: Props<ConfigStore>) =>
  useQuery(configKey('latest', { customerEnv, customerKey }), () => getLatestConfigStore(customerKey, customerEnv), {
    cacheTime: CACHE_TIME,
    ...options
  })

export const useAllConfigStores = ({ customerEnv, customerKey, options }: Props<ConfigStore[]>) =>
  useQuery(configKey('all', { customerEnv, customerKey }), () => getAllConfigStore(customerKey, customerEnv), {
    cacheTime: CACHE_TIME,
    ...options
  })

export const useGetConfigStoreSchema = ({ customerEnv, customerKey, options }: Props<ConfigStoreSchema>) =>
  useQuery(configKey('schema', { customerEnv, customerKey }), () => getConfigStoreSchema(customerKey, customerEnv), {
    cacheTime: CACHE_TIME,
    ...options
  })

export const useCreateNewConfigStoreRevision = ({ customerEnv, customerKey, options }: MutationProps<ConfigStore>) => {
  const latestKey = configKey('latest', { customerKey, customerEnv })
  const schemaKey = configKey('schema', { customerEnv, customerKey })

  const queryClient = useQueryClient()
  return useMutation(createNewConfigStoreRevision(customerKey, customerEnv), {
    onMutate: async data => {
      await queryClient.cancelQueries(latestKey)

      const previousConfig = queryClient.getQueryData(latestKey) as ConfigStore

      queryClient.setQueryData<ConfigStore>(latestKey, old => ({
        config: { ...previousConfig.config, ...data.config },
        changedByUser: data.user,
        changedAt: new Date().toISOString(),
        revisionId: old?.revisionId ? old.revisionId + 1 : 0,
        changedBySub: 'PENDING'
      }))

      return { previousConfig }
    },
    onSettled: () => {
      queryClient.refetchQueries(latestKey)
      queryClient.refetchQueries(schemaKey)
    },
    onError: (err, newConfig, context) => {
      queryClient.setQueryData(latestKey, (context as { previousConfig: ConfigStore }).previousConfig)
    }
  })
}
