import { useVersion } from './useVersion'

const parseBranchName = (gitVersion: string | undefined): 0 | number => {
  if (!gitVersion) {
    return 0
  }

  const value = Number(gitVersion.replace('v', '').slice(0, 5).replace('-', '.'))

  if (isNaN(value)) {
    return 0
  }

  return value
}

export const useEnvironmentVersion = (environmentName: string, customerName: string) => {
  const { data, error, isLoading } = useVersion({
    customerName,
    environmentName,
    name: useEnvironmentVersion.name
  })

  const { gitVersion, gitHash, packageVersion, gitBranch } = data || {}

  const version = {
    git: typeof gitVersion === 'string' ? gitVersion : gitHash || '',
    release: typeof gitBranch === 'string' ? gitBranch : '',
    sf: typeof packageVersion === 'string' ? packageVersion : '',
    rawVersion: parseBranchName(gitVersion)
  }

  return { error, isLoading, version }
}
