import { AxiosError } from 'axios'

// eslint-disable-next-line no-shadow
export enum Actions {
  SET_ENVIRONMENT_VERSION = 'SET_ENVIRONMENT_VERSION',
  SET_IS_LOADING_ENVIRONMENT = 'SET_IS_LOADING_ENVIRONMENT',
  SET_ENVIRONMENT_ERROR = 'SET_ENVIRONMENT_ERROR'
}

interface SetEnvironmentVersion {
  type: Actions.SET_ENVIRONMENT_VERSION
  payload: {
    value: {
      git: string
      sf: string
      release: string
    }
    environmentName: string
  }
}

interface SetIsLoadingEnvironment {
  type: Actions.SET_IS_LOADING_ENVIRONMENT
  payload: {
    value: boolean
    environmentName: string
  }
}

interface SetEnvironmentError {
  type: Actions.SET_ENVIRONMENT_ERROR
  payload: {
    value: AxiosError
    environmentName: string
  }
}

export type OtherEnvironmentAction = SetEnvironmentVersion | SetIsLoadingEnvironment | SetEnvironmentError
