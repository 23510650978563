import { FC } from 'react'
import { Header, Loader, Table } from 'semantic-ui-react'

interface ITableLoaderProps {
  loading: boolean
  array: unknown[]
  children: JSX.Element
  emptyMessage: string
}

export const TableLoaderWrap: FC<ITableLoaderProps> = props =>
  props.array.length > 0 && !props.loading ? (
    props.children
  ) : (
    <Table.Footer>
      <Table.Row key={1}>
        <Table.Cell colSpan="16" textAlign="center">
          {props.loading ? (
            <Loader active={props.loading} inline />
          ) : (
            <Header as="h4" textAlign="center">
              {props.emptyMessage}
            </Header>
          )}
        </Table.Cell>
      </Table.Row>
    </Table.Footer>
  )
