import { FC } from 'react'
import { v4 as uuid } from 'uuid'
import { Button, Icon } from 'semantic-ui-react'

type Link = [string, string]

interface CreateEnvironmentButtonsProps {
  accountCode: string
  environmentName: string
  awsAccountId: string
}

interface CreateEnvironmentButtonProps extends CreateEnvironmentButtonsProps {
  link: Link
}

interface CreateEnvironmentLinkProps extends CreateEnvironmentButtonsProps {
  task: string
}

const generateDataDog = ({ accountCode, awsAccountId, environmentName, task }: CreateEnvironmentLinkProps) =>
  [
    'https://app.datadoghq.com/logs?cols=service%2Cstatus&index=&live=true&query=',
    encodeURIComponent(`service:${task} `),
    encodeURIComponent(`instance:vax-${accountCode}-${environmentName} `),
    encodeURIComponent(`aws_account:${awsAccountId} `)
  ].join('')

const links: Link[] = [
  ['Sync Read', 'sync-read'],
  ['Sync Write', 'sync-write'],
  ['Secure API', 'secure'],
  ['Public API', 'public'],
  ['availability', 'availability'],
  ['Sync registration sf', 'sync-registration-sf']
]

const CreateEnvironmentButton: FC<CreateEnvironmentButtonProps> = ({
  accountCode,
  environmentName,
  link,
  awsAccountId
}) => {
  const [text, task] = link

  return (
    <>
      <Button
        icon
        href={generateDataDog({ accountCode, awsAccountId, environmentName, task })}
        labelPosition="right"
        style={{ display: 'flex', width: '90%', margin: 'auto', marginBottom: '5px', textTransform: 'capitalize' }}
        target="_blank"
      >
        Datadog {text}
        <Icon name="file alternate outline" />
      </Button>
    </>
  )
}

export const CreateEnvironmentButtons: FC<CreateEnvironmentButtonsProps> = ({
  accountCode,
  environmentName,
  awsAccountId
}) => (
  <>
    {links.map(link => (
      <CreateEnvironmentButton
        key={uuid()}
        accountCode={accountCode}
        awsAccountId={awsAccountId}
        environmentName={environmentName}
        link={link}
      />
    ))}
  </>
)
