import { FC } from 'react'
import { Column } from 'react-table'
import { LocationCapacityForVaccine } from '../../../../../services/Config'
import { createMemoizedColumns, createMemoizedData, Table } from '../../../../../core/components/Table'

interface AvailableTableData {
  holiday: string
  bookable: string
  nextDoseAvailable: string
  totalSlots: number
  slotsTaken: number
  availableSlots: number
}

const columns: Column<AvailableTableData>[] = [
  {
    Header: 'Holiday',
    accessor: 'holiday'
  },
  {
    Header: 'Bookable',
    accessor: 'bookable'
  },
  {
    Header: 'Next Dose Available',
    accessor: 'nextDoseAvailable'
  },
  {
    Header: 'Total Slots',
    accessor: 'totalSlots'
  },
  {
    Header: 'Slots Taken',
    accessor: 'slotsTaken'
  },
  {
    Header: 'Available Slots',
    accessor: 'availableSlots'
  }
]

interface Props {
  loading: boolean
  config?: LocationCapacityForVaccine
}

export const AvailableTable: FC<Props> = ({ loading, config }) => {
  const availability = config?.availability || []
  const data: AvailableTableData[] =
    availability?.map(item => ({
      holiday: item.holiday ? 'Yes' : 'No',
      bookable: item.isBookable ? 'Yes' : 'No',
      nextDoseAvailable: item.nextDoseAvailable ? 'Yes' : 'No',
      totalSlots: item?.capacity,
      slotsTaken: item?.capacityTaken,
      availableSlots: item?.capacity - (item?.capacityTaken || 0) || 0
    })) || []
  return (
    <div className="scrollable">
      <Table<AvailableTableData>
        className="sticky-table"
        color="blue"
        columns={createMemoizedColumns(columns)}
        data={createMemoizedData(data, [config])}
        loading={loading}
        emptyMessage="No Availability data found for this date."
        size="small"
        striped
      />
    </div>
  )
}
