import { FC } from 'react'
import { AxiosError } from 'axios'
import { Link, Redirect, RouteComponentProps } from 'react-router-dom'
import { Breadcrumb, Grid, Icon, Menu, Tab, Header } from 'semantic-ui-react'
import { Version } from '../../services/Environment'
import { DisplayWithPermission } from '../../core/components/DisplayWithPermission'
import { SidebarInfo } from '../../core/components/SidebarInfo'
import { VersionDisplay } from '../../core/components/VersionDisplay'
import { EnviromentsDetailsProvider } from './context/EnviromentsDetails'
import { ForceSyncButton } from './components/ForceSyncButton'
import { CreateEnvironmentButtons } from './components/EnvironmentLinks'
import { GenerateTokenModal } from './components/GenerateTokenModal'
import { useEnvironmentDetails } from './hooks/useEnvironmentDetails'

interface RouteParams {
  customerCode: string
  customerEnv: string
}

export const EnvironmentDetails: FC<RouteComponentProps<RouteParams>> = ({ history, match }) => {
  const customerCode = match.params.customerCode
  const environmentName = match.params.customerEnv
  const {
    customer,
    environment,
    getPanes,
    loading,
    redirect,
    version,
    versionError,
    activeIndex,
    handleTabChange
  } = useEnvironmentDetails({
    customerCode,
    customerEnv: environmentName
  })

  return (
    <EnviromentsDetailsProvider>
      <div className="route-component">
        {redirect && <Redirect to="/environments" />}
        <Menu secondary fluid stackable>
          <Menu.Menu position="left">
            <Menu.Item>
              <Breadcrumb>
                <Breadcrumb.Section className="back-button" as={Link} to="/environments">
                  <Icon name="chevron left" size="big" />
                  Back
                </Breadcrumb.Section>
                <Breadcrumb.Section>
                  <Header
                    as="h3"
                    style={{
                      marginLeft: '1em',
                      lineHeight: '28px'
                    }}
                  >
                    {customer?.friendlyName} - {environmentName}
                  </Header>
                </Breadcrumb.Section>
              </Breadcrumb>
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right" props>
            <Menu.Item>
              <DisplayWithPermission permission="vax:action:trigger:read-sync">
                <ForceSyncButton customerKey={customerCode} customerEnv={environmentName} />
              </DisplayWithPermission>
            </Menu.Item>
            <Menu.Item>
              <DisplayWithPermission permission="vax:action:create:token">
                <GenerateTokenModal customerKey={customerCode} customerEnv={environmentName} loading={loading} />
              </DisplayWithPermission>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Grid stackable style={{ height: 'calc(100% - 50px)' }}>
          <Grid.Column width={5} style={{ height: '100%', paddingTop: 0 }} className="scrollable">
            <SidebarInfo
              loading={loading}
              table
              data={[
                { name: 'Account Code', value: customer?.accountCode },
                { name: 'Region', value: customer?.region },
                { name: 'Aws Account ID', value: customer?.awsAccountId },
                { name: 'API URL', value: <a href={environment?.api}>{environment?.api}</a> },
                { name: 'Website URL', value: <a href={environment?.static}>{environment?.static}</a> },
                {
                  name: 'SF Package Version',
                  value: (
                    <VersionDisplay
                      loading={loading}
                      version={version?.packageVersion || ''}
                      error={versionError as AxiosError<Version> | undefined}
                    />
                  )
                },
                {
                  name: 'App Version',
                  value: (
                    <VersionDisplay
                      loading={loading}
                      version={version?.gitVersion || version?.gitHash || ''}
                      error={versionError as AxiosError<Version> | undefined}
                    />
                  )
                },
                {
                  name: 'App Release',
                  value: (
                    <VersionDisplay
                      loading={loading}
                      version={version?.gitBranch || ''}
                      error={versionError as AxiosError<Version> | undefined}
                    />
                  )
                }
              ]}
            />
            <DisplayWithPermission permission="vax:action:read:internal-config">
              <SidebarInfo title="Useful Links" className="scrollable">
                <div style={{ width: '90%', margin: 'auto' }}>
                  <CreateEnvironmentButtons
                    accountCode={customer?.accountCode as string}
                    awsAccountId={customer?.awsAccountId as string}
                    environmentName={environmentName}
                  />
                </div>
              </SidebarInfo>
            </DisplayWithPermission>
          </Grid.Column>
          <Grid.Column width={11} stretched style={{ height: '100%' }}>
            <Tab
              menu={{ className: 'scrollable-tab-menu', tabular: true, attached: true }}
              panes={getPanes()}
              style={{ height: '100%' }}
              onTabChange={handleTabChange}
              activeIndex={activeIndex}
            />
          </Grid.Column>
        </Grid>
      </div>
    </EnviromentsDetailsProvider>
  )
}
