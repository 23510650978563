import { Link, useLocation } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'
import { auth } from '../../../services/Endpoints'
import { DisplayWithPermission } from '../DisplayWithPermission'

export const routes = [
  { path: '/environments', name: 'Environments', permission: 'vax:action:read:customers' },
  { path: '/deployments', name: 'Deployments', permission: 'vax:action:read:deployment' }
]

const usePathname = () => {
  const location = useLocation()
  return location.pathname
}

const makeMenuItem = (route: { path: string; name: string; permission: string }, index: number) => {
  const path = usePathname()
  return (
    <DisplayWithPermission key={route.name + index} permission={route.permission}>
      <Menu.Item name={route.name} active={path.indexOf(route.path) > -1} as={Link} to={route.path} key={route.path} />
    </DisplayWithPermission>
  )
}

export const Header = () => (
  <div style={{ padding: '10px' }}>
    <Menu size="huge" stackable color="blue">
      <Menu.Item header>Vax Admin Console</Menu.Item>
      <Menu.Menu position="left">{routes.map((route, index) => makeMenuItem(route, index))}</Menu.Menu>
      <Menu.Menu position="right">
        <Menu.Item name="Log Out" onClick={auth.logout} />
      </Menu.Menu>
    </Menu>
  </div>
)
