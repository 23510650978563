import { useEffect } from 'react'
import { createErrorToast } from '../../../core/components/Alert'
import { Config } from '../../../services/Config'
import { useFetchConfig } from './useFetchConfig'

export const useConfigTable = ({
  customerKey,
  customerEnv,
  getConfigMethod,
  configType
}: {
  customerKey: string
  customerEnv: string
  configType: string
  // eslint-disable-next-line no-shadow
  getConfigMethod: (customerKey: string, customerEnv: string) => Promise<Config>
}) => {
  const { data: config, error, isLoading, refetch } = useFetchConfig({
    customerEnv,
    customerKey,
    getConfigMethod,
    name: configType
  })

  useEffect(() => {
    if (error) {
      createErrorToast(error)
    }
  }, [error])

  const getMetadata = async () => {
    refetch()
  }

  return { loading: !!isLoading, config, getMetadata }
}
