import { AxiosError } from 'axios'
import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query'
import { CustomerInfo, getCustomer } from '../../../services/Customers'

interface Props<T> {
  customerCode: string
  options?: UseQueryOptions<T, AxiosError<T>, T, string>
}

type Result<T> = UseQueryResult<T, AxiosError<T>>

export const useCustomer: CustomHook<Props<CustomerInfo>, Result<CustomerInfo>> = ({ customerCode, options }) =>
  useQuery(`customer-${customerCode}`, () => getCustomer(customerCode), {
    ...options,
    cacheTime: 1000 * 60 * 5
  })
