import type { Reducer } from 'react'
import { EnvironmentDetailsAction, Actions } from '../actions/EnvironmentDetails'

export interface EnvironmentDetailsState {
  activeIndex: number
}

export const defaultEnvironmentDetailsState: EnvironmentDetailsState = {
  activeIndex: 0
}

export const environmentDetailsReducer: Reducer<EnvironmentDetailsState, EnvironmentDetailsAction> = (
  state,
  action
) => {
  switch (action.type) {
    case Actions.SET_ENVIRONMENT_DETAILS_ACTIVE_INDEX_TAB: {
      const { payload: activeIndex } = action

      return {
        ...state,
        activeIndex
      }
    }

    default:
      return state
  }
}
