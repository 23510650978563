import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { getAllVaccines, Vaccines } from '../../../services/Vaccines'

interface Props<T> {
  customerKey: string
  customerEnv: string
  name: string
  options?: UseQueryOptions<T, AxiosError<T>, T, string>
}

type Result<T> = UseQueryResult<T, AxiosError<T>>

type Data = Vaccines

export const useVaccineList: CustomHook<Props<Data>, Result<Data>> = ({ customerKey, customerEnv, name, options }) =>
  useQuery<Vaccines, AxiosError, Vaccines, string>(
    `all-vaccines-${name}-${customerEnv}-${customerKey}`,
    () => getAllVaccines(customerKey, customerEnv),
    options
  )
