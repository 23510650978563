import { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Button, Header, Icon, Image } from 'semantic-ui-react'
import { AuthLoginConnections } from '../../auth/Auth'
import loginLogo from '../../images/login-logo.png'
import { auth } from '../../services/Endpoints'

export class Login extends Component {
  render() {
    if (auth.isAuthenticated()) {
      return <Redirect to="/" />
    }
    return (
      <div
        style={{
          height: '340px',
          maxWidth: '350px',
          margin: 'auto',
          marginTop: 'calc(50vh - 170px)'
        }}
      >
        <Image src={loginLogo} centered />
        <Header as="h1" color="grey">
          Skedulo Vax Admin Console
        </Header>
        <Button
          icon
          labelPosition="left"
          onClick={() => auth.login(AuthLoginConnections.google)}
          size="large"
          color="google plus"
          fluid={true}
          style={{ marginTop: '20px' }}
        >
          <Icon name="google" />
          Login with Skedulo Google
        </Button>
        <Button
          icon
          labelPosition="left"
          onClick={() => auth.login(AuthLoginConnections.sf)}
          size="large"
          color="blue"
          fluid={true}
          style={{ marginTop: '20px' }}
        >
          <Icon name="cloud" />
          Login with Salesforce
        </Button>
        <Button
          icon
          labelPosition="left"
          onClick={() => auth.login(AuthLoginConnections.sfSandbox)}
          size="large"
          color="blue"
          fluid={true}
          style={{ marginTop: '20px' }}
        >
          <Icon name="cloud" />
          Login with Salesforce Sandbox
        </Button>
      </div>
    )
  }
}
