import { AxiosError } from 'axios'
import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query'
import { getLastPIWDate, ConfigOverrideDates } from '../../../services/Environment'

interface Props<T> {
  customerName: string
  environmentName: string
  options?: UseQueryOptions<T, AxiosError<T>, T, string>
}

type Result<T> = UseQueryResult<T, AxiosError<T>>

export const usePerInteractionWorflow: CustomHook<Props<ConfigOverrideDates>, Result<ConfigOverrideDates>> = ({
  customerName,
  environmentName,
  options
}) =>
  useQuery(
    `per-interaction-workflow-${customerName}-${environmentName}`,
    () => getLastPIWDate(customerName, environmentName),
    options
  )
