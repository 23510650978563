import { Button, Grid, Icon, Modal } from 'semantic-ui-react'

interface Props {
  tag: string
  customerKey: string
  customerEnv: string
  open: boolean
  setOpen: (value: boolean) => void
  submitForm: () => void
}

export const DeployConfirmationModal = (props: Props) => {
  const { tag, customerKey, customerEnv, open, setOpen, submitForm } = props
  const closeConfirmationBox = () => {
    setOpen(false)
  }
  return (
    <Modal size="small" open={open} confirmButton="Yes">
      <Modal.Header>
        <Icon name="exclamation triangle" color="yellow" />
        &ensp; Confirm Deployment
      </Modal.Header>
      <Modal.Content style={{ fontSize: '15px' }}>
        You are about to deploy git hash <b>{tag}</b> to: <br />
        <br />
        <b>{customerKey}</b> <br />
        <br />
        <b>{customerEnv}</b> <br />
        <br />
      </Modal.Content>
      <Modal.Actions>
        <Grid columns={3} verticalAlign="middle">
          <Grid.Column width={10} textAlign="left" style={{ fontSize: '15px' }}>
            Are you sure you want to do this?
          </Grid.Column>
          <Grid.Column width={3}>
            <Button
              onClick={() => {
                submitForm()
                closeConfirmationBox()
              }}
              content="Yes"
              fluid
              color="blue"
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Button onClick={closeConfirmationBox} content="Cancel" fluid />
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    </Modal>
  )
}
