import _, { sortBy } from 'lodash'
import { ChangeEventHandler, useEffect, useState, useRef } from 'react'
import { PaginationProps } from 'semantic-ui-react/dist/commonjs/addons/Pagination/Pagination'
import compareVersions from 'compare-versions'

import { createErrorToast } from '../../../core/components/Alert'
import { ParamsLocation, LocationType } from '../../../services/Locations'
import { Actions } from '../actions/Location'
import { useEnviromentsDetailsContext } from '../context/EnviromentsDetails'
import { useLocations, useAllLocations } from './useLocations'
import { useEnvironmentVersion } from './useEnvironmentVersion'

export const useLocationListing = ({ customerKey, customerEnv }: { customerKey: string; customerEnv: string }) => {
  const { location: state, dispatch } = useEnviromentsDetailsContext()
  const { locationListItems, input, page, sortColumn, sortDirection } = state

  const searchLocation = useRef('')
  const isNewVersion = useRef(true)
  const [sort, setSort] = useState({
    _sortBy: '',
    order: ''
  })

  const { version, isLoading: loadingVersion } = useEnvironmentVersion(customerEnv, customerKey)

  const isValidVersion = () => {
    const regex = /^release\/[a-z][0-9-]{1,}$/gi
    const path = version?.release.split('/')[1]

    if (regex.test(version?.release)) {
      return path
    }

    return false
  }

  const getRequestParams = (currentPage: number, search: string, sortLoc: { _sortBy: string; order: string }) => {
    const paramLocations: ParamsLocation = { page: currentPage }

    if (search) {
      paramLocations.search = search
    }
    if (page) {
      paramLocations.page = page
    }
    if (sortLoc.order && sortLoc._sortBy) {
      const { _sortBy, order } = sortLoc
      paramLocations.sortBy = _sortBy
      paramLocations.order = order
    }
    return paramLocations
  }

  const handleVersion = () => {
    if (version?.git && version?.release === 'main') {
      if (!/^v[0-9-]{1,}[a-z0-9-]{1,}/.test(version.git)) {
        return false
      }

      const newGitVersion = version?.git.replaceAll('v', '').split('-')
      newGitVersion.pop()
      const path = newGitVersion.join('.')
      const compareVersion = compareVersions.compare('21.31.3', path, '<=')
      return compareVersion
    }

    if (version?.release === 'HEAD') {
      return true
    }
    const pathOfVersion = isValidVersion()
    if (!pathOfVersion) {
      return false
    }

    if (pathOfVersion) {
      const path = pathOfVersion.replaceAll('v', '').replaceAll('-', '.')
      const compareVersion = compareVersions.compare('21.31.3', path, '<=')
      return compareVersion
    }
    return true
  }

  isNewVersion.current = handleVersion()

  // Pagination in Back-End side
  const params = getRequestParams(page, searchLocation.current, sort)
  const { data: newLocs, isLoading: isLoadingLoc, error } = useLocations({
    customerKey,
    customerEnv,
    params,
    deps: handleVersion() && !loadingVersion
  })

  // Pagination in Front-End side
  const { data: allLocations, isLoading: isLoadingAll, error: errorAll } = useAllLocations({
    customerEnv,
    customerKey,
    deps: !handleVersion() && !loadingVersion
  })

  useEffect(() => {
    if (errorAll || error) {
      const errorAlert = errorAll || error
      createErrorToast(errorAlert || '')
    }
  }, [errorAll, error])

  useEffect(() => {
    if (allLocations) {
      dispatch({ type: Actions.SET_LOCATION_LIST_ITEMS, payload: filteredLocationList(allLocations) })
    }
  }, [allLocations, sortColumn, sortDirection])

  const sortData = (data: LocationType[]) => {
    if (data.length > 0 && sortColumn in data[0]) {
      const sorted = sortBy(data, obj => obj[sortColumn]?.toString().toLowerCase())
      if (sortDirection === 'descending') {
        sorted.reverse()
      }
      return sorted
    }
    return data
  }

  const filteredLocationList = (locationsList?: LocationType[]) => {
    if (locationsList && _.isArray(locationsList)) {
      return sortData(
        locationsList.filter(
          (location: { address: string; externalId: string; name: string }) =>
            input === '' ||
            location.address.toLowerCase().includes(input.toLowerCase()) ||
            location.externalId.toLowerCase().includes(input.toLowerCase()) ||
            location.name.toLowerCase().includes(input.toLowerCase())
        )
      )
    }
    return []
  }

  const mappingHeaderName = (name: string) => name.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

  const onSortLocations = (clickedColumn: string) => () => {
    if (sortColumn !== clickedColumn) {
      dispatch({ type: Actions.SET_SORT_COLUMN, payload: clickedColumn })
      dispatch({ type: Actions.SET_SORT_DIRECTION, payload: 'ascending' })
      if (isNewVersion.current) {
        setSort({
          _sortBy: mappingHeaderName(clickedColumn),
          order: sortDirection.replace('ending', '')
        })
      }
    } else {
      const orderBy = sortDirection === 'ascending' ? 'descending' : 'ascending'
      dispatch({
        type: Actions.SET_SORT_DIRECTION,
        payload: orderBy
      })
      if (isNewVersion.current) {
        setSort({
          _sortBy: mappingHeaderName(clickedColumn),
          order: orderBy.replace('ending', '')
        })
      }
    }
  }

  const onSearchLocations = () => {
    try {
      searchLocation.current = input
      dispatch({ type: Actions.SET_LOCATION_LIST_ITEMS, payload: filteredLocationList(allLocations) })
      dispatch({ type: Actions.SET_PAGINATION, payload: 1 })
    } catch (err) {
      createErrorToast(err)
    }
  }

  const onPaginationChange = (event: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {
    const tempPage = parseInt((data.activePage || '1').toString(), 10)
    const payload = Number.isNaN(page) ? page : tempPage
    dispatch({ type: Actions.SET_PAGINATION, payload })
  }

  const onInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    dispatch({ type: Actions.SET_INPUT, payload: e.target.value })
  }

  return {
    onInputChange,
    input,
    loading: loadingVersion ? loadingVersion : isNewVersion.current ? isLoadingLoc : isLoadingAll,
    locations: isNewVersion.current ? newLocs?.locations || [] : locationListItems.slice(page * 10 - 10, page * 10),
    onSearchLocations,
    onSortLocations,
    sortColumn,
    sortDirection,
    onPaginationChange,
    page,
    totalLocations: isNewVersion.current ? newLocs?.totalLocations : locationListItems.length,
    totalPages: isNewVersion.current ? newLocs?.totalPages : null,
    isNewVersion: isNewVersion.current
  }
}
