import { Link } from 'react-router-dom'
import { Table, Button } from 'semantic-ui-react'
import { LocationType } from '../../../services/Locations'

interface Props {
  id: number
  location: LocationType
  customerName: string
  customerEnv: string
}

export const LocationItem = (props: Props) => (
  <Table.Row key={props.id}>
    <Table.Cell className="table-cell-overflow">{props.location.name}</Table.Cell>
    <Table.Cell className="table-cell-overflow" textAlign="center">
      {props.location.availableDate}
    </Table.Cell>
    <Table.Cell className="table-cell-overflow" textAlign="center">
      {props.location.startDate}
    </Table.Cell>
    <Table.Cell className="table-cell-overflow" textAlign="center">
      {props.location.endDate}
    </Table.Cell>
    <Table.Cell textAlign="center">{props.location.excludeFromSearch ? 'Yes' : 'No'}</Table.Cell>
    <Table.Cell textAlign="center">{props.location.exhausted ? 'Yes' : 'No'}</Table.Cell>
    <Table.Cell textAlign="center" className="table-cell-overflow">
      {props.location.locationPool}
    </Table.Cell>
    <Table.Cell textAlign="center">
      <Button
        icon="file alternate outline"
        as={Link}
        to={`/environments/${props.customerName}/${props.customerEnv}/${props.location.externalId}`}
      />
    </Table.Cell>
  </Table.Row>
)
