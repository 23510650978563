import { FC } from 'react'
import { Column } from 'react-table'
import { LocationCapacityForVaccine } from '../../../../../services/Config'
import { createMemoizedColumns, createMemoizedData, Table } from '../../../../../core/components/Table'

interface SlotTableData {
  availableSlots: number | undefined
  totalSlots: number | undefined
  duration: number
  time: string | undefined
}

const columns: Column<SlotTableData>[] = [
  {
    Header: 'Time',
    accessor: 'time'
  },
  {
    Header: 'Duration (min)',
    accessor: 'duration'
  },
  {
    Header: 'Total Slots',
    accessor: 'totalSlots'
  },
  {
    Header: 'Available Slots',
    accessor: 'availableSlots'
  }
]

interface Props {
  loading: boolean
  config?: LocationCapacityForVaccine
}

export const SlotTable: FC<Props> = ({ loading, config }) => {
  const availability = config?.availability || []
  const slots = availability.map(item => item.slotsWithAvailability).flat()

  const data: SlotTableData[] =
    slots?.map(item => ({
      time: item?.localStartTime,
      duration: item?.durationSeconds ? item.durationSeconds / 60 : 0,
      totalSlots: item?.capacityTotal,
      availableSlots: item?.capacity
    })) || []
  return (
    <div className="scrollable">
      <Table<SlotTableData>
        className="sticky-table"
        color="blue"
        columns={createMemoizedColumns(columns)}
        data={createMemoizedData(data, [config])}
        loading={loading}
        emptyMessage="No Availability data found for this date."
        size="small"
        striped
      />
    </div>
  )
}
