import { Fragment } from 'react'
import { Header, Table } from 'semantic-ui-react'
import { Customers } from '../../../services/Customers'
import { TableLoaderWrap } from '../../../core/components/Loader'
import { EnvironmentRow } from './EnvironmentRow'

interface Props {
  loading: boolean
  customers: Customers
}

export const EnvironmentsTable = (props: Props) => (
  <div className="scrollable">
    <Table striped color="blue" size="small" stackable className="sticky-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Website URL</Table.HeaderCell>
          <Table.HeaderCell>API URL</Table.HeaderCell>
          <Table.HeaderCell>SF Pkg Version</Table.HeaderCell>
          <Table.HeaderCell>App Version</Table.HeaderCell>
          <Table.HeaderCell>App Release</Table.HeaderCell>
          <Table.HeaderCell>Details</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <TableLoaderWrap loading={props.loading} array={Object.keys(props.customers)} emptyMessage="No customers found.">
        <Table.Body>
          {Object.keys(props.customers).map((key: string) => (
            <Fragment key={key}>
              <Table.Row>
                <Table.Cell colSpan={7}>
                  <Header>{props.customers[key].friendlyName}</Header>
                </Table.Cell>
              </Table.Row>
              {Object.keys(props.customers[key].environments).map((env: string) => (
                <EnvironmentRow
                  environmentName={env}
                  environment={props.customers[key].environments[env]}
                  key={key + env}
                  customerName={key}
                />
              ))}
            </Fragment>
          ))}
        </Table.Body>
      </TableLoaderWrap>
    </Table>
  </div>
)
