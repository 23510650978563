import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { DropdownProps, InputOnChangeData, Breadcrumb, Grid, Icon, Form, Button, Header } from 'semantic-ui-react'
import { getCustomers, Customers } from '../../services/Customers'
import {
  Config,
  ConfigValueType,
  getEnvConfig,
  getSecureConfig,
  getClientConfig,
  getConfigDb
} from '../../services/Config'
import { auth } from '../../services/Endpoints'
import { createErrorToast } from '../../core/components/Alert'
import { getAllVaccines } from '../../services/Vaccines'
import { ConfigCompareList } from './components/ConfigCompareList'

interface IState {
  loading: boolean
  showAll: boolean
  customers: Customers
  environment1Config: Config
  environment2Config: Config
  origin: string
  configOptions: object[]
}

interface RouteParams {
  configType: string
}

export interface IConfigComparison {
  name: string
  environment1Value: ConfigValueType
  environment2Value: ConfigValueType
}

interface ConfigTypes {
  [key: string]: {
    permissions: string[]
    configMethod: (customerKey: string, customerEnv: string) => Promise<Config>
  }
}

const configTypes: ConfigTypes = {
  Client: {
    permissions: ['vax:action:read:config'],
    configMethod: getClientConfig
  },
  Server: {
    permissions: ['vax:action:read:config'],
    configMethod: getSecureConfig
  },
  Env: {
    permissions: ['vax:action:read:config'],
    configMethod: getEnvConfig
  },
  RDS: {
    permissions: ['vax:action:read:internal-config'],
    configMethod: getConfigDb
  },
  Vaccines: {
    permissions: ['vax:action:read:config'],
    configMethod: getAllVaccines
  }
}

export class ConfigCompare extends React.PureComponent<RouteComponentProps<RouteParams>, IState> {
  constructor(props: RouteComponentProps<RouteParams>) {
    super(props)
    this.state = {
      environment1Config: {},
      environment2Config: {},
      customers: {},
      loading: false,
      showAll: true,
      origin: '',
      configOptions: this.getConfigOptions()
    }
  }

  async componentDidMount() {
    const { envName, customerKey } = this.getEnvironmentDetails('1')
    this.setState({ origin: `/environments/${customerKey}/${envName}` })
    await this.refreshEnvironments()
  }
  async componentDidUpdate(prevProps: RouteComponentProps<RouteParams>) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        loading: true
      })
      await this.fetchEnvironments()
    }
  }
  refreshEnvironments = async () => {
    this.setState({ loading: true })
    await Promise.all([this.fetchEnvironments(), this.fetchCustomers()])
    this.setState({ loading: false })
  }

  fetchCustomers = async () => {
    try {
      this.setState({ loading: true })
      const customers = await getCustomers()
      this.setState({ customers, loading: false })
    } catch (error) {
      createErrorToast(error)
      this.setState({ loading: false })
    }
  }

  fetchEnvironments = async () => {
    this.setState({ environment1Config: {}, environment2Config: {} })
    const configType = this.props.match.params.configType
    const env1 = this.getEnvironmentDetails('1')
    const env2 = this.getEnvironmentDetails('2')
    this.setState({ loading: true })
    const [environment1Config, environment2Config] = await Promise.all([
      this.fetchEnvironment(configType, env1.customerKey, env1.envName),
      this.fetchEnvironment(configType, env2.customerKey, env2.envName)
    ])
    this.setState({ environment1Config, environment2Config, loading: false })
  }

  fetchEnvironment = async (configType: string, customerKey: string | null, envName: string | null) => {
    if (customerKey && envName) {
      try {
        return await configTypes[configType].configMethod(customerKey, envName)
      } catch (error) {
        createErrorToast(error)
      }
    }
    return {}
  }

  getEnvironmentOptions = () => {
    const customers = this.state.customers
    return Object.keys(customers).reduce((environmentsList: object[], key: string) => {
      const environemnts = Object.keys(customers[key].environments).map((env: string) => ({
        text: `${key} - ${env}`,
        value: [key, env]
      }))
      return [...environmentsList, ...environemnts]
    }, [])
  }

  handleEnvironmentChange = (environmentNum: string) => (
    event: React.SyntheticEvent<HTMLElement>,
    data: InputOnChangeData | DropdownProps
  ) => {
    if (data.value) {
      const search = new URLSearchParams(this.props.location.search)
      const params = [search.get('env1'), search.get('customer1'), search.get('env2'), search.get('customer2')]
      const newParams = []
      if (environmentNum === '1' && typeof data.value === 'object') {
        newParams.push(data.value[1], data.value[0], params[2] ? params[2] : '', params[3] ? params[3] : '')
      } else if (environmentNum === '2' && typeof data.value === 'object') {
        newParams.push(params[0] ? params[0] : '', params[1] ? params[1] : '', data.value[1], data.value[0])
      } else {
        newParams.push(...params)
      }
      const configType = environmentNum === 'configType' ? data.value : this.props.match.params.configType
      this.props.history.push(
        `/config/diff/${configType}?env1=${newParams[0]}&customer1=${newParams[1]}&env2=${newParams[2]}&customer2=${newParams[3]}`
      )
    }
  }

  getTableData = () => {
    const { environment1Config, environment2Config } = this.state
    if (!environment1Config && !environment2Config) {
      return []
    }
    const configFields = Array.from(new Set(Object.keys(environment1Config).concat(Object.keys(environment2Config))))
    return configFields.map(key => ({
      name: key,
      environment1Value: environment1Config[key] ? environment1Config[key] : '',
      environment2Value: environment2Config[key] ? environment2Config[key] : ''
    }))
  }

  toggleShowAll = () => this.setState(prevState => ({ showAll: !prevState.showAll }))

  getEnvironmentTitle = (environmentNumber: string) => {
    const environment = this.getEnvironmentDetails(environmentNumber)
    return environment.customerKey
      ? `${environment?.customerKey} - ${environment?.envName}`
      : `Environment ${environmentNumber}`
  }

  getEnvironmentDetails = (environmentNum: string) => {
    const search = new URLSearchParams(this.props.location.search)
    return {
      envName: search.get(`env${environmentNum}`),
      customerKey: search.get(`customer${environmentNum}`)
    }
  }

  backToOriginalPage = () => {
    this.props.history.push(this.state.origin)
  }

  getConfigOptions = () =>
    Object.keys(configTypes).reduce(
      (configLists: object[], key: string) =>
        configTypes[key].permissions.reduce(
          (acc: boolean, cur: string) => acc && auth.getPermissions().includes(cur),
          true
        )
          ? [...configLists, { value: key, text: key }]
          : [...configLists],
      []
    )

  render() {
    const { loading, showAll, configOptions } = this.state
    if (configOptions.length === 0) {
      return <div style={{ paddingLeft: '10px' }}>You don&apos;t have permission to view Config</div>
    } else {
      return (
        <div className="route-component">
          <Grid columns={3} stackable verticalAlign="middle">
            <Grid.Column width={2}>
              <Breadcrumb>
                <Breadcrumb.Section className="back-button" onClick={this.backToOriginalPage}>
                  <Icon name="chevron left" size="big" />
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
            <Grid.Column width={2}>
              <Header as="h3">Compare View</Header>
            </Grid.Column>
            <Grid.Column width={12} textAlign="right">
              <Grid>
                <Grid.Column width={4}>
                  <Form.Select
                    search
                    fluid
                    selectOnBlur={false}
                    placeholder={this.props.match.params.configType}
                    options={configOptions}
                    required={true}
                    onChange={this.handleEnvironmentChange('configType')}
                    value={this.props.match.params.configType}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Form.Select
                    search
                    fluid
                    selectOnBlur={false}
                    placeholder={this.getEnvironmentTitle('1')}
                    options={this.getEnvironmentOptions() || [{ text: '', value: '' }]}
                    required={true}
                    onChange={this.handleEnvironmentChange('1')}
                    value={this.getEnvironmentTitle('1')}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Form.Select
                    search
                    fluid
                    selectOnBlur={false}
                    placeholder={this.getEnvironmentTitle('2')}
                    options={this.getEnvironmentOptions() || [{ text: '', value: '' }]}
                    required={true}
                    onChange={this.handleEnvironmentChange('2')}
                    value={this.getEnvironmentTitle('2')}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Button
                    content={showAll ? 'Diff' : 'All'}
                    className="form-button-sked-blue"
                    primary
                    fluid
                    onClick={this.toggleShowAll}
                  />
                </Grid.Column>
                <Grid.Column width={1} verticalAlign="middle">
                  <Icon
                    size="large"
                    name="refresh"
                    onClick={this.refreshEnvironments}
                    loading={loading}
                    className="clickable"
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <ConfigCompareList
            configList={this.getTableData()}
            loading={loading}
            showAll={showAll}
            environment1={this.getEnvironmentTitle('1')}
            environment2={this.getEnvironmentTitle('2')}
          />
        </div>
      )
    }
  }
}
