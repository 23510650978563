import type { Reducer } from 'react'
import { LocationType } from '../../../services/Locations'
import { LocationAction, Actions } from '../actions/Location'

export interface LocationState {
  input: string
  locationListItems: LocationType[]
  page: number
  sortColumn: string
  sortDirection: 'ascending' | 'descending'
}

export const defaultLocationState: LocationState = {
  input: '',
  locationListItems: [],
  page: 1,
  sortColumn: '',
  sortDirection: 'ascending'
}

export const locationReducer: Reducer<LocationState, LocationAction> = (state, action) => {
  switch (action.type) {
    case Actions.SET_LOCATION_LIST_ITEMS: {
      const { payload: locationListItems } = action

      return {
        ...state,
        locationListItems
      }
    }

    case Actions.SET_INPUT: {
      const { payload: input } = action

      return {
        ...state,
        input
      }
    }

    case Actions.SET_PAGINATION: {
      const { payload: page } = action

      return {
        ...state,
        page
      }
    }

    case Actions.SET_SORT_COLUMN: {
      const { payload: sortColumn } = action

      return {
        ...state,
        sortColumn
      }
    }

    case Actions.SET_SORT_DIRECTION: {
      const { payload: sortDirection } = action

      return {
        ...state,
        sortDirection
      }
    }

    default:
      return state
  }
}
