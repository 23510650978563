import { Config } from './Config'
import { vaxAdminApi } from './Endpoints'

export interface VaccineDetails {
  id: number
  externalId: string
  detail: {
    brand: string
    isDefault: boolean
    maxInterval: number
    minInterval: number
    numberOfDose: number
  }
}

// TODO: ENG-16335 This type is a hack to make the config compare not break.
export type Vaccines = Config & {
  vaccines: VaccineDetails[]
}

export const getAllVaccines = (customerKey: string, customerEnv: string) =>
  vaxAdminApi
    .get<Vaccines>(`/proxy/${customerKey}/${customerEnv}/secure/config/vaccines`)
    .then(response => response.data)
