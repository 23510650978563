import { FC, CSSProperties } from 'react'
import { Header, Segment } from 'semantic-ui-react'
import { RenderIf } from '../../components/RenderIf'
import { LoaderWrap } from '../Loader'
import { SideBarInfoData, SidebarInfoDataTable } from './components/InfoDataTable'

interface Props {
  data?: SideBarInfoData[]
  loading?: boolean
  style?: CSSProperties
  className?: string
  title?: string
  table?: boolean
}

export const SidebarInfo: FC<Props> = ({ loading, data, title, style = {}, table, children, className }) => (
  <div style={style} className={className}>
    <RenderIf condition={!!title}>
      <Header as="h3">{title}</Header>
    </RenderIf>
    <Segment color="blue">
      <LoaderWrap loading={!!loading}>
        <>
          {table && <SidebarInfoDataTable data={data} />}
          {!table && children}
        </>
      </LoaderWrap>
    </Segment>
  </div>
)
