import React, { useState } from 'react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { useMutation, useQueryClient } from 'react-query'
import { Customers } from '../../../services/Customers'
import { createDeployment } from '../../../services/Deployments'
import { createErrorToast, createSuccessToast } from '../../../core/components/Alert'
import { useAvailableVerions } from '../hooks/useDeployments'
import { DeployConfirmationModal } from './DeployConfirmationModal'

interface Props {
  customers: Customers
  loading: boolean
}

export const DeployModal = (props: Props) => {
  const [customerKey, setCustomerKey] = useState('')
  const [customerEnv, setCustomerEnv] = useState('')
  const [tag, setTag] = useState('')
  const [failure, setFailure] = useState(false)
  const [confirmBox, setConfirmBox] = useState(false)
  const [doingDeploy, setDoingDeploy] = useState(false)
  const queryClient = useQueryClient()

  const { data: availableVersions } = useAvailableVerions({})

  const addDeployment = useMutation(() => createDeployment({ customerKey, customerEnv, tag }), {
    onSuccess: () => {
      queryClient.invalidateQueries('deployments')
      queryClient.invalidateQueries('version')
      createSuccessToast(`Deployment to ${customerKey} ${customerEnv} with tag ${tag} Started.`)
      setFailure(false)
      setDoingDeploy(false)
    },
    onError: () => {
      createErrorToast(`Deployment Errored ocurred in ${customerKey} ${customerEnv} with tag ${tag}. Contact Support.`)
      setFailure(true)
      setDoingDeploy(false)
    }
  })

  const validateForm = () => !!(props.customers[customerKey]?.environments?.[customerEnv] && tag)

  const handleClose = () => setFailure(false)

  const handleOpen = () => setFailure(true)

  const submitForm = async () => {
    if (validateForm()) {
      setDoingDeploy(true)
      await addDeployment.mutateAsync()
    }
  }

  const hashRegex = new RegExp('^[a-fA-F0-9]{40}$')

  return (
    <Modal
      size="tiny"
      closeIcon
      closeOnDimmerClick={false}
      onClose={handleClose}
      open={failure}
      trigger={
        <Button
          onClick={handleOpen}
          content="Deploy Git Hash"
          color="blue"
          disabled={props.loading}
          loading={props.loading}
        />
      }
    >
      <Modal.Header>Deploy to Environment</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Dropdown
            label="Customer Key"
            placeholder="e.g. California = CAL"
            selection
            search
            options={Object.keys(props.customers).map(customerKeyString => ({
              text: customerKeyString,
              value: customerKeyString
            }))}
            value={customerKey}
            onChange={(event, data) => setCustomerKey(data.value as string)}
          />
          <Form.Dropdown
            label="Customer Env"
            placeholder="e.g. dev, QA"
            selection
            search
            disabled={!customerKey}
            options={Object.keys(props.customers[customerKey]?.environments || {}).map(customerKeyString => ({
              text: customerKeyString,
              value: customerKeyString
            }))}
            value={customerEnv}
            onChange={(event, data) => setCustomerEnv(data.value as string)}
          />
          <Form.Dropdown
            label="Git Tag"
            placeholder="Tag to Deploy"
            selection
            search
            lazyLoad
            options={(availableVersions || [])
              .filter(version => hashRegex.test(version))
              .map(version => ({
                text: version,
                value: version
              }))}
            value={tag}
            onChange={(event, data) => setTag(data.value as string)}
          />
          <Modal.Actions>
            <Form.Button
              onClick={() => setConfirmBox(true)}
              content="Deploy"
              fluid
              color="blue"
              loading={doingDeploy}
              disabled={doingDeploy || !validateForm()}
            />
          </Modal.Actions>
        </Form>
      </Modal.Content>
      <DeployConfirmationModal
        open={confirmBox}
        setOpen={setConfirmBox}
        submitForm={submitForm}
        tag={tag}
        customerKey={customerKey}
        customerEnv={customerEnv}
      />
    </Modal>
  )
}
