import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  DropdownProps,
  InputOnChangeData,
  Breadcrumb,
  Grid,
  Icon,
  Form,
  Button,
  Header,
  DropdownItemProps
} from 'semantic-ui-react'
import { format } from 'date-fns'
import { getCustomers, Customers } from '../../services/Customers'
import { Config } from '../../services/Config'
import { auth } from '../../services/Endpoints'
import { createErrorToast } from '../../core/components/Alert'
import { ConfigStoreRevision, getConfigStoreByRevisionId, getConfigStoreHistory } from '../../services/ConfigStore'
import { ConfigStoreComparisonSelectionType, DATE_FORMAT, READ_CONFIG_STORE_PERMISSION } from '../../core/utils'
import { ConfigCompareList } from './components/ConfigCompareList'

interface IState {
  loading: boolean
  showAll: boolean
  revisionOptions: DropdownItemProps[]
  customerOptions: DropdownItemProps[]
  environmentConfig: Config
  revision1Config: Config
  revision2Config: Config
  origin: string
  isUserHasPermission: boolean
}

interface RouteParams {
  configType: string
}

interface SearchParams {
  envName: string
  customerKey: string
  revision1: number
  revision2: number
}

export class ConfigStoreCompare extends React.PureComponent<RouteComponentProps<RouteParams>, IState> {
  constructor(props: RouteComponentProps<RouteParams>) {
    super(props)
    const defaultDropdownItemProps = [{ text: '', value: '' }]
    this.state = {
      revisionOptions: defaultDropdownItemProps,
      customerOptions: defaultDropdownItemProps,
      environmentConfig: {},
      revision1Config: {},
      revision2Config: {},
      loading: false,
      showAll: true,
      origin: '',
      isUserHasPermission: this.isUserHasPermission()
    }
  }

  async componentDidMount() {
    const { envName, customerKey } = this.getSearchDetails()
    this.setState({ origin: `/environments/${customerKey}/${envName}` })
    await this.refreshRevisions()
  }
  async componentDidUpdate(prevProps: RouteComponentProps<RouteParams>) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        loading: true
      })
      await Promise.all([this.loadHistory(), this.loadRevisions()])
    }
  }
  refreshRevisions = async () => {
    this.setState({ loading: true })
    await Promise.all([this.loadHistory(), this.fetchCustomers(), this.loadRevisions()])
    this.setState({ loading: false })
  }

  fetchCustomers = async () => {
    try {
      this.setState({ loading: true })
      const customers = await getCustomers()
      const customerOptions = this.getCustomerEnvOptions(customers)
      this.setState({ customerOptions })
    } catch (error) {
      createErrorToast(error as string | Error)
    } finally {
      this.setState({ loading: false })
    }
  }

  loadHistory = async () => {
    const { envName, customerKey } = this.getSearchDetails()
    this.setState({ loading: true })
    const history = await this.fetchHistory(customerKey, envName)
    this.setState({ revisionOptions: this.getRevisionOptions(history), loading: false })
  }

  fetchHistory = async (customerKey: string | null, envName: string | null) => {
    if (customerKey && envName) {
      try {
        return await getConfigStoreHistory(customerKey, envName)
      } catch (error) {
        createErrorToast(error as string | Error)
      }
    }
    return []
  }

  loadRevisions = async () => {
    const rev1 = this.getRevisionIdFromURL(ConfigStoreComparisonSelectionType.RevisionLeft)
    const rev2 = this.getRevisionIdFromURL(ConfigStoreComparisonSelectionType.RevisionRight)
    this.setState({ loading: true })
    const [revision1Config, revision2Config] = await Promise.all([this.fetchRevision(rev1), this.fetchRevision(rev2)])
    this.setState({ revision1Config, revision2Config, loading: false })
  }

  fetchRevision = async (revisionId: number) => {
    const { customerKey, envName } = this.getSearchDetails()
    if (customerKey && envName) {
      try {
        return await getConfigStoreByRevisionId(customerKey, envName, revisionId)
      } catch (error) {
        createErrorToast(error as string | Error)
      }
    }
    return {}
  }

  getCustomerEnvOptions = (customers: Customers) =>
    Object.keys(customers).reduce((environmentsList: object[], key: string) => {
      const environemnts = Object.keys(customers[key].environments).map((env: string) => ({
        text: `${key} - ${env}`,
        value: [key, env]
      }))
      return [...environmentsList, ...environemnts]
    }, [])

  getRevisionOptions = (history: ConfigStoreRevision[]) =>
    history.map((cur: ConfigStoreRevision) => {
      const dateCreated = cur.changedAt ? format(new Date(cur.changedAt), DATE_FORMAT) : 'No date'
      const revision = {
        text: `${dateCreated} - ${cur.changedByUser}`,
        value: cur.revisionId
      }
      return revision
    })

  handleSelectionChange = (selectionType: ConfigStoreComparisonSelectionType) => (
    event: React.SyntheticEvent<HTMLElement>,
    data: InputOnChangeData | DropdownProps
  ) => {
    if (data.value) {
      const searchParams = this.buildSearchParams(selectionType, data.value)
      this.props.history.push(
        `/config-store/diff?env=${searchParams.envName}&customer=${searchParams.customerKey}&revision1=${searchParams.revision1}&revision2=${searchParams.revision2}`
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buildSearchParams = (selectionType: ConfigStoreComparisonSelectionType, value: any): SearchParams => {
    const currentSearchParams = this.getSearchDetails()
    const isEnvSelectionChanged = selectionType === ConfigStoreComparisonSelectionType.Env && typeof value === 'object'
    const isRevisionLeftSelectionChanged =
      selectionType === ConfigStoreComparisonSelectionType.RevisionLeft && typeof value === 'number'
    const isRevisionRightSelectionChanged =
      selectionType === ConfigStoreComparisonSelectionType.RevisionRight && typeof value === 'number'
    if (isEnvSelectionChanged) {
      // default revisionId is 1
      return { envName: value[1], customerKey: value[0], revision1: 1, revision2: 1 }
    }
    if (isRevisionLeftSelectionChanged) {
      return { ...currentSearchParams, revision1: value }
    }
    if (isRevisionRightSelectionChanged) {
      return { ...currentSearchParams, revision2: value }
    }
    return { ...currentSearchParams }
  }

  getTableData = () => {
    const { revision1Config, revision2Config } = this.state
    if (!revision1Config && !revision2Config) {
      return []
    }
    const configFields = Array.from(new Set(Object.keys(revision1Config).concat(Object.keys(revision2Config))))
    return configFields.map(key => ({
      name: key,
      environment1Value: revision1Config[key] ? revision1Config[key] : '',
      environment2Value: revision2Config[key] ? revision2Config[key] : ''
    }))
  }

  toggleShowAll = () => this.setState(prevState => ({ showAll: !prevState.showAll }))

  getCustomerEnvTitle = () => {
    const environment = this.getSearchDetails()
    return environment.customerKey ? `${environment?.customerKey} - ${environment?.envName}` : 'Environment'
  }

  getRevisionTitle = (revisionSelectionNumber: string) => {
    const revisionId = this.getRevisionIdFromURL(revisionSelectionNumber)
    // eslint-disable-next-line radix
    const revision = this.state.revisionOptions.find(item => item.value === revisionId)
    return revision?.text!.toString() ?? 'Revision'
  }

  getRevisionIdFromURL = (revisionSelectionNumber: string) => {
    const search = new URLSearchParams(this.props.location.search)
    return Number(search.get(`revision${revisionSelectionNumber}`))
  }

  getSearchDetails = () => {
    const search = new URLSearchParams(this.props.location.search)
    return {
      envName: search.get('env'),
      customerKey: search.get('customer'),
      revision1: search.get('revision1') || 1,
      revision2: search.get('revision2') || 1
    } as SearchParams
  }

  backToOriginalPage = () => {
    this.props.history.push(this.state.origin)
  }

  isUserHasPermission = () => auth.getPermissions().includes(READ_CONFIG_STORE_PERMISSION)

  render() {
    const { loading, showAll, isUserHasPermission } = this.state
    if (!isUserHasPermission) {
      return <div style={{ paddingLeft: '10px' }}>You don&apos;t have permission to view Config</div>
    } else {
      return (
        <div className="route-component">
          <Grid columns={3} stackable verticalAlign="middle">
            <Grid.Column width={1}>
              <Breadcrumb>
                <Breadcrumb.Section className="back-button" onClick={this.backToOriginalPage}>
                  <Icon name="chevron left" size="big" />
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
            <Grid.Column width={13} textAlign="right">
              <Grid>
                <Grid.Column width={2}>
                  <Header as="h4" className={'col-h4-middle'}>
                    Environment:
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Form.Select
                    search
                    fluid
                    selectOnBlur={false}
                    placeholder={this.getCustomerEnvTitle()}
                    options={this.state.customerOptions}
                    required={true}
                    onChange={this.handleSelectionChange(ConfigStoreComparisonSelectionType.Env)}
                    value={this.getCustomerEnvTitle()}
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <Header as="h4" className={'col-h4-middle'}>
                    Compare:
                  </Header>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Form.Select
                    search
                    fluid
                    selectOnBlur={false}
                    placeholder={this.getRevisionTitle(ConfigStoreComparisonSelectionType.RevisionLeft)}
                    options={this.state.revisionOptions}
                    required={true}
                    onChange={this.handleSelectionChange(ConfigStoreComparisonSelectionType.RevisionLeft)}
                    value={this.getRevisionTitle(ConfigStoreComparisonSelectionType.RevisionLeft)}
                  />
                </Grid.Column>

                <Grid.Column width={1}>
                  <Header as="h4" className={'col-h4-middle'}>
                    With:
                  </Header>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Form.Select
                    search
                    fluid
                    selectOnBlur={false}
                    placeholder={this.getRevisionTitle(ConfigStoreComparisonSelectionType.RevisionRight)}
                    options={this.state.revisionOptions}
                    required={true}
                    onChange={this.handleSelectionChange(ConfigStoreComparisonSelectionType.RevisionRight)}
                    value={this.getRevisionTitle(ConfigStoreComparisonSelectionType.RevisionRight)}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column width={2}>
              <Grid>
                <Grid.Column width={12}>
                  <Button
                    content={showAll ? 'Diff' : 'All'}
                    className="form-button-sked-blue"
                    primary
                    fluid
                    onClick={this.toggleShowAll}
                  />
                </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  <Icon
                    size="large"
                    name="refresh"
                    onClick={this.refreshRevisions}
                    loading={loading}
                    className="clickable"
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <ConfigCompareList
            configList={this.getTableData()}
            loading={loading}
            showAll={showAll}
            environment1={this.getRevisionTitle(ConfigStoreComparisonSelectionType.RevisionLeft)}
            environment2={this.getRevisionTitle(ConfigStoreComparisonSelectionType.RevisionRight)}
          />
        </div>
      )
    }
  }
}
