import { vaxAdminApi } from './Endpoints'

export interface Scopes {
  scopes: string[]
  roles: { [x: string]: string[] }
}

interface NewToken {
  subject: string
  scopes: string[]
  exp?: number
}

export interface Token {
  iat: number
  exp: number
  jti: string
  iss: string
  aud: string
  token: string
}

export interface TokenInfo {
  iat: string
  exp?: string
  jti: string
  sub: string
  scopes: string[]
  generatedBy: string
}

export const getScopes = (customerKey: string, customerEnv: string) =>
  vaxAdminApi
    .get<Scopes>(`/proxy/${customerKey}/${customerEnv}/secure/config/all_scopes`)
    .then(response => response.data)

export const generateToken = (customerKey: string, customerEnv: string, newToken: NewToken) =>
  vaxAdminApi.post<Token>(`/tokens/${customerKey}/${customerEnv}/generate`, newToken).then(response => response.data)

export const getGeneratedTokens = (customerKey: string, customerEnv: string) =>
  vaxAdminApi.get<TokenInfo[]>(`/tokens/${customerKey}/${customerEnv}`).then(response => response.data)
