import { ChangeEventHandler, useEffect, useState } from 'react'
import { createErrorToast } from '../../../core/components/Alert'
import { CustomerEnvironments, Customers } from '../../../services/Customers'
import { useCustomers } from '../../../core/hooks/useCustomers'

export const useEnvironmentsList = () => {
  const [searchValue, setSearchValue] = useState('')

  const { data, refetch, isLoading, isFetching } = useCustomers({
    name: useEnvironmentsList.name,
    options: {
      onError: error => {
        if (error.message === 'Network Error') {
          createErrorToast('Failed to get customers with a network error. Are you connected to the Secure Network/VPN')
        } else {
          createErrorToast(error)
        }
      },
      initialData: {}
    }
  })

  const customers = data as Customers

  useEffect(() => {
    !(data && Object.keys(data).length) && refetch() // if data is not there, refetch it
  }, [])

  const changeSearchValue: ChangeEventHandler<HTMLInputElement> = e => {
    setSearchValue(e.target.value)
  }

  const filterEnvironments = (environments: CustomerEnvironments) =>
    Object.keys(environments)
      .filter((environmentName: string) => environmentName.toLowerCase().includes(searchValue.toLowerCase()))
      .reduce(
        (filteredEnvironments: CustomerEnvironments, environmentName: string) => ({
          ...filteredEnvironments,
          [environmentName]: environments[environmentName]
        }),
        {}
      )

  const searchEnvironmentsList = () =>
    Object.keys(customers).reduce((filteredCustomers: Customers, key: string) => {
      if (
        key.toLowerCase().includes(searchValue.toLowerCase()) ||
        customers[key].friendlyName.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        return { ...filteredCustomers, [key]: customers[key] }
      } else {
        const matchEnvironments = filterEnvironments(customers[key].environments)
        return Object.keys(matchEnvironments).length > 0
          ? { ...filteredCustomers, [key]: { ...customers[key], environments: matchEnvironments } }
          : { ...filteredCustomers }
      }
    }, {})

  return { isLoading, isFetching, searchEnvironmentsList, fetchCustomers: refetch, changeSearchValue }
}
