import React from 'react'
import * as _ from 'lodash'
import { Column } from 'react-table'
import { Form, Icon, Popup } from 'semantic-ui-react'

import { DetailsTabProps } from '../../../../core/interfaces'
import { MessageKeys } from '../../../../services/MessageKeys'
import { createMemoizedColumns, Table } from '../../../../core/components/Table'
import { useMessageKeys } from '../../hooks/useMessageKeys'

interface MessageKeysTableData {
  messageKey: React.ReactElement
  type: React.ReactElement
  required?: React.ReactElement | null
  defaultValue?: string
  description?: string
}

const columns: Column<MessageKeysTableData>[] = [
  {
    Header: (
      <>
        Message Key <Popup trigger={<Icon name="question circle" color="blue" />}>Message key for SF</Popup>
      </>
    ),
    accessor: 'messageKey'
  },
  {
    Header: (
      <>
        Type{' '}
        <Popup
          trigger={<Icon name="question circle" color="blue" />}
          content={
            <ul>
              <li>
                <code>text</code>: This key only supports text.
              </li>
              <li>
                <code>markdown</code>: This key supports text and Markdown.
              </li>
              <li>
                <code>html</code>: This key supports text and HTML.
              </li>
            </ul>
          }
        />
      </>
    ),
    accessor: 'type'
  },
  {
    Header: (
      <div style={{ minWidth: '100px' }}>
        Required{' '}
        <Popup trigger={<Icon name="question circle" color="blue" />}>
          The message key must exist in SF, if is not present it will fallback either to default message or the key
          itself.
        </Popup>
      </div>
    ),
    accessor: 'required'
  },
  {
    Header: (
      <>
        Default Value{' '}
        <Popup trigger={<Icon name="question circle" color="blue" />}>
          Default value for a required AWS message key. (Used when the key does not exist in SF)
        </Popup>
      </>
    ),
    accessor: 'defaultValue'
  },
  {
    Header: (
      <>
        Description{' '}
        <Popup trigger={<Icon name="question circle" color="blue" />}>Description summary about the message key.</Popup>
      </>
    ),
    accessor: 'description'
  }
]

export const MessageKeysTable: React.FC<DetailsTabProps> = ({ customerEnv, customerKey }) => {
  const { isLoading, data: messageKeys } = useMessageKeys({ customerEnv, customerKey })
  const [keyPairs, setKeyPairs] = React.useState<[string, MessageKeys][]>([])
  const [filter, setFilter] = React.useState<string>('')
  const [filteredKeys, setFilteredKeys] = React.useState<[string, MessageKeys][]>([])

  React.useEffect(() => setKeyPairs(_.toPairs(messageKeys)), [messageKeys])

  React.useEffect(
    () =>
      setFilteredKeys(
        keyPairs.filter(
          pair => pair[0].toLowerCase().includes(filter) || pair[1].defaultValue?.toLowerCase().includes(filter)
        )
      ),
    [filter, keyPairs]
  )

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = e => setFilter(e.target.value.toLowerCase())

  const data = (): MessageKeysTableData[] => {
    if (keyPairs.length === 0) {
      return []
    }

    const keysArray = filter.length === 0 ? keyPairs : filteredKeys

    return keysArray.map((pair: [string, MessageKeys]) => ({
      messageKey: <strong>{pair[0]}</strong>,
      type: <code>{pair[1].type}</code>,
      required: pair[1].required ? <Icon name="check" color="blue" size="large" /> : null,
      defaultValue: pair[1].defaultValue,
      description: pair[1].description
    }))
  }

  return (
    <div className="scrollable">
      <p>List of all the current message keys available and used in AWS:</p>
      <p>
        <small>NOTE: This content is only available for environments on v21.23+</small>
      </p>

      <Form.Input onChange={onInputChange} placeholder="Filter by Message Key or Description" fluid />
      <Table<MessageKeysTableData>
        className="sticky-table"
        color="blue"
        columns={createMemoizedColumns(columns)}
        data={data()}
        loading={isLoading}
        emptyMessage="No Availability data found for this date."
        size="small"
        striped
        textAlign="left"
        withSort
      />
    </div>
  )
}
