import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { Header, Table } from 'semantic-ui-react'

export interface SideBarInfoData {
  name: string
  value?: DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> | string | number
}

interface Props {
  data?: SideBarInfoData[]
}

export const ReleaseInfo: FC<Props> = ({ data }) => (
  <Table style={{ border: 'none' }}>
    <Table.Body>
      {data?.map(detail => (
        <Table.Row key={detail.name}>
          <Table.Cell
            width={5}
            style={{ border: 'none', paddingLeft: '0px', paddingBottom: '8px' }}
            verticalAlign="top"
          >
            <Header size="tiny">{detail.name}</Header>
          </Table.Cell>
          <Table.Cell style={{ border: 'none', paddingLeft: '0px', paddingBottom: '8px' }} verticalAlign="top">
            <p className="table-cell-overflow">{detail.value || ''}</p>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)
