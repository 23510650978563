import { AxiosError } from 'axios'
import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query'
import { getCapacitySnapshot, CapacitySnapshot } from '../../services/Locations'

interface Props<T> {
  customerKey: string
  customerEnv: string
  name: string
  options?: UseQueryOptions<T, AxiosError<T>, T, string>
}

type Result<T> = UseQueryResult<T, AxiosError<T>>

export const useCapacitySnapshot: CustomHook<Props<CapacitySnapshot[]>, Result<CapacitySnapshot[]>> = ({
  customerKey,
  customerEnv,
  name,
  options
}) =>
  useQuery(
    `capacity-snapshot-${name}-${customerEnv}-${customerKey}`,
    () => getCapacitySnapshot(customerKey, customerEnv),
    options
  )
