// eslint-disable-next-line no-shadow
export enum Actions {
  SET_DEBUG_LOCATION_TABLE_LOCATION_ID = 'SET_DEBUG_LOCATION_TABLE_LOCATION_ID',
  SET_DEBUG_LOCATION_TABLE_SHOW_RAW_DATA = 'SET_DEBUG_LOCATION_TABLE_SHOW_RAW_DATA'
}

interface SetDebugLocationTableLocationId {
  type: Actions.SET_DEBUG_LOCATION_TABLE_LOCATION_ID
  payload: string
}

interface SetDebugLocationTableShowRawData {
  type: Actions.SET_DEBUG_LOCATION_TABLE_SHOW_RAW_DATA
  payload: boolean
}

export type DebugLocationTableAction = SetDebugLocationTableLocationId | SetDebugLocationTableShowRawData
