import { FC, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Grid, Icon, Input } from 'semantic-ui-react'
import { DeploymentLog } from '../../services/Deployments'
import { createErrorToast } from '../../core/components/Alert'
import { RouteParams } from '../Locations/LocationDetails'
import { DisplayWithPermission } from '../../core/components/DisplayWithPermission'
import { useCustomers } from '../../core/hooks/useCustomers'
import { DeploymentLogTable } from './components/DeploymentLogTable'
import { DeployModal } from './components/DeployModal'
import { CreateReleaseModal } from './components/CreateReleaseModal'
import { DeployReleaseModal } from './components/DeployReleaseModal'
import { useDeployments } from './hooks/useDeployments'
import { ReleaseProvider } from './context/Release'

const searchDeployments = (searchQuery: string) => (deployment: DeploymentLog) => {
  const searched = Object.values(deployment).map(value =>
    `${value}`.toString().toLowerCase().includes(searchQuery.toLowerCase())
  )
  return searched.reduce((previousValue, currentValue) => previousValue || currentValue, false)
}

export const DeploymentsList: FC<RouteComponentProps<RouteParams>> = ({ match, history }) => {
  const [searchQuery, setSearchQuery] = useState('')

  const {
    data: deployments,
    isLoading: loadingDeployments,
    isFetching: isFetchingDeployments,
    refetch: fetchDeployments
  } = useDeployments({
    options: { onError: error => createErrorToast(error), refetchInterval: 15000 }
  })

  const { data: customers, isLoading: loadingCustomers } = useCustomers({
    name: DeploymentsList.name,
    options: { onError: error => createErrorToast(error) }
  })

  const filteredDeployments = deployments?.filter(searchDeployments(searchQuery))

  return (
    <ReleaseProvider>
      <div className="route-component">
        <Grid columns={4} stackable verticalAlign="middle">
          <Grid.Column width={10}>
            <Grid verticalAlign="middle">
              <Grid.Column>
                <Icon
                  size="large"
                  name="refresh"
                  loading={isFetchingDeployments}
                  className="clickable"
                  onClick={fetchDeployments}
                />
              </Grid.Column>
              <Grid.Column>
                <Input
                  icon="search"
                  placeholder="Search..."
                  type="text"
                  value={searchQuery}
                  onChange={(event, data) => setSearchQuery(data.value)}
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column width={2} textAlign="right">
            <DisplayWithPermission permission="vax:action:create:release">
              <CreateReleaseModal loading={loadingCustomers} />
            </DisplayWithPermission>
          </Grid.Column>
          <Grid.Column width={2} textAlign="right">
            <DisplayWithPermission permission="vax:action:deploy:release">
              <DeployReleaseModal customers={customers || {}} loading={loadingCustomers} />
            </DisplayWithPermission>
          </Grid.Column>
          <Grid.Column width={2} textAlign="right">
            <DisplayWithPermission permission="vax:action:create:deployment">
              <DeployModal customers={customers || {}} loading={loadingCustomers} />
            </DisplayWithPermission>
          </Grid.Column>
        </Grid>
        <DeploymentLogTable loading={loadingDeployments} deploymentLogs={filteredDeployments || []} />
      </div>
    </ReleaseProvider>
  )
}
