import { AxiosError } from 'axios'
import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query'
import { getLocationDebugExtended, LocationExtendedDebug } from '../../services/Config'

interface Props {
  customerKey: string
  customerEnv: string
  locationId: string
  options?: UseQueryOptions<LocationExtendedDebug, AxiosError>
}

export const useLocationExtendedDebug: CustomHook<Props, UseQueryResult<LocationExtendedDebug, AxiosError>> = ({
  customerKey,
  customerEnv,
  locationId,
  options
}) =>
  useQuery(
    `location-debug-extended-${customerKey}-${customerEnv}-${locationId}`,
    () => getLocationDebugExtended(customerKey, customerEnv, locationId),
    { ...options, enabled: !!locationId }
  )
