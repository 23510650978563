import { FC, useState } from 'react'
import { Button, Input, Grid, Header, Checkbox, Segment } from 'semantic-ui-react'
import Inspector, { chromeLight } from 'react-inspector'
import { DetailsTabProps } from '../../../../core/interfaces'
import { useDebugLocationTable } from '../../hooks/useDebugLocationTable'
import { useVaccineList } from '../../hooks/useVaccineList'
import { getClientConfig, LocationVaccineConsumption } from '../../../../services/Config'
import { useFetchConfig } from '../../../Environments/hooks/useFetchConfig'
import { usePerInteractionWorflow } from '../../../Environments/hooks/usePerInteractionWorflow'
import { LocationTable } from './components/LocationTable'

interface Props extends DetailsTabProps {
  locationId?: string
}

export interface LocationVaccineConsumptionWithIntervals extends LocationVaccineConsumption {
  maxInterval: number
  minInterval: number
}

export const DebugLocationTable: FC<Props> = ({ customerKey, customerEnv, locationId: locationIdProp }) => {
  const [errorCode, setErrorCode] = useState<number | undefined>()

  const {
    fetchLocations,
    isLoading,
    onRawCheckboxChanged,
    showRawData,
    config,
    locationId,
    onChangeLocationId
  } = useDebugLocationTable({
    customerEnv,
    customerKey,
    locationId: locationIdProp,
    name: 'LocationsDetails-DebugLocationTable'
  })

  const { data: perInteractionWorkflowLastDate } = usePerInteractionWorflow({
    customerName: customerKey,
    environmentName: customerEnv
  })

  const { data: envConfig } = useFetchConfig({
    customerEnv,
    customerKey,
    getConfigMethod: getClientConfig,
    name: 'Client'
  })

  const { data: vaccineList, isLoading: isLoadingVaccineList } = useVaccineList({
    customerEnv,
    customerKey,
    name: DebugLocationTable.name,
    options: {
      onError: err => {
        setErrorCode(err.response?.status)
      },
      retry: count => !count
    }
  })

  const newConfig: LocationVaccineConsumptionWithIntervals[] | undefined = config?.consumption.map(location => {
    const vaccine = vaccineList?.vaccines.find(vax => vax.externalId === location.vaccine.id)
    return {
      ...location,
      minInterval: vaccine?.detail.minInterval || 0,
      maxInterval: vaccine?.detail.maxInterval || 0
    }
  })

  const locationProps = {
    endDate: config?.location?.endDate ? new Date(config?.location?.endDate) : new Date('2099-01-01'),
    perInteractionWorkflow: !!perInteractionWorkflowLastDate?.configOverride?.createdAt,
    dose1Upfront: !!envConfig?.forceSingleBooking
  }

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        {!locationIdProp && (
          <Grid>
            <Grid.Column width={10} textAlign="left" verticalAlign="middle">
              <Header as="h3">Location Id</Header>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right" verticalAlign="middle" style={{ paddingRight: '0px' }}>
              <Input value={locationId} onChange={onChangeLocationId} fluid />
            </Grid.Column>
            <Grid.Column width={2} textAlign="right">
              <Button color="blue" content="Get" onClick={fetchLocations} />
            </Grid.Column>
          </Grid>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {' '}
        <Checkbox toggle label="Raw Data" onChange={onRawCheckboxChanged} />
      </div>
      {showRawData ? (
        <Segment color="blue">
          <Inspector theme={{ ...chromeLight, ...{ BASE_BACKGROUND_COLOR: 'none' } }} data={config} expandLevel={1} />
        </Segment>
      ) : (
        <LocationTable
          loading={isLoading}
          config={newConfig}
          errorVaccineListCode={errorCode}
          isLoadingVaccineList={isLoadingVaccineList}
          locationProperties={locationProps}
        />
      )}
    </div>
  )
}
