import { AxiosError } from 'axios'
import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query'
import { ReleaseLog, getRelease } from '../../../services/Release'

interface Props<T> {
  options?: UseQueryOptions<T, AxiosError<T>, T, string>
}

type Result<T> = UseQueryResult<T, AxiosError<T>>

export const useRelease: CustomHook<Props<ReleaseLog>, Result<ReleaseLog>> = ({ options }) =>
  useQuery('release/latest', () => getRelease(), options)
