import { AxiosError } from 'axios'
import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query'
import { DeploymentLog, getDeployments, getAvailableVersions } from '../../../services/Deployments'

interface Props<T> {
  options?: UseQueryOptions<T, AxiosError<T>, T, string>
}

type Result<T> = UseQueryResult<T, AxiosError<T>>

type Element = DeploymentLog[]

export const useDeployments: CustomHook<Props<Element>, Result<Element>> = ({ options }) =>
  useQuery('deployments', () => getDeployments(), options)

export const useAvailableVerions: CustomHook<Props<string[]>, Result<string[]>> = ({ options }) =>
  useQuery('availabbe versions', () => getAvailableVersions(), options)
