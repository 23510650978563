import { SyntheticEvent, useState } from 'react'
import { StrictTabProps, TabProps } from 'semantic-ui-react'
import { Actions } from '../../../core/actions/EnvironmentDetails'
import { createErrorToast } from '../../../core/components/Alert'
import { useAppContext } from '../../../core/context/App'

import { Environment } from '../../../services/Customers'
import { auth } from '../../../services/Endpoints'
import { APITokensTable } from '../components/Tabs/APITokensTable'
import { BookingTable } from '../components/Tabs/BookingTable'
import { VaccineDataTable } from '../components/Tabs/VaccineDataTable'
import { ConfigPanel } from '../components/Tabs/ConfigPanel'
import { paneFactory } from '../components/TabPane'

import { ConfigStore } from '../components/Tabs/ConfigStore'
import { EnvironmentDetailsDebugLocationListing } from '../components/Tabs/DebugLocationListing'
import { SimpleEnvironmentsTable } from '../components/Tabs/SimpleEnvironmentsTable'
import { MessageKeysTable } from '../components/Tabs/MessageKeysTable'
import { useCustomer } from './useCustomer'
import { useVersion } from './useVersion'

const tabs = [
  {
    permissions: ['vax:action:read:customers'],
    Component: SimpleEnvironmentsTable,
    name: 'Other Environments'
  },
  {
    permissions: ['vax:action:read:config'],
    Component: ConfigPanel,
    name: 'Config'
  },
  {
    permissions: ['vax:action:read:internal-config'],
    Component: ConfigStore,
    name: 'Config store'
  },
  {
    permissions: ['vax:action:debug:location'],
    Component: EnvironmentDetailsDebugLocationListing,
    name: 'Locations'
  },
  {
    permissions: ['vax:action:read:token'],
    Component: APITokensTable,
    name: 'API Tokens'
  },
  {
    permissions: ['vax:action:read:booking'],
    Component: BookingTable,
    name: 'Booking Details'
  },
  {
    permissions: ['vax:action:read:config'],
    Component: VaccineDataTable,
    name: 'Vaccines'
  },
  {
    permissions: ['vax:action:read:config'],
    Component: MessageKeysTable,
    name: 'Message Keys'
  }
]

export const useEnvironmentDetails = ({ customerEnv, customerCode }: { customerEnv: string; customerCode: string }) => {
  const {
    environmentDetails: { activeIndex },
    dispatch
  } = useAppContext()
  const [redirect, setRedirect] = useState(false)
  const [environment, setEnvironment] = useState<Environment | undefined>(undefined)
  const { data: customer, isLoading: customerLoading } = useCustomer({
    customerCode,
    options: {
      onSuccess: data => {
        const newEnvironment = data.environments[customerEnv]
        if (!newEnvironment) {
          createErrorToast(`Could not find environment ${customerEnv}.`)
          return
        }

        setEnvironment(newEnvironment)
      },
      onError: error => {
        createErrorToast(error)
        setRedirect(true)
      }
    }
  })
  const { data: version, error: versionError, isLoading: versionLoading } = useVersion({
    customerName: customerCode,
    environmentName: customerEnv,
    name: useEnvironmentDetails.name
  })

  const loading = versionLoading || customerLoading

  const getPanes = (): StrictTabProps['panes'] =>
    tabs
      .filter(
        tab =>
          tab.permissions
            .map(permission => auth.getPermissions().includes(permission))
            .reduce((sum, next) => sum && next, true) &&
          (tab.name === 'Other Environments' || version) &&
          (tab.name === 'Other Environments' ? Object.keys(customer?.environments || {}).length > 1 : true)
      )
      .map(tab => ({
        menuItem: tab.name,
        render: paneFactory({ customerCode, customerEnv, loading, customer, environment, tab })
      }))

  const handleTabChange = (e: SyntheticEvent, { activeIndex: newActiveIndex }: TabProps) =>
    dispatch({
      type: Actions.SET_ENVIRONMENT_DETAILS_ACTIVE_INDEX_TAB,
      payload: parseInt((newActiveIndex || '0').toString(), 10)
    })

  return {
    getPanes,
    loading,
    version,
    versionError,
    environment,
    customer,
    redirect,
    handleTabChange,
    activeIndex
  }
}
