import { vaxAdminApi } from './Endpoints'

export interface ReleaseLog {
  id: string
  gitHash: string
  releaseVersion: string
  minimumSalesForce: string
  updatedAt: string
  createdAt: string
}

export interface NewRelease {
  gitHash: string
  releaseVersion: string
  minimumSalesForce: string
}

export const getRelease = () => vaxAdminApi.get<ReleaseLog>('/release/latest').then(response => response.data)

export const createRelease = (release: NewRelease) => vaxAdminApi.post('/release/create', release)

export const deployRelease = (customerKey: string, customerEnv: string, releaseId: string) =>
  vaxAdminApi.post(`/release/deploy/${customerKey}/${customerEnv}/${releaseId}`)
