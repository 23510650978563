import { FC } from 'react'
import { Icon, Popup } from 'semantic-ui-react'
import { getErrorContent } from '../../../../../core/components/Alert'

export interface ScopeErrorPopupProps {
  error?: Error
}

export const ScopeErrorPopup: FC<ScopeErrorPopupProps> = ({ error }) => (
  <Popup
    trigger={
      <div>
        Scopes <Icon name="question circle" />
      </div>
    }
    content={
      <div>
        <p>List of scopes could not be queried. Enter scopes manually separated by a space.</p>
        {error && <p>The error when trying to get scopes was: {getErrorContent(error)}</p>}
      </div>
    }
    basic
  />
)
