import { AxiosError, AxiosResponse } from 'axios'
import * as _ from 'lodash'

import { toast, ToastOptions } from 'react-semantic-toasts'

const handleAxiosErrorResponse = (response: AxiosResponse): string => {
  let errorResponse = ''
  if (response.status === 500) {
    errorResponse = 'Please screenshot and report to Skedulo Vax Tooling: '
  }

  if (response.data) {
    if (response.data.message) {
      errorResponse += response.data.message
    } else if (response.data.error) {
      errorResponse += response.data.error
    } else if (_.isArray(response.data) && response.data.length) {
      if (response.data[0].errorType) {
        errorResponse += `A ${response.data[0].errorType} occurred: `
      } else if (response.data[0].message) {
        errorResponse += response.data[0].message
      }
    } else if (_.isString(response.data)) {
      errorResponse += response.data
    }

    return errorResponse
  } // the response matches none of the expected shapes
  return response.statusText || 'An error response was received but could not be handled.'
}

export const getErrorContent = (error?: Error) => {
  if (!error) {
    return ''
  }
  const axiosError = error as AxiosError
  if (!axiosError.isAxiosError) {
    return error.message
  } else {
    // Console log the error, but show response error first
    // eslint-disable-next-line no-console
    axiosError.config && console.log(axiosError.toJSON())

    if (axiosError.response) {
      return handleAxiosErrorResponse(axiosError.response)
    } else if (axiosError.config) {
      return `Failed Response from server at ${axiosError.config.url}`
    } else {
      return error.message
    }
  }
}

export const createErrorToast = (error: Error | string, options?: Partial<ToastOptions>) => {
  const axiosError = error as AxiosError
  if (axiosError.isAxiosError && axiosError.response?.status === 404) {
    toast({
      title: 'Out of Date',
      description: 'Environment version upgrade required',
      type: 'warning',
      time: 10000,
      ...options
    })
  } else {
    toast({
      title: 'Error',
      description: typeof error === 'string' ? error : getErrorContent(error),
      type: 'error',
      time: 60000,
      ...options
    })
  }
}

export const createSuccessToast = (description: string, options?: Partial<ToastOptions>) => {
  toast({
    title: 'Success:',
    description,
    type: 'success',
    time: 10000,
    ...options
  })
}

export const syncSuccessToast = (description: string, options?: Partial<ToastOptions>) => {
  toast({
    title: 'Success:',
    description,
    type: 'success',
    time: 10000,
    ...options
  })
}
