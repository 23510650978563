import { ComponentClass } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { Redirect, BrowserRouter, Switch, Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import { SemanticToastContainer } from 'react-semantic-toasts'
import { DeploymentDetails } from '../views/Deployments/DeploymentDetails'
import { DeploymentsList } from '../views/Deployments/DeploymentsList'
import { EnvironmentDetails } from '../views/Environments/EnvironmentDetails'
import { Environments } from '../views/Environments/EnvironmentsList'
import { LocationDetails } from '../views/Locations/LocationDetails'
import { AllVersions } from '../views/Environments/components/AllVersions'
import { auth } from '../services/Endpoints'
import { Login } from '../views/Login'
import { ConfigCompare } from '../views/ConfigCompare/ConfigCompare'
import { ConfigStoreCompare } from '../views/ConfigCompare/ConfigStoreCompare'
import { Header } from './components/Header'

const handleAuthentication = (nextState: RouteComponentProps) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication()
  }
}

const AuthRoute = ({ component, ...args }: RouteProps & { component: RouteProps['component'] }) => {
  const Component = component as ComponentClass
  const render = (props: RouteComponentProps) =>
    auth.isAuthenticated() ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />
  // eslint-disable-next-line react/jsx-no-bind
  return <Route {...args} render={render} />
}

const AuthRouteRender = ({ render, ...args }: RouteProps & { render: RouteProps['render'] }) => {
  const outerRender = (props: RouteComponentProps) => {
    if (render && auth.isAuthenticated()) {
      return render(props)
    } else {
      return <Redirect to={{ pathname: '/login' }} />
    }
  }
  // eslint-disable-next-line react/jsx-no-bind
  return <Route {...args} render={outerRender} />
}

const handleCallback = (props: RouteComponentProps) => {
  handleAuthentication(props)
  return (
    <Dimmer active={true} inverted={true}>
      <Loader />
    </Dimmer>
  )
}

export const Router = () => (
  <BrowserRouter>
    <div id="main">
      <SemanticToastContainer className="alert-toast" position="top-right" />
      {auth.isAuthenticated() && <Header />}
      <Switch>
        <Redirect exact={true} from="/" to="/environments" />
        <Route exact={true} path="/login" component={Login} />
        <Route path="/callback" render={handleCallback} />
        <AuthRoute exact={true} path="/deployments" component={DeploymentsList} />
        <AuthRoute exact={true} path="/deployments/:id" component={DeploymentDetails} />
        <AuthRoute exact={true} path="/environments" component={Environments} />
        <AuthRoute exact={true} path="/environments/:customerCode/:customerEnv" component={EnvironmentDetails} />
        <AuthRoute exact={true} path="/environments/allversions" component={AllVersions} />
        <AuthRoute
          exact={true}
          path="/environments/:customerCode/:customerEnv/:customerLocation"
          component={LocationDetails}
        />
        <AuthRoute exact={true} path="/config/diff/:configType" component={ConfigCompare} />
        <AuthRoute exact={true} path="/config-store/diff" component={ConfigStoreCompare} />
      </Switch>
    </div>
  </BrowserRouter>
)
