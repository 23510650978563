import { FC, useState, useEffect } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { vaxAdminApi } from '../../../services/Endpoints'
import { DisplayWithPermission } from '../../../core/components/DisplayWithPermission'

interface S3Payload {
  [key: string]: string
}

interface Props {
  customerKey: string
  customerEnv: string
}

export const S3CapacitySnapshotButton: FC<Props> = ({ customerEnv, customerKey }) => {
  const [urlString, setUrlString] = useState('')

  useEffect(() => {
    const S3_URL = `/proxy/${customerKey}/${customerEnv}/secure/stats/capacity/snapshot/s3/csv`
    vaxAdminApi
      .get<S3Payload>(S3_URL)
      .then(response => {
        const { data } = response
        setUrlString(data.s3Url)
      })
      .catch(error => {
        setUrlString('')
        alert(`Error Loading s3 URL: ${error}`)
      })
  }, [])

  return (
    <DisplayWithPermission permission="vax:action:read:stats">
      <>
        <Button icon labelPosition="right" primary href={urlString} download disabled={urlString === ''}>
          Download Full Availability Snapshot (S3)
          <Icon style={{ background: 'rgba(0, 0, 0, 0)' }} name="sync" />
        </Button>
      </>
    </DisplayWithPermission>
  )
}
