import { useForm, UseFormSetValue } from 'react-hook-form'
import { FC, useEffect } from 'react'
import { CellProps, Column } from 'react-table'
import { Form, Modal } from 'semantic-ui-react'

import { Table } from '../../../../core/components/Table'

import { ConfigStore, ConfigStoreSchema } from '../../../../services/ConfigStore'
import { auth } from '../../../../services/Endpoints'
import { useCreateNewConfigStoreRevision } from '../../hooks/useConfigStore'
import { configStoreTableData, ConfigStoreTableDataType } from '../Tabs/ConfigStore/config-store-utils'

interface NewConfigStoreModalProps {
  customerEnv: string
  customerKey: string
  latestConfig: ConfigStore
  schema?: ConfigStoreSchema
  onOpen: () => void
  onClose: () => void
  isOpen: boolean
}

type EditableCellProps = CellProps<ConfigStoreTableDataType['data'][0]> & {
  updateData: UseFormSetValue<ConfigStore['config']>
}

const EditableCell: FC<EditableCellProps> = ({ row, updateData }) => {
  switch (row.original.type) {
    case 'boolean':
      return (
        <Form.Checkbox
          toggle
          style={{ marginBottom: 14 }}
          name={row.original.key}
          error={row.original.error?.message}
          defaultChecked={row.original.value === 'true'}
          onChange={async (e, { name, checked }) => {
            updateData(name!, checked as boolean)
          }}
        />
      )
    case 'number':
      return (
        <Form.Input
          name={row.original.key}
          type="number"
          defaultValue={row.original.value}
          error={row.original.error?.message}
          onChange={async (e, { name, value }) => {
            updateData(name!, value as string)
          }}
        />
      )
    case 'string':
    case 'array':
    case 'object':
      return (
        <Form.Input
          name={row.original.key}
          type="text"
          defaultValue={row.original.value}
          error={row.original.error?.message}
          onChange={async (e, { name, value }) => {
            updateData(name!, value as string)
          }}
        />
      )
    default:
      return null
  }
}

const EditableConfig: Column[] = [
  {
    Header: <>Key</>,
    accessor: 'key'
  },
  {
    Header: <>Description</>,
    accessor: 'description'
  },
  {
    Header: <>Value</>,
    accessor: 'value',
    Cell: EditableCell
  }
]

const validateConfig = (schema: ConfigStoreSchema, config: ConfigStore['config'], parse = false) => {
  const configPayload = Object.keys(config)
    .filter(key => !!schema?.properties[key])
    .reduce<ConfigStore['config']>((acc, curr) => {
      const type = schema?.properties[curr].type
      const value = config?.[curr] ?? schema?.properties?.[curr].default

      switch (type) {
        case 'object':
        case 'array':
          return {
            ...acc,
            [curr]: parse ? JSON.parse(value as string) : JSON.stringify(value as string)
          }
        case 'number':
          return {
            ...acc,
            [curr]: Number(value as string)
          }
        case 'string':
        case 'boolean':
        default:
          return {
            ...acc,
            [curr]: value
          }
      }
    }, {})

  return configPayload
}

export const NewConfigStoreModal = ({
  customerEnv,
  customerKey,
  latestConfig,
  onOpen,
  onClose,
  isOpen,
  schema
}: NewConfigStoreModalProps) => {
  const { mutateAsync, isLoading: isCreating } = useCreateNewConfigStoreRevision({ customerEnv, customerKey })
  const { handleSubmit, register, setValue, setError, formState, unregister } = useForm<ConfigStore['config']>({
    defaultValues: validateConfig(schema!, latestConfig.config ?? {})
  })

  const { isSubmitting, errors, isDirty } = formState

  useEffect(() => {
    if (!latestConfig?.config) {
      return
    }

    Object.keys(latestConfig.config).forEach(a => {
      register(a)
    })

    return () => {
      Object.keys(latestConfig.config).forEach(a => {
        unregister(a)
      })
    }
  }, [latestConfig])

  const onSub = async (config: ConfigStore['config']) => {
    const user = await auth.getProfile()

    // We only want the fields that are changed to be sent through
    const configPayload = validateConfig(schema!, config, true)

    try {
      await mutateAsync({ config: configPayload, user: user.name })
      onClose()
    } catch (e) {
      if (e?.response?.data) {
        const x = e.response.data as { instancePath: string; message: string }[]

        x.forEach(r => {
          setError(
            r.instancePath.replace('/', ''),
            {
              message: r.message
            },
            { shouldFocus: true }
          )
        })
      }
    }
  }

  const { data: parsedData } = configStoreTableData(
    { config: latestConfig?.config ?? null, schema: schema ?? null, errors },
    [latestConfig, schema, formState]
  )

  const updateData = (name: string, value: string | boolean) => {
    setValue(name, value, { shouldDirty: true })
  }

  return (
    <Modal open={isOpen} onClose={onClose} onOpen={onOpen} closeIcon trigger={null} size="large">
      <Modal.Header>
        Create new config revision for {customerKey} {customerEnv}
      </Modal.Header>
      <Modal.Content scrolling>
        <Form onSubmit={handleSubmit(onSub)}>
          <Table
            textAlign="left"
            columns={EditableConfig}
            emptyMessage="Config didn't load. Try clear the cache and refresh the page"
            data={parsedData}
            tableOptions={{ updateData }}
          />
          <Form.Button
            style={{ marginTop: 25 }}
            type="submit"
            content="Create"
            loading={isCreating || isSubmitting}
            disabled={isCreating || isSubmitting || !isDirty}
            fluid
            color="blue"
          />
          {Object.keys(errors).length >= 1 && (
            <p style={{ color: 'red' }}>There was an error submitting your revision, scroll up to see the errors.</p>
          )}
        </Form>
      </Modal.Content>
    </Modal>
  )
}
