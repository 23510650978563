import { vaxAdminApi } from './Endpoints'

export interface Version {
  gitVersion?: string
  gitHash?: string
  packageVersion?: string
  gitBranch?: string
  build?: string
}

export interface CustomerWithVersion {
  customerEnv: string
  customerKey: string
  data: {
    gitVersion?: string
    gitHash?: string
    packageVersion?: string
    gitBranch?: string
    build?: string
  }
}

export interface CustomersWithVersion {
  CustomerWithVersion: CustomerWithVersion[]
}

export interface ConfigOverrideDates {
  configOverride: {
    createdAt?: string
    expiry?: string
  }
}

export const getVersion = (customerKey: string, customerEnv: string) =>
  vaxAdminApi.get<Version>(`/proxy/${customerKey}/${customerEnv}/public/version`).then(response => response.data)

export const getLastPIWDate = (customerKey: string, customerEnv: string) =>
  vaxAdminApi
    .get<ConfigOverrideDates>(`/proxy/${customerKey}/${customerEnv}/secure/config/override`)
    .then(response => response.data)

export const getAllVersions = () =>
  vaxAdminApi.get<CustomersWithVersion>('/customers/all_versions').then(response => response.data)
