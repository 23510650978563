import { AxiosError } from 'axios'
import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query'
import { Customers, getCustomers } from '../../services/Customers'

interface Props<T> {
  name: string
  options?: UseQueryOptions<T, AxiosError<T>, T, string>
}

type Result<T> = UseQueryResult<T, AxiosError<T>>

export const useCustomers: CustomHook<Props<Customers>, Result<Customers>> = ({ name, options }) =>
  useQuery('customers', () => getCustomers(), {
    ...options,
    cacheTime: 1000 * 60 * 10
  })
