import { subDays } from 'date-fns'
import { FC } from 'react'
import { Column } from 'react-table'
import { createMemoizedColumns, createMemoizedData, Table } from '../../../../../core/components/Table'
import { formatNumber } from '../../../../../core/utils'
import { LocationVaccineConsumptionWithIntervals } from '../DebugLocationTable'
import { RenderInterval } from './RenderInterval'

interface AvailableSupplyProps {
  endDate?: Date
  perInteractionWorkflow: boolean
  dose1Upfront: boolean
}

interface Props {
  loading: boolean
  config?: LocationVaccineConsumptionWithIntervals[]
  errorVaccineListCode?: number
  isLoadingVaccineList: boolean
  locationProperties?: AvailableSupplyProps
}

interface LocationTableData {
  id: string
  brand: string
  dose: number
  stockCountDate: string
  stockCount: string
  appoinments: string
  reservations: string
  remainingSuply: string
  maxInterval: JSX.Element
  minInterval: JSX.Element
  locationProperties?: AvailableSupplyProps
  backgroundColor: string
}

const highlightColor = '#fac08b'

const columns: Column<LocationTableData>[] = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Brand',
    accessor: 'brand'
  },
  {
    Header: 'Dose',
    accessor: 'dose'
  },
  {
    Header: 'Stock Count Date',
    accessor: 'stockCountDate'
  },
  {
    Header: 'Stock Count',
    accessor: 'stockCount'
  },
  {
    Header: 'Appointments',
    accessor: 'appoinments'
  },
  {
    Header: 'Reservations',
    accessor: 'reservations'
  },
  {
    Header: 'Remaining Supply',
    accessor: 'remainingSuply'
  },
  {
    Header: 'Max Interval',
    accessor: 'maxInterval'
  },
  {
    Header: 'Min Interval',
    accessor: 'minInterval'
  }
]

const isDoseBookableByMinInterval = (
  locationProperties?: AvailableSupplyProps,
  doseNumber?: number,
  minInterval?: number
) => {
  if (locationProperties && locationProperties.dose1Upfront && !locationProperties.perInteractionWorkflow) {
    return doseNumber === 1
      ? locationProperties.endDate && new Date() < subDays(locationProperties.endDate, minInterval || 0)
      : locationProperties.endDate && new Date() < locationProperties.endDate
  }
  return true
}

export const LocationTable: FC<Props> = ({
  loading,
  config,
  errorVaccineListCode,
  isLoadingVaccineList,
  locationProperties
}) => {
  const data: LocationTableData[] =
    config?.map(locationVaccine => ({
      id: locationVaccine.vaccine.id,
      brand: locationVaccine.vaccine.brand,
      dose: locationVaccine.vaccine.doseNumber,
      stockCountDate: locationVaccine.supply.asAt,
      stockCount: formatNumber(locationVaccine.supply.count),
      appoinments: formatNumber(locationVaccine.appointments),
      reservations: formatNumber(locationVaccine.reservations),
      remainingSuply: formatNumber(
        locationVaccine.supply.count - locationVaccine.appointments - locationVaccine.reservations
      ),
      maxInterval: (
        <RenderInterval
          errorCode={errorVaccineListCode}
          value={locationVaccine.maxInterval}
          isLoading={isLoadingVaccineList}
        />
      ),
      minInterval: (
        <RenderInterval
          errorCode={errorVaccineListCode}
          value={locationVaccine.minInterval}
          isLoading={isLoadingVaccineList}
        />
      ),
      backgroundColor: isDoseBookableByMinInterval(
        locationProperties,
        locationVaccine.vaccine.doseNumber,
        locationVaccine.minInterval
      )
        ? ''
        : highlightColor
    })) || []

  const showLegend = () => {
    const isHighlighted = (val: LocationTableData) => val.backgroundColor === highlightColor

    const message =
      data.length > 0 && data.every(isHighlighted)
        ? '  All vaccines are unavailable for bookings due to location close date arriving before minimum dose intervals could be observed'
        : data.some(isHighlighted)
        ? '  Unavailable for bookings due to location close date arriving before minimum dose interval could be observed'
        : ''

    return (
      message && (
        <ul className={'legend'}>
          <li>
            <span className={'warning'}></span>
            {message}
          </li>
        </ul>
      )
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <div className="scrollable">
        <Table<LocationTableData>
          className="sticky-table"
          color="blue"
          columns={createMemoizedColumns(columns)}
          data={createMemoizedData(data, [config])}
          loading={loading}
          emptyMessage="No supply found."
          size="small"
          striped
          getRowProps={(row: { original: { backgroundColor: string } }) => ({
            style: {
              background: row.original.backgroundColor
            }
          })}
        />
      </div>
      {showLegend()}
    </div>
  )
}
