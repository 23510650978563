import { FC } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { CSVLink } from 'react-csv'
import { DisplayWithPermission } from '../../../core/components/DisplayWithPermission'
import { useCapacitySnapshot } from '../hooks/useCapacitySnapshot'

interface Props {
  customerKey: string
  customerEnv: string
}

interface CsvHeader {
  label: string
  key: string
}

const csvHeaderRows: CsvHeader[] = [
  { label: 'LocationExtId', key: 'externalId' },
  { label: 'Name', key: 'name' },
  { label: 'Address', key: 'address' },
  { label: 'Date', key: 'availabilityDate' },
  { label: 'Holiday', key: 'holiday' },
  { label: 'Capacity', key: 'capacity' },
  { label: 'Taken', key: 'capacityTaken' },
  { label: 'Remaining', key: 'capacityRemaining' },
  { label: 'Reserved', key: 'reservations' },
  { label: 'Lat', key: 'lat' },
  { label: 'Lng', key: 'lng' }
]

export const CapacitySnapshotButton: FC<Props> = ({ customerEnv, customerKey }) => {
  const { loaded, csvLinkEl, csvSnapshot, downloadCsvFile, filename, error, loading } = useCapacitySnapshot({
    customerEnv,
    customerKey
  })

  return (
    <DisplayWithPermission permission="vax:action:read:stats">
      <>
        <Button
          color="blue"
          icon
          labelPosition="right"
          primary
          onClick={downloadCsvFile}
          disabled={loading || (loaded && error !== null)}
        >
          Download Full Availability Snapshot
          <Icon style={{ background: 'rgba(0, 0, 0, 0)' }} name="sync" loading={loading} />
        </Button>
        <CSVLink headers={csvHeaderRows} filename={filename} data={csvSnapshot || []} ref={csvLinkEl} />
      </>
    </DisplayWithPermission>
  )
}
