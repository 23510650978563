import { AxiosError } from 'axios'
import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { getLocationDebug, LocationAvailabilityQuery, LocationCapacityForVaccine } from '../../services/Config'

interface Props<T> {
  customerKey: string
  customerEnv: string
  customerLocation: string
  locationAvailabilityQuery: LocationAvailabilityQuery
  options?: UseQueryOptions<T, AxiosError<T>, T, QueryKey>
}

type Result<T> = UseQueryResult<T, AxiosError<T>>

export const useLocationDebug: CustomHook<Props<LocationCapacityForVaccine>, Result<LocationCapacityForVaccine>> = ({
  customerKey,
  customerEnv,
  customerLocation,
  locationAvailabilityQuery,
  options
}) =>
  useQuery(
    ['location-debug', { customerKey, customerEnv, customerLocation, locationAvailabilityQuery }],
    () => getLocationDebug(customerKey, customerEnv, customerLocation, locationAvailabilityQuery),
    options
  )
