import { AxiosError } from 'axios'
import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query'
import { Version, getVersion } from '../../../services/Environment'

interface Props<T> {
  customerName: string
  environmentName: string
  name: string
  options?: UseQueryOptions<T, AxiosError<T>, T, string>
}

type Result<T> = UseQueryResult<T, AxiosError<T>>

export const useVersion: CustomHook<Props<Version>, Result<Version>> = ({
  customerName,
  environmentName,
  name,
  options
}) =>
  useQuery(`version-${name}-${customerName}-${environmentName}`, () => getVersion(customerName, environmentName), {
    ...options,
    retry: false,
    cacheTime: 1000 * 60 * 5
  })
