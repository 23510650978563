import { FC, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'
import { Button } from 'semantic-ui-react'
import { DeploymentLog } from '../../../services/Deployments'
import { displaySuccess } from '../../../core/components/DisplaySuccess'
import { createMemoizedColumns, createMemoizedData, Table } from '../../../core/components/Table'
import { RenderIf } from '../../../core/components/RenderIf'
import { DisplaySuccessWithLoader } from './DisplaySuccessWithLoader'

interface Props {
  loading: boolean
  deploymentLogs: DeploymentLog[]
}

interface DeploymentLogTableData {
  deployedAt: string
  customerKey: string
  environment: string
  version: string
  deployedBy: string
  promoted: JSX.Element
  success: JSX.Element
  details: JSX.Element
}

const columns: Column<DeploymentLogTableData>[] = [
  {
    Header: 'Deployed At',
    accessor: 'deployedAt'
  },
  {
    Header: 'Customer Key',
    accessor: 'customerKey'
  },
  {
    Header: 'Environment',
    accessor: 'environment'
  },
  {
    Header: 'Version',
    accessor: 'version'
  },
  {
    Header: 'Deployed By',
    accessor: 'deployedBy'
  },
  {
    Header: 'Promoted',
    accessor: 'promoted'
  },
  {
    Header: 'Success',
    accessor: 'success'
  },
  {
    Header: 'Details',
    accessor: 'details'
  }
]

export const DeploymentLogTable: FC<Props> = ({ loading, deploymentLogs }) => {
  const topTableRef = useRef<HTMLDivElement | null>(null)
  const data: DeploymentLogTableData[] = deploymentLogs?.map(deploymentLog => ({
    deployedAt: deploymentLog.updatedAt.substring(0, 10),
    customerKey: deploymentLog.customerKey,
    environment: deploymentLog.customerEnv,
    version: deploymentLog.version,
    deployedBy: deploymentLog.userId,
    promoted: displaySuccess(deploymentLog.promote),
    success: <DisplaySuccessWithLoader success={deploymentLog.success} loading={!deploymentLog.finished} />,
    details: <Button icon="file alternate outline" as={Link} to={`/deployments/${deploymentLog.id}`} />
  }))

  return (
    <div className="scrollable" ref={topTableRef}>
      <RenderIf condition={!!deploymentLogs}>
        <Table<DeploymentLogTableData>
          className="sticky-table"
          color="blue"
          columns={createMemoizedColumns(columns)}
          data={createMemoizedData(data, [deploymentLogs])}
          loading={loading}
          emptyMessage="No deployments found."
          withPagination
          pageSize={50}
          topTableRef={topTableRef}
        />
      </RenderIf>
    </div>
  )
}
