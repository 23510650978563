import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { CustomersWithVersion, getAllVersions } from '../../../services/Environment'

interface Props<T> {
  options?: UseQueryOptions<T, AxiosError, T, string>
}

type Result<T> = UseQueryResult<T, AxiosError>

export const useAllVersions: CustomHook<Props<CustomersWithVersion>, Result<CustomersWithVersion>> = ({ options }) =>
  useQuery('all_versions', () => getAllVersions(), options)
