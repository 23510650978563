import { Dispatch, useReducer } from 'react'
import { useCombinedReducers } from '../hooks/useCombinedReducers'
import { EnvironmentDetailsAction } from '../actions/EnvironmentDetails'
import {
  environmentDetailsReducer,
  EnvironmentDetailsState,
  defaultEnvironmentDetailsState
} from './EnvironmentDetails'

export interface AppState {
  environmentDetails: EnvironmentDetailsState
  dispatch: AppDispatch
}

export type AppAction = EnvironmentDetailsAction

export type AppDispatch = Dispatch<AppAction>

export const useAppReducer = () =>
  useCombinedReducers<AppState, EnvironmentDetailsAction>({
    environmentDetails: useReducer(environmentDetailsReducer, defaultEnvironmentDetailsState)
  })
