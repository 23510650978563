import { CSSProperties, MutableRefObject, useMemo, useState } from 'react'
import { Column, usePagination, useTable, PluginHook, useSortBy } from 'react-table'
import {
  Table as SemanticTable,
  Segment,
  SemanticCOLORS,
  Pagination,
  PaginationProps,
  StrictTableCellProps
} from 'semantic-ui-react'

import { TableLoaderWrap } from '../Loader'
import { RenderIf } from '../RenderIf'

export type MemoizedColumns<D extends object = object> = Column<D>[]

export type MemoizedData<D extends object = object> = D[]

type CreateMemoizedColumns = <D extends object = object>(columns: Column<D>[], deps?: unknown[]) => MemoizedColumns<D>

type CreateMemoizedData = <D extends object = object>(data: D[], deps?: unknown[]) => MemoizedData<D>

type OnPageChange = (event: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => void
const defaultPropGetter = () => ({})

export interface Props<D extends object = object> {
  columns: Column<D>[]
  data: D[]
  loading?: boolean
  emptyMessage: string
  className?: string
  style?: CSSProperties
  color?: SemanticCOLORS
  withPagination?: boolean
  withSort?: boolean
  pageSize?: number
  topTableRef?: MutableRefObject<HTMLElement | null>
  size?: 'small' | 'large'
  striped?: boolean
  // eslint-disable-next-line
  getRowProps?: any // Need to remove any here. This is needed for row highlighting
  textAlign?: StrictTableCellProps['textAlign']
  tableOptions?: object
}

export const Table = <D extends object>({
  columns,
  data,
  loading,
  emptyMessage,
  className,
  style,
  color,
  withPagination,
  withSort,
  pageSize,
  topTableRef,
  size = 'small',
  striped = true,
  getRowProps = defaultPropGetter,
  textAlign = 'center',
  tableOptions
}: Props<D>) => {
  const plugins: PluginHook<D>[] = [useSortBy]

  if (withPagination) {
    plugins.push(usePagination)
  }

  const [pageIndex, setPageIndex] = useState(0)
  const { getTableProps, headerGroups, rows, prepareRow, page, pageCount, gotoPage } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex, pageSize },
      disableSortBy: !withSort,
      ...tableOptions
    },
    ...plugins
  )

  const onPaginationChange: OnPageChange = (_, { activePage }) => {
    const nextPageIndex = parseInt((activePage || '1').toString(), 10) - 1
    setPageIndex(nextPageIndex)
    gotoPage(nextPageIndex)
    topTableRef?.current?.scrollTo({ left: 0, top: 0 })
  }

  return (
    <>
      <SemanticTable
        {...getTableProps()}
        stackable
        className={className}
        style={style}
        color={color}
        size={size}
        striped={striped}
      >
        <SemanticTable.Header>
          {headerGroups.map(headerGroup => {
            const { key: headerKey, ...headerProps } = headerGroup.getHeaderGroupProps()
            return (
              <SemanticTable.Row {...headerProps} key={headerKey}>
                {headerGroup.headers.map(column => (
                  <SemanticTable.HeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    textAlign={textAlign}
                    key={column.id}
                  >
                    {column.render('Header')}
                    <RenderIf condition={!!withSort}>
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i aria-hidden="true" className="sort down icon" />
                          ) : (
                            <i aria-hidden="true" className="sort up icon" />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </RenderIf>
                  </SemanticTable.HeaderCell>
                ))}
              </SemanticTable.Row>
            )
          })}
        </SemanticTable.Header>
        <TableLoaderWrap array={rows} loading={!!loading} emptyMessage={emptyMessage}>
          <SemanticTable.Body>
            <RenderIf condition={!withPagination}>
              {rows.map(row => {
                prepareRow(row)
                const { key: rowKey, ...rowProps } = row.getRowProps(getRowProps(row))

                return (
                  <SemanticTable.Row {...rowProps} key={rowKey}>
                    {row.cells.map(cell => {
                      const { key: cellKey, ...cellProps } = cell.getCellProps()
                      return (
                        <SemanticTable.Cell key={cellKey} {...cellProps} textAlign={textAlign}>
                          {cell.render('Cell')}
                        </SemanticTable.Cell>
                      )
                    })}
                  </SemanticTable.Row>
                )
              })}
            </RenderIf>
            <RenderIf condition={!!withPagination}>
              {page?.map(row => {
                prepareRow(row)
                const { key: rowKey, ...rowProps } = row.getRowProps(getRowProps(row))

                return (
                  <SemanticTable.Row {...rowProps} key={rowKey}>
                    {row.cells.map(cell => {
                      const { key: cellKey, ...cellProps } = cell.getCellProps()

                      return (
                        <SemanticTable.Cell {...cellProps} textAlign={textAlign} key={cellKey}>
                          {cell.render('Cell')}
                        </SemanticTable.Cell>
                      )
                    })}
                  </SemanticTable.Row>
                )
              })}
            </RenderIf>
          </SemanticTable.Body>
        </TableLoaderWrap>
      </SemanticTable>
      <RenderIf condition={!!withPagination}>
        <Pagination totalPages={pageCount || 0} onPageChange={onPaginationChange} activePage={pageIndex + 1} />
      </RenderIf>
    </>
  )
}

export const createMemoizedColumns: CreateMemoizedColumns = (columns, deps = []) => useMemo(() => columns, [...deps])

export const createMemoizedData: CreateMemoizedData = (data, deps = []) => useMemo(() => data, [...deps])

interface ExampleData {
  firstName: string
  lastName: string
}

export const UseExampleTable = () => {
  const columns = createMemoizedColumns<ExampleData>([
    {
      Header: 'First Name',
      accessor: 'firstName'
    },
    {
      Header: 'Last Name',
      accessor: 'lastName'
    }
  ])

  const data = createMemoizedData<ExampleData>([
    { firstName: 'John', lastName: 'Smith' },
    { firstName: 'Jeff', lastName: 'Killah' },
    { firstName: 'Carl', lastName: 'Gim' }
  ])

  return (
    <Segment color="blue">
      <Table<ExampleData> columns={columns} data={data} emptyMessage="Example not found" />
    </Segment>
  )
}
