import { AxiosError } from 'axios'
import { useQuery, UseQueryResult } from 'react-query'
import { getMessageKeys, MessageKeys } from '../../../services/MessageKeys'

interface Props {
  customerKey: string
  customerEnv: string
}

type Result = UseQueryResult<Record<string, MessageKeys>, AxiosError>

export const useMessageKeys: CustomHook<Props, Result> = ({ customerKey, customerEnv }) =>
  useQuery(`message-keys-${customerEnv}-${customerKey}`, () => getMessageKeys(customerKey, customerEnv), {
    retry: 0,
    cacheTime: 1000 * 60 * 10
  })
