import { FC } from 'react'
import { Table, Icon } from 'semantic-ui-react'
import { TableLoaderWrap } from '../../../../../core/components/Loader'
import { DetailsTabProps } from '../../../../../core/interfaces'
import { useAPITokens } from '../../../hooks/useAPITokens'

type Props = DetailsTabProps

export const APITokensTable: FC<Props> = ({ customerEnv, customerKey, loading: loadingProp }) => {
  const { convertTime, loading, tokens } = useAPITokens({ customerEnv, customerKey })

  return (
    <div className="scrollable">
      <Table striped color="blue" size="small" stackable className="sticky-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Issued At Time</Table.HeaderCell>
            <Table.HeaderCell>Expiry Time</Table.HeaderCell>
            <Table.HeaderCell>JTI</Table.HeaderCell>
            <Table.HeaderCell>Subject</Table.HeaderCell>
            <Table.HeaderCell>Generated By</Table.HeaderCell>
            <Table.HeaderCell>Scopes</Table.HeaderCell>
            <Table.HeaderCell>Active</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <TableLoaderWrap
          loading={loading || loadingProp}
          array={Object.keys(tokens || {})}
          emptyMessage="No tokens found."
        >
          <Table.Body>
            {tokens?.map(token => {
              const { iat, exp, jti, sub, generatedBy, scopes } = token
              const iatDate = convertTime(parseInt(iat, 10))
              const expDate = exp ? convertTime(parseInt(exp, 10)) : undefined
              return (
                <Table.Row key={jti}>
                  <Table.Cell>{iatDate.toUTCString()}</Table.Cell>
                  <Table.Cell>{expDate?.toUTCString() || 'No Expiry'}</Table.Cell>
                  <Table.Cell>{jti}</Table.Cell>
                  <Table.Cell>{sub}</Table.Cell>
                  <Table.Cell>{generatedBy}</Table.Cell>
                  <Table.Cell>
                    {scopes?.map(scope => (
                      <div key={scope}>{scope}</div>
                    ))}
                  </Table.Cell>
                  <Table.Cell>
                    {!expDate || expDate > new Date() ? (
                      <Icon name="check" color="green" />
                    ) : (
                      <Icon name="close" color="red" />
                    )}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </TableLoaderWrap>
      </Table>
    </div>
  )
}
