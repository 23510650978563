import { AxiosError } from 'axios'
import _ from 'lodash'
import { useQuery } from 'react-query'
import { getAllVaccines, VaccineDetails, Vaccines } from '../../services/Vaccines'
import { useLocationExtendedDebug } from './useLocationExtendedDebug'

interface Props {
  customerKey: string
  customerEnv: string
  locationId: string
  name: string
}

interface Result {
  loadingVax: boolean
  vaccineList: {
    key: number
    text: string
    value: string
  }[]
  vaxInfo: VaccineDetails[]
}

export const useVaccineList: CustomHook<Props, Result> = ({ customerKey, customerEnv, name, locationId }) => {
  const { data: vaxList, isLoading: isLoadingVaxList } = useQuery<Vaccines, AxiosError, Vaccines, string>(
    `all-vaccines-${name}-${customerEnv}-${customerKey}`,
    () => getAllVaccines(customerKey, customerEnv)
  )

  const { data: config, isLoading: isLoadingConfig } = useLocationExtendedDebug({
    customerKey,
    customerEnv,
    locationId
  })

  const vaxLocationSupplyList = vaxList?.vaccines.filter(vax =>
    JSON.stringify(config?.consumption)?.includes(vax.externalId)
  )

  const vaccineList = _.map(vaxLocationSupplyList, (vax, index) => ({
    key: index,
    text: `${vax.detail.brand}`,
    value: vax.externalId
  }))

  return {
    loadingVax: isLoadingConfig || isLoadingVaxList,
    vaccineList,
    vaxInfo: vaxList?.vaccines || []
  }
}
