import { AxiosError } from 'axios'
import { ChangeEventHandler, SyntheticEvent, useEffect } from 'react'
import { CheckboxProps } from 'semantic-ui-react'
import { createErrorToast } from '../../../core/components/Alert'
import { Config, LocationExtendedDebug } from '../../../services/Config'
import { Actions } from '../actions/DebugLocationTable'
import { useLocationDetailsContext } from '../context/LocationDetails'
import { useLocationExtendedDebug } from '../../../core/hooks/useLocationExtendedDebug'

interface Props {
  customerKey: string
  customerEnv: string
  name: string
  locationId?: string
}

interface Result {
  error: AxiosError<Config> | null
  isLoading: boolean
  config?: LocationExtendedDebug
  showRawData: boolean
  locationId: string
  onRawCheckboxChanged: (event: SyntheticEvent<HTMLElement>, data: CheckboxProps) => void
  fetchLocations: () => void
  onChangeLocationId: ChangeEventHandler<HTMLInputElement>
}

export const useDebugLocationTable: CustomHook<Props, Result> = ({
  customerKey,
  customerEnv,
  locationId: locationIdProp
}) => {
  const { dispatch, debugLocationTable: state } = useLocationDetailsContext()
  const { locationId, showRawData } = state

  const { data: config, error, isLoading, refetch } = useLocationExtendedDebug({
    customerKey,
    customerEnv,
    locationId
  })

  useEffect(() => {
    if (error && locationId) {
      createErrorToast(`${error.message}`)
    }
  }, [error])

  useEffect(() => {
    if (locationIdProp) {
      dispatch({
        type: Actions.SET_DEBUG_LOCATION_TABLE_LOCATION_ID,
        payload: locationIdProp
      })
    }
  }, [])

  const onRawCheckboxChanged = (event: SyntheticEvent<HTMLElement>, data: CheckboxProps) => {
    dispatch({
      type: Actions.SET_DEBUG_LOCATION_TABLE_SHOW_RAW_DATA,
      payload: !!data.checked
    })
  }

  const fetchLocations = () => {
    refetch()
  }

  const onChangeLocationId: ChangeEventHandler<HTMLInputElement> = e => {
    dispatch({
      type: Actions.SET_DEBUG_LOCATION_TABLE_LOCATION_ID,
      payload: e.target.value
    })
  }

  return { error, isLoading, config, showRawData, onRawCheckboxChanged, fetchLocations, locationId, onChangeLocationId }
}
