import { AxiosError } from 'axios'
import { UseQueryResult, useQuery } from 'react-query'
import {
  LocationType,
  ParamsLocation,
  LocationResult,
  getLocations,
  getAllLocations
} from '../../../services/Locations'

interface Props {
  customerKey: string
  customerEnv: string
  params: ParamsLocation
  deps: boolean
}
interface AllLocationProps {
  customerKey: string
  customerEnv: string
  deps: boolean
}

type Result = UseQueryResult<LocationResult, AxiosError<LocationType[]>>
type AllLocationsResult = UseQueryResult<LocationType[], AxiosError<LocationType[]>>

export const useLocations: CustomHook<Props, Result> = ({ customerKey, customerEnv, params, deps }) =>
  useQuery(
    `locations-list-${customerEnv}-${customerKey}-${params.page}-${params.search}-${params.sortBy}-${params.order}-${deps}`,
    () => getLocations(customerKey, customerEnv, params),
    { staleTime: 5000 * 6, cacheTime: 3000 * 2 * 10, enabled: !!deps }
  )

export const useAllLocations: CustomHook<AllLocationProps, AllLocationsResult> = ({ customerKey, customerEnv, deps }) =>
  useQuery(`locations-list-${customerEnv}-${customerKey}-${deps}`, () => getAllLocations(customerKey, customerEnv), {
    staleTime: 5000 * 6,
    cacheTime: 3000 * 2 * 10,
    enabled: !!deps
  })
