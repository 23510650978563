import axios from 'axios'
import { Auth } from '../auth/Auth'
import { Env } from '../core/env/Env'

export const auth = new Auth()

export const vaxAdminApi = axios.create({
  baseURL: Env.API,
  timeout: 60000,
  headers: {
    Authorization: 'Bearer ' + auth.getAccessToken()
  }
})

export const vaxAdminApiLongRunning = axios.create({
  baseURL: Env.API,
  timeout: 900000,
  headers: {
    Authorization: 'Bearer ' + auth.getAccessToken()
  }
})
