import _ from 'lodash'
import { SyntheticEvent, useEffect } from 'react'
import { format } from 'date-fns'
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types'
import { CheckboxProps } from 'semantic-ui-react'
import { LocationCapacityForVaccine } from '../../../services/Config'
import { Actions } from '../../../core/actions/DebugLocationWithVaccine'
import { DebugLocationWithVaccineState } from '../../../core/reducers/DebugLocationWithVaccine'
import { VaccineDetails } from '../../../services/Vaccines'
import { useLocationDetailsContext } from '../context/LocationDetails'
import { useVaccineList } from '../../../core/hooks/useVaccineList'
import { useLocationDebug } from '../../../core/hooks/useLocationDebug'

interface Props {
  customerKey: string
  customerEnv: string
  locationId: string
}

interface Result extends DebugLocationWithVaccineState {
  onChangeDate: (_event: SyntheticEvent | undefined, data: SemanticDatepickerProps) => void
  onRawCheckboxChanged: (event: SyntheticEvent<HTMLElement>, data: CheckboxProps) => void
  config?: LocationCapacityForVaccine
  vaxInfo: VaccineDetails[]
  loading: boolean
  vaccineList: { key: number; text: string; value: string }[]
  doseOptions?: { key: number; text: number; value: number }[]
}

export const useDebugLocationWithVaccine: CustomHook<Props, Result> = ({ customerKey, customerEnv, locationId }) => {
  const { dispatch, debugLocationWithVaccine: state } = useLocationDetailsContext()
  const { doseNumber, endDate, startDate, vaccineBase64, groupSize } = state

  const { vaccineList, vaxInfo, loadingVax } = useVaccineList({
    name: useDebugLocationWithVaccine.name,
    customerEnv,
    locationId,
    customerKey
  })

  useEffect(() => {
    const hasLocations = !!vaccineList?.length

    if (hasLocations && !vaccineBase64) {
      const [firstVaccine] = vaccineList

      onChangeVaxData(firstVaccine.value)
      onChangeDoseNumber(1)
    }
  }, [vaccineList])

  const { data: config, refetch: reFetchLocations, isLoading: loadingLocation } = useLocationDebug({
    customerEnv,
    customerKey,
    customerLocation: locationId,
    locationAvailabilityQuery: {
      vaccineData: vaccineBase64 || '',
      startDate,
      endDate,
      doseNumber: parseInt(doseNumber.toString(), 10),
      groupSize
    },
    options: {
      enabled: !!vaccineBase64
    }
  })

  const setVaccine = (vaxId: string) => {
    dispatch({
      type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_DATA,
      payload: vaxId
    })
    try {
      const decodedVal = atob(vaxId)
      if (decodedVal.startsWith('["')) {
        dispatch({
          type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_BASE_64,
          payload: vaxId.trim()
        })
      } else {
        dispatch({
          type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_BASE_64,
          payload: btoa('["' + vaxId.trim() + '"]')
        })
      }
    } catch {
      dispatch({
        type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_BASE_64,
        payload: ''
      })
    }
  }

  const onChangeVaxData = async (vaxExtId: string) => {
    const selectedVax = vaxInfo.find(vax => vax.externalId === vaxExtId)

    // set the dose options
    const doseList = Array.from(Array(selectedVax?.detail.numberOfDose).keys())
    const dopt = doseList.map(dose => ({
      key: dose + 1,
      text: dose + 1,
      value: dose + 1
    }))

    dispatch({
      type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_DOSE_OPTIONS,
      payload: dopt
    })

    setVaccine(vaxExtId)
  }

  const onChangeDoseNumber = (dose: number) => {
    dispatch({
      type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_DOSE_NUMBER,
      payload: dose
    })
  }

  const onRawCheckboxChanged = (_event: SyntheticEvent<HTMLElement>, data: CheckboxProps) => {
    dispatch({
      type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_SHOW_RAW_DATA,
      payload: !!data.checked
    })
  }

  const onChangeDate = (_event: SyntheticEvent | undefined, data: SemanticDatepickerProps) => {
    if (data.value) {
      dispatch({
        type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_START_DATE,
        payload: data.value && !_.isArray(data.value) ? format(data.value, 'yyyy-MM-dd') : ''
      })
      dispatch({
        type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_END_DATE,
        payload: data.value && !_.isArray(data.value) ? format(data.value, 'yyyy-MM-dd') : ''
      })

      dispatch({
        type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_DATE_PICKER,
        payload: data.value
      })
    }
    getLocations()
  }

  const getLocations = async () => {
    await reFetchLocations()
  }

  return {
    ...state,
    vaxInfo,
    loading: loadingVax || loadingLocation,
    config,
    vaccineList,
    onChangeDate,
    onRawCheckboxChanged
  }
}
