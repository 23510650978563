// eslint-disable-next-line no-shadow
export enum Actions {
  SET_ENVIRONMENT_DETAILS_ACTIVE_INDEX_TAB = 'SET_INPUT'
}

interface SetEnvironmentDetailsActiveIndexTab {
  type: Actions.SET_ENVIRONMENT_DETAILS_ACTIVE_INDEX_TAB
  payload: number
}

export type EnvironmentDetailsAction = SetEnvironmentDetailsActiveIndexTab
