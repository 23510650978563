import React, { useState } from 'react'
import { Button, Header } from 'semantic-ui-react'
import { Column } from 'react-table'
import { format, formatDistance } from 'date-fns'

import { Link } from 'react-router-dom'
import { useGetConfigStoreSchema, useLatestConfigStore } from '../../../hooks/useConfigStore'
import { Table } from '../../../../../core/components/Table'
import { NewConfigStoreModal } from '../../NewConfigStoreModal/NewConfigStoreModal'
import { useEnvironmentVersion } from '../../../hooks/useEnvironmentVersion'
import { CustomerInfo } from '../../../../../services/Customers'
import { RenderIf } from '../../../../../core/components/RenderIf'
import { configStoreTableData, hasConfigStore, MIN_AWS_APP_VERSION } from './config-store-utils'
interface ConfigStoreProps {
  customerKey: string
  customerEnv: string
  customer?: CustomerInfo
}

type ConfigStoreSchemaTableProps = ConfigStoreProps

const latestConfig: Column[] = [
  {
    Header: <>Key</>,
    accessor: 'key'
  },
  {
    Header: <>Description</>,
    accessor: 'description'
  },
  {
    Header: <>Value</>,
    accessor: 'value'
  },
  {
    Header: <>Default</>,
    accessor: 'default'
  }
]

const LatestConfig = ({ customerEnv, customerKey, customer }: ConfigStoreSchemaTableProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const { data: schema, status, isLoading: schemaLoading } = useGetConfigStoreSchema({
    customerKey,
    customerEnv
  })

  const { data, isLoading } = useLatestConfigStore({
    customerEnv,
    customerKey,
    options: {
      enabled: status === 'success'
    }
  })

  const { data: parsedData } = configStoreTableData({ config: data?.config ?? null, schema: schema ?? null }, [
    data,
    schema
  ])

  if (isLoading || schemaLoading) {
    return <p>Loading {schemaLoading ? 'schema' : 'latest config'}...</p>
  }

  const createdByText = (() => {
    const timeSinceNow = data?.changedAt
      ? formatDistance(new Date(data?.changedAt ?? ''), new Date(), { addSuffix: true })
      : 'No date'

    const dateCreated = data?.changedAt ? format(new Date(data?.changedAt ?? ''), 'h:mma dd/MM/yyyy') : 'No date'

    return `${timeSinceNow} (${dateCreated})`
  })()

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 30
        }}
      >
        <div style={{ marginBottom: 10 }}>
          <Header style={{ marginBottom: 4 }} as="h2">
            Latest
          </Header>
          Created by {data?.changedByUser} {createdByText}
        </div>
        <div>
          <Button color="blue" content="Create new revision" onClick={() => setIsOpen(true)} />
          <RenderIf condition={customer ? Object.keys(customer.environments).length > 1 : false}>
            <Button
              color="blue"
              content="Compare Revisions"
              as={Link}
              to={`/config-store/diff?env=${customerEnv}&customer=${customerKey}&revision1=1&revision2=1`}
            />
          </RenderIf>
        </div>
        {isOpen && (
          <NewConfigStoreModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
            latestConfig={data!}
            schema={schema!}
            customerEnv={customerEnv}
            customerKey={customerKey}
          />
        )}
      </div>
      <Table
        textAlign="left"
        columns={latestConfig}
        data={parsedData}
        emptyMessage="No latest config. Try clear the cache and refresh the page."
      />
    </div>
  )
}

export const ConfigStore: React.FC<ConfigStoreProps> = ({ customerKey, customerEnv, customer }) => {
  const { version, isLoading } = useEnvironmentVersion(customerEnv, customerKey)

  if (isLoading) {
    return <p>Loading environment...</p>
  }

  if (!hasConfigStore({ rawVersion: version.rawVersion, customerKey })) {
    return <p>This feature requires a minimum Booking Grid version of {MIN_AWS_APP_VERSION}.</p>
  }

  return <LatestConfig customerEnv={customerEnv} customerKey={customerKey} customer={customer} />
}
