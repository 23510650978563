import { PureComponent } from 'react'
import { Icon, Button, Popup } from 'semantic-ui-react'
import { runForceSync } from '../../../services/Sync'
import { getEnvConfig } from '../../../services/Config'
import { createErrorToast } from '../../../core/components/Alert'

interface State {
  loading: boolean
  disabled: boolean
  updatedAt: string
}

interface Props {
  customerKey: string
  customerEnv: string
}

export class ForceSyncButton extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      disabled: false,
      updatedAt: ''
    }
  }

  componentDidMount = () => {
    this.getLastUpdatedTime()
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.customerEnv !== this.props.customerEnv) {
      this.getLastUpdatedTime()
    }
  }

  getLastUpdatedTime = async () => {
    try {
      const { customerKey, customerEnv } = this.props
      const { updatedAt } = await getEnvConfig(customerKey, customerEnv)
      if (updatedAt && typeof updatedAt === 'string') {
        this.setState({ updatedAt })
      }
    } catch {
      this.setState({ updatedAt: '' })
    }
  }

  runForceSync = async () => {
    this.setState({ loading: true, disabled: true })
    const { customerKey, customerEnv } = this.props
    try {
      await runForceSync(customerKey, customerEnv)
      setTimeout(() => this.setState({ disabled: false }), 30000)
    } catch (error) {
      this.setState({ disabled: false })
      createErrorToast(error)
    }
    this.setState({ loading: false })
  }

  calculateLastUpdatedMinutes = () => {
    const { updatedAt } = this.state
    if (updatedAt && typeof updatedAt === 'string') {
      return Math.round((new Date().getTime() - new Date(updatedAt).getTime()) / 60000)
    }
  }

  render() {
    const lastUpdatedTime = this.calculateLastUpdatedMinutes()
    return (
      <Popup
        trigger={
          <Button
            icon
            labelPosition="right"
            primary
            onClick={this.runForceSync}
            disabled={this.state.disabled}
            color="blue"
          >
            Run manual sync
            <Icon style={{ background: 'rgba(0, 0, 0, 0)' }} name="sync" loading={this.state.loading} />
          </Button>
        }
      >
        {`Read-sync from Salesforce. ${
          lastUpdatedTime || lastUpdatedTime === 0 ? `Last updated ${lastUpdatedTime} minutes ago` : ''
        }`}
      </Popup>
    )
  }
}
