import { createContext, useContext, ReactElement } from 'react'
import { useEnviromentsDetailsReducer, EnviromentsDetailsState } from '../reducers'

export const EnviromentDetailContext = createContext<EnviromentsDetailsState | undefined>(undefined)

export const EnviromentsDetailsProvider = ({
  children,
  ...rest
}: {
  [x: string]: unknown
  children: ReactElement | ReactElement[]
}) => {
  const [state, dispatch] = useEnviromentsDetailsReducer()

  return (
    <EnviromentDetailContext.Provider
      value={{
        ...state,
        dispatch
      }}
      {...rest}
    >
      {children}
    </EnviromentDetailContext.Provider>
  )
}

// custom hook to create App context
export const useEnviromentsDetailsContext = () => {
  const context = useContext(EnviromentDetailContext)
  if (!context) {
    throw new Error('App components cannot be rendered outside the EnviromentProvider components')
  }
  return context
}
