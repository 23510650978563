import { createContext, useContext, ReactElement } from 'react'
import { useLocationDetailsReducer, LocationDetailsState } from '../reducers/LocationDetails'

export const LocationDetailsContext = createContext<LocationDetailsState | undefined>(undefined)

export const LocationDetailsProvider = ({
  children,
  ...rest
}: {
  [x: string]: unknown
  children: ReactElement | ReactElement[]
}) => {
  const [state, dispatch] = useLocationDetailsReducer()

  return (
    <LocationDetailsContext.Provider
      value={{
        ...state,
        dispatch
      }}
      {...rest}
    >
      {children}
    </LocationDetailsContext.Provider>
  )
}

// custom hook to create App context
export const useLocationDetailsContext = () => {
  const context = useContext(LocationDetailsContext)
  if (!context) {
    throw new Error('App components cannot be rendered outside the LocationDetailsProvider components')
  }
  return context
}
