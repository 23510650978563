import { Config, LocationAvailability } from '../../services/Config'
import { VaccineDetails } from '../../services/Vaccines'

// eslint-disable-next-line no-shadow
export enum Actions {
  SET_DEBUG_LOCATION_WITH_VACCINE_LOADING = 'SET_LOADING',
  SET_DEBUG_LOCATION_WITH_VACCINE_LOADING_VAX = 'SET_DEBUG_LOCATION_WITH_VACCINE_LOADING_VAX',
  SET_DEBUG_LOCATION_WITH_VACCINE_CONFIG = 'SET_DEBUG_LOCATION_WITH_VACCINE_CONFIG',
  SET_DEBUG_LOCATION_WITH_VACCINE_LOCATION_ID = 'SET_DEBUG_LOCATION_WITH_VACCINE_LOCATION_ID',
  SET_DEBUG_LOCATION_WITH_VACCINE_DATA = 'SET_DEBUG_LOCATION_WITH_VACCINE_DATA',
  SET_DEBUG_LOCATION_WITH_VACCINE_BASE_64 = 'SET_DEBUG_LOCATION_WITH_VACCINE_BASE_64',
  SET_DEBUG_LOCATION_WITH_VACCINE_START_DATE = 'SET_DEBUG_LOCATION_WITH_VACCINE_START_DATE',
  SET_DEBUG_LOCATION_WITH_VACCINE_END_DATE = 'SET_DEBUG_LOCATION_WITH_VACCINE_END_DATE',
  SET_DEBUG_LOCATION_WITH_VACCINE_DATE_PICKER = 'SET_DEBUG_LOCATION_WITH_VACCINE_DATE_PICKER',
  SET_DEBUG_LOCATION_WITH_VACCINE_DOSE_NUMBER = 'SET_DEBUG_LOCATION_WITH_VACCINE_DOSE_NUMBER',
  SET_DEBUG_LOCATION_WITH_VACCINE_INPUT_TYPE = 'SET_DEBUG_LOCATION_WITH_VACCINE_INPUT_TYPE',
  SET_DEBUG_LOCATION_WITH_VACCINE_LIST = 'SET_DEBUG_LOCATION_WITH_VACCINE_VACCINE_LIST',
  SET_DEBUG_LOCATION_WITH_VACCINE_SHOW_RAW_DATA = 'SET_DEBUG_LOCATION_WITH_VACCINE_SHOW_RAW_DATA',
  SET_DEBUG_LOCATION_WITH_VACCINE_SHOW_SLOTS_MODAL = 'SET_DEBUG_LOCATION_WITH_VACCINE_SHOW_SLOTS_MODAL',
  SET_DEBUG_LOCATION_WITH_VACCINE_SLOTS_WITH_AVAIL = 'SET_DEBUG_LOCATION_WITH_VACCINE_SLOTS_WITH_AVAIL',
  SET_DEBUG_LOCATION_WITH_VACCINE_TOTAL_CAPACITY = 'SET_DEBUG_LOCATION_WITH_VACCINE_TOTAL_CAPACITY',
  SET_DEBUG_LOCATION_WITH_VACCINE_TOTAL_REMAINING = 'SET_DEBUG_LOCATION_WITH_VACCINE_TOTAL_REMAINING',
  SET_DEBUG_LOCATION_WITH_VACCINE_DOSE_OPTIONS = 'SET_DEBUG_LOCATION_WITH_VACCINE_DOSE_OPTIONS',
  SET_DEBUG_LOCATION_WITH_VACCINE_VAX_INFO = 'SET_DEBUG_LOCATION_WITH_VACCINE_VAX_INFO'
}

interface SetDebugLocationWithVaccineVaxInfo {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_VAX_INFO
  payload: VaccineDetails[]
}

interface SetDebugLocationWithVaccineDoseOptions {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_DOSE_OPTIONS
  payload?: { key: number; text: number; value: number }[]
}

interface SetDebugLocationWithVaccineTotalRemaining {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_TOTAL_REMAINING
  payload: string
}

interface SetDebugLocationWithVaccineTotalCapacity {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_TOTAL_CAPACITY
  payload: string
}

interface SetDebugLocationWithVaccineLoading {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_LOADING
  payload: boolean
}

interface SetDebugLocationWithVaccineLoadingVax {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_LOADING_VAX
  payload: boolean
}

interface SetDebugLocationWithVaccineConfig {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_CONFIG
  payload?: Config
}

interface SetDebugLocationWithVaccineLocationId {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_LOCATION_ID
  payload: string
}

interface SetDebugLocationWithVaccineVaccineData {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_DATA
  payload: string
}

interface SetDebugLocationWithVaccineVaccineBase64 {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_BASE_64
  payload: string
}

interface SetDebugLocationWithVaccineStartDate {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_START_DATE
  payload: string
}

interface SetDebugLocationWithVaccineEndDate {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_END_DATE
  payload: string
}

interface SetDebugLocationWithVaccineDatePicker {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_DATE_PICKER
  payload: Date | Date[]
}

interface SetDebugLocationWithVaccineDoseNumber {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_DOSE_NUMBER
  payload: number
}

interface SetDebugLocationWithVaccineInputType {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_INPUT_TYPE
  payload: string
}

interface SetDebugLocationWithVaccineVaccineList {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_LIST
  payload: { key: number; text: string; value: string }
}

interface SetDebugLocationWithVaccineVaccineAShowRawData {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_SHOW_RAW_DATA
  payload: boolean
}

interface SetDebugLocationWithVaccineVaccineShowSlotsModal {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_SHOW_SLOTS_MODAL
  payload: boolean
}

interface SetDebugLocationWithVaccineVaccineSlotsWithAvail {
  type: Actions.SET_DEBUG_LOCATION_WITH_VACCINE_SLOTS_WITH_AVAIL
  payload?: LocationAvailability[]
}

export type DebugLocationWithVaccineAction =
  | SetDebugLocationWithVaccineLoading
  | SetDebugLocationWithVaccineLoadingVax
  | SetDebugLocationWithVaccineConfig
  | SetDebugLocationWithVaccineLocationId
  | SetDebugLocationWithVaccineVaccineData
  | SetDebugLocationWithVaccineVaccineBase64
  | SetDebugLocationWithVaccineStartDate
  | SetDebugLocationWithVaccineEndDate
  | SetDebugLocationWithVaccineDatePicker
  | SetDebugLocationWithVaccineDoseNumber
  | SetDebugLocationWithVaccineInputType
  | SetDebugLocationWithVaccineVaccineList
  | SetDebugLocationWithVaccineVaccineAShowRawData
  | SetDebugLocationWithVaccineVaccineShowSlotsModal
  | SetDebugLocationWithVaccineVaccineSlotsWithAvail
  | SetDebugLocationWithVaccineTotalCapacity
  | SetDebugLocationWithVaccineTotalRemaining
  | SetDebugLocationWithVaccineDoseOptions
  | SetDebugLocationWithVaccineVaxInfo
