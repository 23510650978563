import { vaxAdminApi } from './Endpoints'

export interface Customers {
  [code: string]: CustomerInfo
}

export interface CustomerInfo {
  accountCode: string
  awsAccountId: string
  environments: CustomerEnvironments
  friendlyName: string
  region: string
}

export interface CustomerEnvironments {
  [envName: string]: Environment
}

export interface Environment {
  api: string
  promoteOnly: boolean
  static: string
}

export const getCustomers = () => vaxAdminApi.get<Customers>('/customers').then(result => result.data)

export const getCustomer = (customerName: string) =>
  vaxAdminApi.get<CustomerInfo>(`/customers/${customerName}`).then(result => result.data)
