import type { Dispatch } from 'react'

type valueof<S> = S[keyof S]

/* eslint-disable @typescript-eslint/no-explicit-any */

export const useCombinedReducers = <S extends object, A>(
  combinedReducers: Omit<Record<keyof S, [valueof<S>, Dispatch<any>]>, 'dispatch'>
): [S, Dispatch<A>] => {
  const initialState: S = Object.create({})

  // Global State
  const state = Object.entries<[valueof<S>, Dispatch<any>]>(combinedReducers).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value[0] }),
    initialState
  )

  // Global Dispatch Function
  const dispatch: Dispatch<A> = action =>
    Object.values<[valueof<S>, Dispatch<any>]>(combinedReducers)
      .map(value => value[1])
      .forEach(d => d(action))

  return [state, dispatch]
}

/* eslint-enable @typescript-eslint/no-explicit-any */
