import { AxiosError } from 'axios'
import { useQuery, UseQueryResult } from 'react-query'
import { Config } from '../../../services/Config'

type GetConfigMethod =
  | ((customerKey: string, customerEnv: string, locationId: string) => Promise<Config>)
  | ((customerKey: string, customerEnv: string) => Promise<Config>)

interface Props {
  getConfigMethod: GetConfigMethod
  customerKey: string
  customerEnv: string
  locationId?: string
  name: string
}

type Result = UseQueryResult<Config, AxiosError<Config>>

type GetConfig = ({ getConfigMethod, customerKey, customerEnv }: Omit<Props, 'name'>) => () => Promise<Config>

const getConfig: GetConfig = ({ getConfigMethod, customerKey, customerEnv, locationId }) => async () =>
  locationId
    ? getConfigMethod(customerKey, customerEnv, locationId)
    : getConfigMethod(customerKey, customerEnv, locationId || '')

export const useFetchConfig: CustomHook<Props, Result> = ({
  getConfigMethod,
  customerKey,
  customerEnv,
  name,
  locationId
}) =>
  useQuery(
    `config-${name}-${customerEnv}-${customerKey}`,
    getConfig({ getConfigMethod, customerEnv, customerKey, locationId })
  )
