import { Table, Checkbox } from 'semantic-ui-react'
import Inspector, { chromeLight } from 'react-inspector'
import { TableLoaderWrap } from '../../../core/components/Loader'
import { ConfigValueType } from '../../../services/Config'
import { IConfigComparison } from '../ConfigCompare'
import { ConfigCompareModal, getType } from './ConfigCompareModal'
import { ConfigFieldCompareModal } from './ConfigFieldCompareModal'

interface IProps {
  configList: IConfigComparison[]
  loading: boolean
  showAll: boolean
  environment1: string
  environment2: string
}

export const ConfigCompareList = (props: IProps) => {
  const { showAll, loading, environment1, environment2, configList } = props

  const isDifferent = (config: IConfigComparison) => {
    const { environment1Value, environment2Value } = config
    if (typeof environment1Value == 'object') {
      return JSON.stringify(environment1Value) !== JSON.stringify(environment2Value)
    }
    return environment1Value !== environment2Value
  }

  const renderValue = (value: ConfigValueType, type: string) => {
    if (type === 'string' || type === 'number') {
      return value
    }
    if (type === 'boolean') {
      return <Checkbox checked={value as boolean} />
    }
    if (type === 'json') {
      const expansionLevel = Array.isArray(value) ? 0 : 1
      return (
        <Inspector
          theme={{ ...chromeLight, ...{ BASE_BACKGROUND_COLOR: 'none' } }}
          data={value}
          expandLevel={expansionLevel}
        />
      )
    }
    return 'undefined'
  }

  const renderCompareView = (config: IConfigComparison) => {
    const { environment1Value, environment2Value, name } = config
    return config.name === 'i18n' ? (
      <ConfigFieldCompareModal config={config} leftTitle={environment1} rightTitle={environment2} />
    ) : (
      <ConfigCompareModal
        leftValue={environment1Value}
        leftTitle={environment1}
        rightValue={environment2Value}
        rightTitle={environment2}
        name={name}
      />
    )
  }

  const displayConfig = configList.filter(config => showAll || isDifferent(config))
  return (
    <div className="scrollable">
      <Table striped color="blue" stackable style={{ width: '100%' }} sortable className="sticky-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Config</Table.HeaderCell>
            <Table.HeaderCell>{environment1}</Table.HeaderCell>
            <Table.HeaderCell>Compare</Table.HeaderCell>
            <Table.HeaderCell>{environment2}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <TableLoaderWrap loading={loading} array={displayConfig} emptyMessage="No Config">
          <Table.Body>
            {displayConfig.map(row => (
              <Table.Row key={row.name} error={isDifferent(row)}>
                <Table.Cell>{row.name}</Table.Cell>
                <Table.Cell className="table-cell-overflow" style={{ padding: '20px' }}>
                  {renderValue(row.environment1Value, getType(row.environment1Value))}
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {row.environment1Value && isDifferent(row) && renderCompareView(row)}
                </Table.Cell>
                <Table.Cell className="table-cell-overflow">
                  {renderValue(row.environment2Value, getType(row.environment2Value))}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </TableLoaderWrap>
      </Table>
    </div>
  )
}
