import { Button, Grid, Icon, Modal } from 'semantic-ui-react'
import { NewRelease } from '../../../services/Release'
import { ReleaseInfo } from './ReleaseDataTable'

interface Props {
  newRelease: NewRelease
  open: boolean
  setOpen: (value: boolean) => void
  submitForm: () => void
}

export const CreateReleaseConfirmationModal = (props: Props) => {
  const { newRelease, open, setOpen, submitForm } = props
  const { releaseVersion, gitHash, minimumSalesForce } = newRelease
  const closeConfirmationBox = () => {
    setOpen(false)
  }
  const getReleaseData = () => [
    {
      name: 'Release Version',
      value: releaseVersion
    },
    {
      name: 'Git Hash',
      value: gitHash
    },
    {
      name: 'Minimum SF Pkg Version',
      value: minimumSalesForce
    }
  ]
  return (
    <Modal size="small" open={open} confirmButton="Yes">
      <Modal.Header>
        <Icon name="exclamation triangle" color="yellow" />
        &ensp; Confirm Release Creation
      </Modal.Header>
      <Modal.Content style={{ fontSize: '15px' }}>
        You are about to create a new release with the following information: <br />
        <ReleaseInfo data={getReleaseData()} />
        <b>Note: Releases cannot be changed</b>
      </Modal.Content>
      <Modal.Actions>
        <Grid columns={3} verticalAlign="middle">
          <Grid.Column width={10} textAlign="left" style={{ fontSize: '15px' }}>
            Are you sure you want to do this?
          </Grid.Column>
          <Grid.Column width={3}>
            <Button
              onClick={() => {
                submitForm()
                closeConfirmationBox()
              }}
              content="Yes"
              fluid
              color="blue"
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Button onClick={closeConfirmationBox} content="Cancel" fluid />
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    </Modal>
  )
}
