import { FC } from 'react'
import { Segment, Checkbox, Container } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import Inspector, { chromeLight } from 'react-inspector'
import { DetailsTabProps } from '../../../../core/interfaces'
import { useDebugLocationWithVaccine } from '../../hooks/useDebugLocationWithVaccine'
import { AvailableTable } from './components/AvailableTable'
import { SlotTable } from './components/SlotTable'

interface Props extends DetailsTabProps {
  locationId?: string
}

export const DebugLocationWithVaccine: FC<Props> = ({ locationId, customerEnv, customerKey }) => {
  const {
    config,
    showRawData,
    onChangeDate,
    onRawCheckboxChanged,
    loading,
    datePickerValue
  } = useDebugLocationWithVaccine({ customerEnv, customerKey, locationId: locationId || '' })

  return (
    <div style={{ minHeight: '500px ' }}>
      <Container textAlign="right">
        <SemanticDatepicker
          format="YYYY-MM-DD"
          placeholder="Date"
          onChange={onChangeDate}
          value={datePickerValue}
          datePickerOnly
          size="small"
        />
        <div style={{ margin: '0 1em', display: 'inline' }}>
          <Checkbox toggle label="Raw Data" onChange={onRawCheckboxChanged} />
        </div>
      </Container>
      {showRawData ? (
        <Segment color="blue">
          <Inspector theme={{ ...chromeLight, ...{ BASE_BACKGROUND_COLOR: 'none' } }} data={config} expandLevel={1} />
        </Segment>
      ) : (
        <Segment color="blue">
          <AvailableTable loading={loading} config={config} />
          <SlotTable loading={loading} config={config} />
        </Segment>
      )}
    </div>
  )
}
