import { ComponentClass, FC } from 'react'
import { Tab } from 'semantic-ui-react'
import { CustomerInfo, Environment } from '../../../services/Customers'

export interface TabProps {
  customerKey: string
  customerEnv: string
  loading: boolean
  customer?: CustomerInfo
  environment?: Environment
  configType: string
}

interface Props {
  customerCode: string
  customerEnv: string
  loading: boolean
  customer?: CustomerInfo
  environment?: Environment
  tab: {
    Component: FC<TabProps> | ComponentClass<TabProps>
    name: string
  }
}

export const TabPane: FC<Props> = ({ customerCode, customerEnv, loading, customer, environment, tab }) => (
  <Tab.Pane className="scrollable" style={{ maxHeight: 'calc(100% - 30px)' }}>
    <tab.Component
      customerKey={customerCode}
      customerEnv={customerEnv}
      loading={loading}
      customer={customer}
      environment={environment}
      configType={tab.name}
    />
  </Tab.Pane>
)

export const paneFactory = ({ customerCode, customerEnv, loading, customer, environment, tab }: Props) => () => (
  <TabPane
    customerCode={customerCode}
    loading={loading}
    customer={customer}
    environment={environment}
    customerEnv={customerEnv}
    tab={tab}
  />
)
