import { PureComponent } from 'react'
import { Button, Grid, Header } from 'semantic-ui-react'

interface IEditableTableProps {
  editing: boolean
  isEditable: boolean
  loading: boolean
  reset: () => void
  submit: () => void
  submitting: boolean
  tableTitle: string
  toggleEditing: () => void
  validateSubmit: boolean
}

export class RenderEditableTableHeader extends PureComponent<IEditableTableProps> {
  constructor(props: IEditableTableProps) {
    super(props)
  }

  renderControls = () => {
    const { toggleEditing, validateSubmit, submit, reset, loading, editing, submitting } = this.props
    return editing ? (
      <>
        <Button
          disabled={!validateSubmit || submitting}
          loading={submitting || false}
          onClick={submit}
          color="blue"
          content="Save"
        />
        <Button content="Cancel" onClick={reset} disabled={Boolean(submitting)} />
      </>
    ) : (
      <Button content="Edit" onClick={toggleEditing} className="sked-blue-button" disabled={loading} />
    )
  }

  render() {
    const { isEditable } = this.props
    return (
      <div>
        <Grid>
          <Grid.Column width={8} textAlign="left" verticalAlign="middle">
            <Header as="h3">{this.props.tableTitle}</Header>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            {isEditable ? this.renderControls() : ''}
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}
