import { IndexableObject } from '../core/interfaces'
import { vaxAdminApi } from './Endpoints'

export interface CapacitySnapshot {
  locationId: number
  externalId: string
  name: string
  address: string
  availabilityDate: Date
  holiday: boolean
  capacity: number
  reservations: number
  capacityTaken: number
  capacityRemaining: number
  lat: string
  lng: string
}

export interface LocationType extends IndexableObject {
  id: number
  regionExternalId: string
  externalId: string
  name: string
  address: string
  description?: string
  startDate?: string
  endDate?: string
  availableDate?: string
  excludeFromSearch: boolean
  exhausted: boolean
  locationPool: string
}

export interface LocationResult {
  locations: LocationType[]
  totalLocations: number
  totalPages: number
  limit: number
}

export interface ParamsLocation {
  page: number
  search?: string
  sortBy?: string
  order?: string
}

export const getCapacitySnapshot = (customerKey: string, customerEnv: string) =>
  vaxAdminApi
    .get<CapacitySnapshot[]>(`/proxy/${customerKey}/${customerEnv}/secure/stats/capacity/snapshot`)
    .then(response => response.data)

export const getLocations = (customerKey: string, customerEnv: string, params: ParamsLocation) =>
  vaxAdminApi
    .get<LocationResult>(`/proxy/${customerKey}/${customerEnv}/secure/locations/debug`, { params })
    .then(response => response.data)

export const getAllLocations = (customerKey: string, customerEnv: string) =>
  vaxAdminApi
    .get<LocationType[]>(`/proxy/${customerKey}/${customerEnv}/secure/locations/debug`)
    .then(response => response.data)
