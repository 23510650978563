import { Fragment } from 'react'
import { auth } from '../../../services/Endpoints'

interface Props {
  children: JSX.Element
  permission: string
}

export const DisplayWithPermission = (props: Props) =>
  auth.getPermissions().includes(props.permission) ? props.children : <Fragment />
