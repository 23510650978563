import type { Reducer } from 'react'
import type { AxiosError } from 'axios'
import { OtherEnvironmentAction, Actions } from '../actions/OtherEnvironment'

export interface OtherEnviromentState {
  [key: string]: {
    version: {
      git: string
      sf: string
      release: string
    } | null
    isLoading: boolean | null
    error: AxiosError | undefined
  }
}

export const defaultOtherEnviromentState: OtherEnviromentState = {}

export const otherEnviromentReducer: Reducer<OtherEnviromentState, OtherEnvironmentAction> = (state, action) => {
  switch (action.type) {
    case Actions.SET_IS_LOADING_ENVIRONMENT: {
      const { value, environmentName } = action.payload

      return {
        ...state,
        [environmentName]: {
          ...state[environmentName],
          isLoading: value
        }
      }
    }

    case Actions.SET_ENVIRONMENT_ERROR: {
      const { value, environmentName } = action.payload

      return {
        ...state,
        [environmentName]: {
          ...state[environmentName],
          error: value
        }
      }
    }

    case Actions.SET_ENVIRONMENT_VERSION: {
      const { value, environmentName } = action.payload

      return {
        ...state,
        [environmentName]: {
          ...state[environmentName],
          version: value
        }
      }
    }

    default:
      return state
  }
}
