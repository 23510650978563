import type { Reducer } from 'react'
import { DebugLocationTableAction, Actions } from '../actions/DebugLocationTable'

export interface DebugLocationTableState {
  locationId: string
  showRawData: boolean
}

export const defaultDebugLocationTableState: DebugLocationTableState = {
  locationId: '',
  showRawData: false
}

export const debugLocationTableReducer: Reducer<DebugLocationTableState, DebugLocationTableAction> = (
  state,
  action
) => {
  switch (action.type) {
    case Actions.SET_DEBUG_LOCATION_TABLE_LOCATION_ID: {
      const { payload: locationId } = action

      return {
        ...state,
        locationId
      }
    }

    case Actions.SET_DEBUG_LOCATION_TABLE_SHOW_RAW_DATA: {
      const { payload: showRawData } = action

      return {
        ...state,
        showRawData
      }
    }

    default:
      return state
  }
}
