import React, { useState } from 'react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { useMutation } from 'react-query'
import { createRelease } from '../../../services/Release'
import { createErrorToast, createSuccessToast } from '../../../core/components/Alert'
import { useAvailableVerions } from '../hooks/useDeployments'
import { Actions } from '../actions/Release'
import { useReleaseContext } from './../context/Release'
import { CreateReleaseConfirmationModal } from './CreateReleaseConfirmationModal'

interface Props {
  loading: boolean
}

export const CreateReleaseModal = (props: Props) => {
  const [releaseVersion, setReleaseVersion] = useState('')
  const [minimumSalesForce, setMinimumSalesForce] = useState('')
  const [gitHash, setGitHash] = useState('')
  const [failure, setFailure] = useState(false)
  const [confirmBox, setConfirmBox] = useState(false)
  const [creatingRelease, setCreatingRelease] = useState(false)
  const { dispatch } = useReleaseContext()
  const { loading } = props

  const { data: availableVersions } = useAvailableVerions({})

  const addRelease = useMutation(() => createRelease({ releaseVersion, minimumSalesForce, gitHash }), {
    onSuccess: () => {
      createSuccessToast(`Successfully created release ${releaseVersion}`)
      setFailure(false)
      setCreatingRelease(false)
    },
    onError: error => {
      createErrorToast(`Failed to create release. ${error}`)
      setFailure(true)
      setCreatingRelease(false)
    }
  })

  const validateForm = () => releaseVersion !== '' && minimumSalesForce !== '' && gitHash !== ''

  const handleClose = () => setFailure(false)

  const handleOpen = () => setFailure(true)

  const submitForm = async () => {
    if (validateForm()) {
      setCreatingRelease(true)
      await addRelease.mutateAsync()
      dispatch({
        type: Actions.SET_DEPLOY_RELEASE_MODAL_UNFETCHED_NEW_RELEASE,
        payload: true
      })
    }
  }

  const hashRegex = new RegExp('^[a-fA-F0-9]{40}$')

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      onClose={handleClose}
      open={failure}
      trigger={
        <Button onClick={handleOpen} content="Create Release" color="blue" disabled={loading} loading={loading} />
      }
    >
      <Modal.Header>Create Release</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            label="Release Version"
            placeholder="e.g. v21-19-0"
            onChange={(event, data) => setReleaseVersion(data.value as string)}
          />
          <Form.Input
            label="Minimum SalesForce Package Version"
            placeholder="e.g. 1.204"
            onChange={(event, data) => setMinimumSalesForce(data.value as string)}
          />
          <Form.Dropdown
            label="Git Hash"
            placeholder="e.g. 02869c2b9da4452a5cbb7e5a6ff9ce3be93f674e"
            selection
            search
            lazyLoad
            options={(availableVersions || [])
              .filter(version => hashRegex.test(version))
              .map(version => ({
                text: version,
                value: version
              }))}
            value={gitHash}
            onChange={(event, data) => setGitHash(data.value as string)}
          />
          <Modal.Actions>
            <Form.Button
              onClick={() => setConfirmBox(true)}
              content="Create"
              fluid
              color="blue"
              loading={creatingRelease}
              disabled={creatingRelease || !validateForm()}
            />
          </Modal.Actions>
        </Form>
      </Modal.Content>
      <CreateReleaseConfirmationModal
        open={confirmBox}
        setOpen={setConfirmBox}
        submitForm={submitForm}
        newRelease={{ releaseVersion, minimumSalesForce, gitHash }}
      />
    </Modal>
  )
}
