import { Dispatch, useReducer } from 'react'
import { useCombinedReducers } from '../../../core/hooks/useCombinedReducers'
import { OtherEnvironmentAction } from '../actions/OtherEnvironment'
import { LocationAction } from '../actions/Location'
import { DebugLocationWithVaccineAction } from '../../../core/actions/DebugLocationWithVaccine'
import {
  debugLocationWithVaccineReducer,
  DebugLocationWithVaccineState,
  defaultDebugLocationWithVaccineState
} from '../../../core/reducers/DebugLocationWithVaccine'
import { defaultOtherEnviromentState, otherEnviromentReducer, OtherEnviromentState } from './OtherEnviroment'
import { locationReducer, LocationState, defaultLocationState } from './Location'

export interface EnviromentsDetailsState {
  otherEnviroment: OtherEnviromentState
  location: LocationState
  debugLocationWithVaccine: DebugLocationWithVaccineState
  dispatch: EnviromentsDetailsDispatch
}

export type EnviromentsDetailsAction = OtherEnvironmentAction | LocationAction | DebugLocationWithVaccineAction

export type EnviromentsDetailsDispatch = Dispatch<EnviromentsDetailsAction>

export const useEnviromentsDetailsReducer = () =>
  useCombinedReducers<EnviromentsDetailsState, EnviromentsDetailsAction>({
    otherEnviroment: useReducer(otherEnviromentReducer, defaultOtherEnviromentState),
    location: useReducer(locationReducer, defaultLocationState),
    debugLocationWithVaccine: useReducer(debugLocationWithVaccineReducer, defaultDebugLocationWithVaccineState)
  })
