import { useEffect } from 'react'
import { createErrorToast } from '../../../core/components/Alert'
import { useTokens } from './useTokens'

export const useAPITokens = ({ customerKey, customerEnv }: { customerKey: string; customerEnv: string }) => {
  const { data: tokens, isLoading, error } = useTokens({
    customerEnv,
    customerKey,
    name: useAPITokens.name
  })

  useEffect(() => {
    if (error) {
      createErrorToast(error)
    }
  }, [customerKey, customerEnv])

  const convertTime = (time: number) => (time > new Date().getTime() / 1000 ? new Date(time) : new Date(time * 1000))

  return { loading: isLoading, tokens, convertTime }
}
