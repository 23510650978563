import { useRef, useState } from 'react'
import { orderBy } from 'lodash'
import { createErrorToast } from '../../../core/components/Alert'
import { useCapacitySnapshot as useCapacity } from '../../../core/hooks/useCapacitySnapshot'

export const CSV_FILENAME_BASE = 'capacity_snapshot_export'

export const generateCsvFilename = () => `${CSV_FILENAME_BASE}-${new Date().toISOString().substring(0, 16)}`

export const useCapacitySnapshot = ({
  customerKey,
  customerEnv,
  customerLocation
}: {
  customerKey: string
  customerEnv: string
  customerLocation?: string
}) => {
  const [filename, setFilename] = useState('')
  const [loaded, setLoaded] = useState(false)
  const { data, isFetching: loading, error, refetch } = useCapacity({
    customerEnv,
    customerKey,
    name: `${useCapacitySnapshot.name}-${customerLocation}`,
    options: {
      onSuccess: () => setFilename(generateCsvFilename()),
      onSettled: () => setLoaded(true),
      enabled: false
    }
  })

  // eslint-disable-next-line
  const csvLinkEl = useRef<any>(null)
  const csvSnapshot = orderBy(data, ['name', 'avalabilityDate'], ['asc', 'asc'])

  const downloadCsvFile = async () => {
    let err
    if (!loaded) {
      const { error: fetchError } = await refetch()
      err = fetchError
      if (err) {
        const status = err.response?.status
        createErrorToast(
          status === 404
            ? 'Environment version upgrade required'
            : status === 501
            ? 'Please take a screenshot and report to Skedulo Vax Tooling'
            : err
        )
      }
    }
    if (!err) {
      csvLinkEl.current?.link?.click()
    }
  }

  return { loaded, loading, filename, csvSnapshot, downloadCsvFile, csvLinkEl, error }
}
