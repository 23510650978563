import type { Reducer } from 'react'
import { ReleaseAction, Actions } from '../actions/Release'

export interface DeployReleaseModalState {
  unfetchedLatestRelease: boolean
}

export const defaultReleaseState: DeployReleaseModalState = {
  unfetchedLatestRelease: true
}

export const deployReleaseModalReducer: Reducer<DeployReleaseModalState, ReleaseAction> = (state, action) => {
  switch (action.type) {
    case Actions.SET_DEPLOY_RELEASE_MODAL_UNFETCHED_NEW_RELEASE: {
      const { payload: unfetchedLatestRelease } = action

      return {
        ...state,
        unfetchedLatestRelease
      }
    }

    default:
      return state
  }
}
