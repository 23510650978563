import { SyntheticEvent, useState, useEffect } from 'react'
import type { DropdownItemProps, DropdownProps, InputOnChangeData } from 'semantic-ui-react'
import { Config } from '../../../../../services/Config'
import type { IConfigComparison } from '../../../ConfigCompare'

interface Props {
  config: IConfigComparison
}

export interface Result {
  flattenedConfig1: Config
  flattenedConfig2: Config
  configField: string
  searchField: string
  handleFieldChange: (event: SyntheticEvent<HTMLElement>, data: InputOnChangeData | DropdownProps) => void
  searchOptions: (
    keyword: string
  ) => {
    text: string
    value: string
  }[]
  updateSearchField: (event: SyntheticEvent<HTMLElement>, data: InputOnChangeData | DropdownProps) => void
  resetSearchFeild: () => void
}

const flatten = require('flat')

export const useConfigFieldCompareModal: CustomHook<Props, Result> = ({ config }) => {
  const [searchField, setSearchField] = useState('')
  const [diffFields, setDiffFields] = useState([{ text: '', value: '' }])
  const [configField, setConfigField] = useState('')
  const { environment1Value, environment2Value } = config
  const flattenedConfig1 = flatten(environment1Value)
  const flattenedConfig2 = flatten(environment2Value)

  useEffect(() => {
    const configFields = Array.from(
      new Set(Object.keys(flattenedConfig1).concat(Object.keys(flatten(flattenedConfig2))))
    )
    setDiffFields(
      configFields
        .filter((key: string) => flattenedConfig1[key] !== flattenedConfig2[key])
        .map(key => ({ value: key, text: key }))
    )
  }, [])

  const searchOptions = (keyword: string) =>
    diffFields
      .filter((field: DropdownItemProps) => {
        if (typeof field.value == 'string') {
          return field.value.includes(keyword)
        }
      })
      .slice(0, 500)

  const handleFieldChange = (event: SyntheticEvent<HTMLElement>, data: InputOnChangeData | DropdownProps) => {
    if (typeof data.value === 'string') {
      setConfigField(data.value)
    }
  }

  const updateSearchField = (event: SyntheticEvent<HTMLElement>, data: InputOnChangeData | DropdownProps) => {
    setSearchField(data.searchQuery)
  }

  const resetSearchFeild = () => {
    setSearchField('')
  }

  return {
    flattenedConfig1,
    flattenedConfig2,
    configField,
    searchField,
    handleFieldChange,
    searchOptions,
    updateSearchField,
    resetSearchFeild
  }
}
