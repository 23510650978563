import { FC, useState, useEffect } from 'react'
import { Button, Form, InputOnChangeData, Message, Modal } from 'semantic-ui-react'
import {
  generateToken as generateTokenService,
  getScopes as getScopesService,
  Token
} from '../../../../services/Tokens'
import { createErrorToast } from '../../../../core/components/Alert'
import { Scopes } from './components/Scopes'

export type OnChangeHandler = (event: unknown, data: InputOnChangeData) => void

export interface GenerateTokenModalProps {
  customerKey: string
  customerEnv: string
  loading: boolean
}

export const GenerateTokenModal: FC<GenerateTokenModalProps> = ({ customerEnv, customerKey, loading }) => {
  const [subject, setSubject] = useState('')
  const [exp, setExp] = useState('3600')
  const [scopes, setScopes] = useState<string[]>([])
  const [selectedScopes, setSelectedScopes] = useState<string[]>([])
  const [scopesString, setScopesString] = useState('')
  const [loadingScopes, setLoadingScopes] = useState(false)
  const [error, setError] = useState(undefined)
  const [submitting, setSubmitting] = useState(false)
  const [token, setToken] = useState<Token | undefined>(undefined)
  const [shouldGetScopes, setShouldGetScopes] = useState(true)

  const getScopesListFromString = () => scopesString.split(' ')

  const validateScopesString = () =>
    !scopesString ||
    (getScopesListFromString().length > 0 &&
      getScopesListFromString()
        .map(scope => scope.match(/.*:.*/))
        .reduce((sum, cur) => sum && cur))

  const validateScopes = () => (scopes && selectedScopes.length > 0) || (scopesString && validateScopesString())

  const getExpFromString = () => (exp ? parseInt(exp, 10) : undefined)

  const validateExp = () => {
    const newExp = getExpFromString()
    if (!newExp) {
      return true
    }
    return !isNaN(newExp)
  }

  const validateForm = () => subject && validateExp() && validateScopes()

  const generateToken = async () => {
    if (validateForm()) {
      setSubmitting(true)
      try {
        const newToken = await generateTokenService(customerKey, customerEnv, {
          subject,
          scopes: scopes ? selectedScopes : getScopesListFromString(),
          exp: getExpFromString()
        })
        setToken(newToken)
      } catch (err) {
        createErrorToast(err)
      }
      setSubmitting(false)
    }
  }

  const onChangeSub: OnChangeHandler = (event, { value }) => setSubject(value)

  const onChangeExp: OnChangeHandler = (event, { value }) => setExp(value)

  const onChangeScopes: OnChangeHandler = (event, { value }) => setScopesString(value)

  const toggleScope = (scope: string) => () => {
    if (selectedScopes.includes(scope)) {
      setSelectedScopes(selectedScopes.filter(selectedScope => selectedScope !== scope))
    } else {
      setSelectedScopes([...selectedScopes, scope])
    }
  }

  const resetForm = () => {
    setSubject('')
    setExp('3600')
    setSelectedScopes([])
    setScopesString('')
    setLoadingScopes(false)
    setError(undefined)
    setSubmitting(false)
    setToken(undefined)
  }

  const getScopes = async () => {
    setLoadingScopes(true)

    try {
      const scopesResponse = await getScopesService(customerKey, customerEnv)
      const newScopes = [...scopesResponse.scopes, ...Object.keys(scopesResponse.roles)]
      setScopes(newScopes)
    } catch (err) {
      setError(err)
    }

    setLoadingScopes(false)
  }

  useEffect(() => {
    const fetchScopes = async () => {
      await getScopes()
      setShouldGetScopes(false)
    }
    fetchScopes()
  }, [shouldGetScopes])

  useEffect(() => {
    resetForm()
    setShouldGetScopes(true)
  }, [customerKey, customerKey])

  return (
    <Modal closeIcon trigger={<Button color="green" content="Generate Token" />} size="tiny" onClose={resetForm}>
      <Modal.Header>
        Generate Token for {customerKey} {customerEnv}
      </Modal.Header>
      <Modal.Content scrolling>
        <Form onSubmit={generateToken} success={!!token} loading={loading || loadingScopes}>
          <Form.Input
            label="Subject"
            placeholder="What the token will be used for."
            required
            value={subject}
            onChange={onChangeSub}
          />
          <Form.Input
            label="Expiry In Seconds (Optional)"
            placeholder="Optional Expiry in Seconds"
            value={exp}
            onChange={onChangeExp}
            error={!validateExp()}
          />
          <Scopes
            onChangeScopes={onChangeScopes}
            scopesString={scopesString}
            selectedScopes={selectedScopes}
            toggleScope={toggleScope}
            validateScopesString={validateScopesString}
            error={error}
            scopes={scopes}
          />
          <Message success header="Generated Token">
            <p>JTI: {token?.jti}</p>
            {token?.exp && <p>EXP: {new Date(token?.exp || 0).toDateString()}</p>}
            <p className="table-cell-overflow">Token: {token?.token}</p>
          </Message>
          <Form.Button
            type="submit"
            content="Generate"
            fluid
            color="blue"
            disabled={!validateForm() || submitting || !!token}
            loading={submitting}
          />
        </Form>
      </Modal.Content>
    </Modal>
  )
}
