import { useMemo } from 'react'
import { DeepMap, FieldError, FormState } from 'react-hook-form'
import { ConfigStore, ConfigStoreConfig, ConfigStoreSchema } from '../../../../../services/ConfigStore'

export const MIN_AWS_APP_VERSION = 21.27

interface HasConfigStoreOptions {
  rawVersion: number
  customerKey: string
}

export const hasConfigStore = ({ rawVersion, customerKey }: HasConfigStoreOptions) => {
  const ALLOWED_KEYS = ['STG']

  if (rawVersion >= MIN_AWS_APP_VERSION) {
    return true
  }

  /**
   * This is too get around staging not having a version
   */
  if (rawVersion === 0 && ALLOWED_KEYS.includes(customerKey.toUpperCase())) {
    return true
  }

  return false
}

interface ConfigStoreTableDataOptions {
  config: ConfigStoreConfig | null
  schema: ConfigStoreSchema | null
  errors?: DeepMap<ConfigStoreConfig, FieldError>
}
type ConfigStoreChangeTrigger = (ConfigStore | ConfigStoreSchema | undefined | FormState<ConfigStoreConfig>)[]

export const configStoreTableData = (
  { config = null, schema, errors = {} }: ConfigStoreTableDataOptions,
  changeTrigger: ConfigStoreChangeTrigger = []
) => {
  const parsedData = useMemo(() => {
    if (!config || !schema) {
      return []
    }

    const configKeys = Object.keys(config)

    const parseValue = (key: string, val: string | number | boolean | object) => {
      switch (schema.properties?.[key].type) {
        case 'string':
        case 'number':
          return val
        default:
          return JSON.stringify(val)
      }
    }

    // remove config keys not in schema.properties, otherwise UI borks
    const configKeysFiltered = configKeys.filter(key => !!schema.properties[key])

    return configKeysFiltered.map(key => ({
      key,
      type: schema.properties[key].type,
      value: parseValue(key, config[key]),
      default: parseValue(key, schema.properties[key].default),
      description: schema?.properties?.[key].description,
      error: errors[key]
    }))
  }, [...changeTrigger])

  return {
    data: parsedData
  }
}
export type ConfigStoreTableDataType = ReturnType<typeof configStoreTableData>
