import { Segment, Checkbox, Table } from 'semantic-ui-react'
import Inspector, { chromeLight } from 'react-inspector'
import { useState } from 'react'
import { TableLoaderWrap } from '../../../core/components/Loader'
import { useAllVersions } from '../hooks/useAllVersions'
import { CustomerWithVersion } from '../../../services/Environment'

const defaultConfig = {
  gitHash: '',
  gitBranch: '',
  gitVersion: '',
  build: '',
  packageVersion: ''
}

export const AllVersions = () => {
  const [showRawData, setShowRawData] = useState(false)
  const { data: allVersions, error, isLoading } = useAllVersions({})

  return (
    <>
      <div style={{ padding: '5px', display: 'flex', justifyContent: 'flex-end' }}>
        <Checkbox toggle label="Raw Data" onChange={(_, data) => setShowRawData(!!data.checked)} />
      </div>

      {showRawData ? (
        <Segment color="blue">
          <Inspector
            theme={{ ...chromeLight, ...{ BASE_BACKGROUND_COLOR: 'none' } }}
            data={allVersions}
            expandLevel={3}
          />
        </Segment>
      ) : (
        <div className="scrollable">
          <Table striped color="blue" size="small" stackable className="sticky-table">
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>Customer Environment</Table.HeaderCell>
                <Table.HeaderCell>Customer Key</Table.HeaderCell>
                <Table.HeaderCell>git Hash</Table.HeaderCell>
                <Table.HeaderCell>git Branch</Table.HeaderCell>
                <Table.HeaderCell>git Version</Table.HeaderCell>
                <Table.HeaderCell>Build</Table.HeaderCell>
                <Table.HeaderCell>Package Version</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <TableLoaderWrap
              loading={isLoading}
              array={(allVersions && Object.keys(allVersions)) || []}
              emptyMessage="No version info found."
            >
              <Table.Body>
                {allVersions &&
                  allVersions instanceof Array &&
                  allVersions.map((version: CustomerWithVersion, index: number) => {
                    const { gitHash, gitBranch, gitVersion, build, packageVersion } = version.data || defaultConfig
                    const { customerEnv, customerKey } = version
                    return (
                      <Table.Row key={`${index}`} textAlign="center">
                        <Table.Cell>{customerEnv}</Table.Cell>
                        <Table.Cell>{customerKey}</Table.Cell>
                        <Table.Cell>{gitHash}</Table.Cell>
                        <Table.Cell>{gitBranch}</Table.Cell>
                        <Table.Cell>{gitVersion}</Table.Cell>
                        <Table.Cell>{build}</Table.Cell>
                        <Table.Cell>{packageVersion}</Table.Cell>
                      </Table.Row>
                    )
                  })}
              </Table.Body>
            </TableLoaderWrap>
          </Table>
        </div>
      )}
    </>
  )
}
